<template>
  <section class="logout-wrapper">
    <div class="text-center">
      <img
        class="logo-image"
        src="../../assets/img/logo-central-da-marca.svg"
        alt="Logo Central da Marca"
      />
      <div class="subtitle">Logout realizado com sucesso, até breve :)</div>
      <button @click="goToLogin()" class="button">LOGIN</button>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Logout extends Vue {
  goToLogin(): void {
    this.$router.push({ name: 'login' })
  }
}
</script>

<style lang="scss" scoped>
.logout-wrapper {
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #fff;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo-image {
  height: 132px;
  max-width: 80%;
}

.subtitle {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-top: 5rem;
}

.button {
  width: 119px;
  height: 48px;
  margin-top: 3rem;

  background: white;
  color: #000;
  border-radius: 17px;
  border: none;
  cursor: pointer;

  font-size: 20px;
  font-weight: 600;
}
</style>
