<template>
  <div class="content">
    <IllustrationIntroSection />
    <IllustrationSustainabilitySection />
    <IllustrationSustainabilitySection2 />
    <IllustrationBrazilianitySection />
    <IllustrationDiversitySection />
    <IllustrationConnectivitySection />
    <IllustrationCommunicationSection />
    <IllustrationCelebrationSection />
    <IllustrationCriationSection />
    <IllustrationJournalismSection />
    <IllustrationGloboStudiosSection />
    <IllustrationFictionSection />
    <IllustrationCorporateSceneSection />
    <IllustrationConsumersSection />
    <IllustrationAvatarsSection />
    <IllustrationStyleSection />
    <IllustrationColorsSection />
    <IllustrationGradientsSection />
    <IllustrationGradientsTwoSection />
    <IllustrationPureColorsceneSection />
    <IllustrationCodesSection />
    <IllustrationFrameworksSection />
    <IllustrationSummarySection />
    <IllustrationPhotosSection />
  </div>
</template>

<script lang="ts">
import IllustrationIntroSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationIntroSection.vue'
import IllustrationSustainabilitySection from '@/components/VisualIdentityPage/sections/illustration/IllustrationSustainabilitySection.vue'
import IllustrationSustainabilitySection2 from '@/components/VisualIdentityPage/sections/illustration/IllustrationSustainabilitySection2.vue'
import IllustrationBrazilianitySection from '@/components/VisualIdentityPage/sections/illustration/IllustrationBrazilianitySection.vue'
import IllustrationDiversitySection from '@/components/VisualIdentityPage/sections/illustration/IllustrationDiversitySection.vue'
import IllustrationConnectivitySection from '@/components/VisualIdentityPage/sections/illustration/IllustrationConnectivitySection.vue'
import IllustrationCommunicationSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationCommunicationSection.vue'
import IllustrationCelebrationSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationCelebrationSection.vue'
import IllustrationCriationSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationCriationSection.vue'
import IllustrationJournalismSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationJournalismSection.vue'
import IllustrationGloboStudiosSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationGloboStudiosSection.vue'
import IllustrationFictionSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationFictionSection.vue'
import IllustrationCorporateSceneSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationCorporateSceneSection.vue'
import IllustrationConsumersSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationConsumersSection.vue'
import IllustrationAvatarsSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationAvatarsSection.vue'
import IllustrationStyleSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationStyleSection.vue'
import IllustrationColorsSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationColorsSection.vue'
import IllustrationGradientsSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationGradientsSection.vue'
import IllustrationGradientsTwoSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationGradientsTwoSection.vue'
import IllustrationPureColorsceneSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationPureColorsceneSection.vue'
import IllustrationCodesSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationCodesSection.vue'
import IllustrationFrameworksSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationFrameworksSection.vue'
import IllustrationSummarySection from '@/components/VisualIdentityPage/sections/illustration/IllustrationSummarySection.vue'
import IllustrationPhotosSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationPhotosSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IllustrationIntroSection,
    IllustrationSustainabilitySection,
    IllustrationSustainabilitySection2,
    IllustrationBrazilianitySection,
    IllustrationDiversitySection,
    IllustrationConnectivitySection,
    IllustrationCommunicationSection,
    IllustrationCelebrationSection,
    IllustrationCriationSection,
    IllustrationJournalismSection,
    IllustrationGloboStudiosSection,
    IllustrationFictionSection,
    IllustrationCorporateSceneSection,
    IllustrationConsumersSection,
    IllustrationAvatarsSection,
    IllustrationStyleSection,
    IllustrationColorsSection,
    IllustrationGradientsSection,
    IllustrationGradientsTwoSection,
    IllustrationPureColorsceneSection,
    IllustrationCodesSection,
    IllustrationFrameworksSection,
    IllustrationSummarySection,
    IllustrationPhotosSection
  }
})
export default class IllustrationView extends Vue {}
</script>

<style lang="scss"></style>
