<template>
  <div class="differentiating-benefits-brazilianity-content">
    <div
      class="differentiating-benefits-brazilianity-banner-image"
      :style="{ backgroundImage: `url(${differentiatingBenefitsBrazilianityImageSrc})` }"
    ></div>

    <div class="differentiating-benefits-brazilianity-overlay-title-image">
      <p class="differentiating-benefits-brazilianity-image-brazilianity-title">
        {{ differentiatingBenefitsBrazilianityImageTitle }}
      </p>
      <p class="differentiating-benefits-brazilianity-image-brazilianity-title">
        {{ differentiatingBenefitsBrazilianityImageTitle2 }}
      </p>
      <p class="differentiating-benefits-brazilianity-image-brazilianity-title2">
        {{ differentiatingBenefitsBrazilianityImageTitle3 }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import DifferentiatingBenefitsPageMixin from '@/mixins/differentiating-benefits-page-mixin'
import { DifferentiatingBenefitsPageStore } from '@/store/brand-plataform-page/differentiating-benefits-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class DifferentiatingBenefitsBrazilianitySection extends Mixins(
  DifferentiatingBenefitsPageMixin
) {
  differentiatingBenefitsPageStore: PageStoreContract = getModule(
    DifferentiatingBenefitsPageStore,
    this.$store
  )

  get differentiatingBenefitsBrazilianityImageTitle(): string {
    return this.getElementText(3, 0)
  }

  get differentiatingBenefitsBrazilianityImageSrc(): string {
    return this.getElementMedia(3, 0)
  }

  get differentiatingBenefitsBrazilianityImageTitle2(): string {
    return this.getElementText(3, 1)
  }

  get differentiatingBenefitsBrazilianityImageTitle3(): string {
    return this.getElementText(2, 2)
  }

  async getDifferentiatingBenefitsPageStore(): Promise<void> {
    await this.differentiatingBenefitsPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.differentiatingBenefitsPageStore.isLoading) {
      await this.getDifferentiatingBenefitsPageStore()
    }
  }
}
</script>

<style lang="scss">
.differentiating-benefits-brazilianity-content {
  margin-top: 5px;
  display: grid;  
  position: relative;  
  justify-content: center;
  align-items: center;  
}

.differentiating-benefits-brazilianity-banner-image {
  position: relative;
  bottom: 0;  
  width: 1045px;
  height: 513px;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover; 
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5)!important; 
  }
}

.differentiating-benefits-brazilianity-overlay-title-image {
  position: absolute;
    bottom: 0rem;
    left: 1rem;
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    color: white;
    z-index: 1;
    pointer-events: none;
    text-align: left;
    margin-top: 50rem;
    align-items: center;
}

.differentiating-benefits-brazilianity-image-brazilianity-title {
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: center;
  width: 1015px;
  margin: 0;
}

.differentiating-benefits-brazilianity-image-brazilianity-title2 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 20px;
  text-align: center;
  width: 509px;
  margin: 0;
}
</style>
