<template>
    <div class="content">      
      <GridIntroSection />
      <GridIntroTwoSection />      
    </div>
  </template>
  
  <script lang="ts">  
  import GridIntroSection from '@/components/VisualIdentityPage/sections/grid/GridIntroSection.vue'
  import GridIntroTwoSection from '@/components/VisualIdentityPage/sections/grid/GridIntroTwoSection.vue'
  import { Component, Vue } from 'vue-property-decorator'  
  
  @Component({
    components: {
      GridIntroSection,
      GridIntroTwoSection      
     }
  })
  export default class GridView extends Vue {}
  </script>
  
  <style lang="scss"></style>
  