<template>
  <div class="illustration-colors-content">
    <div class="title-colors-wrapper">
      <h1 class="illustration-colors-title-span">{{ illustrationColorsTitleSpan }}</h1>
      <h1 class="illustration-colors-title">{{ illustrationColorsTitle }}</h1>
    </div>
    <div class="description-colors-wrapper">
      <p class="illustration-colors-description">{{ illustrationColorsDescription }}</p>
      <div class="icon-wrapper"></div>
      <div class="icon-wrapper">
        <IconCheck />
        <p class="illustration-colors-description">{{ illustrationColorsDescription1 }}</p>
        <IconCheck />
        <p class="illustration-colors-description">{{ illustrationColorsDescription2 }}</p>
      </div>
    </div>
    <div
      class="illustration-colors-card-item"
      :style="{ backgroundImage: `url(${illustrationColorsImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import IllustrationPageMixin from '@/mixins/illustration-page-mixin'
import { IllustrationPageStore } from '@/store/visual-identity-page/illustration-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class IllustrationColorsSection extends Mixins(IllustrationPageMixin) {
  illustrationPageStore: PageStoreContract = getModule(IllustrationPageStore, this.$store)

  get illustrationColorsTitleSpan(): string {
    return this.getElementTitle(17, 0)
  }

  get illustrationColorsTitle(): string {
    return this.getElementText(17, 0)
  }

  get illustrationColorsDescription(): string {
    return this.getElementText(17, 1)
  }

  get illustrationColorsDescription1(): string {
    return this.getElementText(17, 2)
  }

  get illustrationColorsDescription2(): string {
    return this.getElementText(17, 3)
  }

  get illustrationColorsImageSrc(): string {
    return this.getElementMedia(17, 4)
  }

  async getIllustrationPage(): Promise<void> {
    await this.illustrationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.illustrationPageStore.isLoading) {
      await this.getIllustrationPage()
    }
  }
}
</script>

<style scoped>
.illustration-colors-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-colors-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.illustration-colors-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}
.illustration-colors-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-colors-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.illustration-colors-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.illustration-colors-card-item {
  position: relative;
  bottom: 3rem;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 731px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
