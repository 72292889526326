<template>
  <div class="typography-intro-two-content">
    <div class="description-typography-two-content">
      <h1 class="typography-intro-two-title">{{ typographyIntroTitle }}</h1>
      <div class="button-typography-intro-wrapper">
        <TypographyButton
          :imageSrc="typographyIntroButtonSrc"
          buttonText="Clique aqui para obter as novas fontes"
          downloadName="Tipografia.zip"
        />
      </div>
    </div>
    <div
      class="typography-intro-two-card-item"
      :style="{ backgroundImage: `url(${typographyIntroImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import TypographyButton from './TypographyButton.vue'

@Component({
  components: {
    TypographyButton
  }
})
export default class TypographyIntroTwoSection extends Mixins(TypographyPageMixin) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyIntroTitle(): string {
    return this.getElementTitle(1, 4)
  }

  get typographyIntroImageSrc(): string {
    return this.getElementMedia(1, 5)
  }

  get typographyIntroButtonSrc(): string {
    return this.getElementMedia(1, 6)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-intro-two-content {
  margin-top: 4rem;
  padding-bottom: 9rem;
  display: grid;
  grid-template-columns: 44% 52%;
  width: 100%;
}

.typography-intro-two-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  padding: 0 3rem 3rem 0;
  width: 695px;
  margin: 0;
}

.description-typography-two-content {
  width: 434px;
  margin-left: 4rem;
  padding-top: 3rem;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.typography-intro-two-card-item {
  position: relative;
  top: 12rem;

  max-width: 621px;
  width: 100%;
  height: 315px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 3rem;
}
</style>
