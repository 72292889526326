<template>
  <div class="photography-content">
    <div
      class="photography-banner-image"
      :style="{ backgroundImage: `url(${photographyBannerImageSrc})` }"
    ></div>
    <div class="photography-overlay-image">
      <h1 class="photography-banner-title">{{ photographyBannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PhotographyPageMixin from '@/mixins/photography-page-mixin'
import { PhotographyPageStore } from '@/store/visual-identity-page/photography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class PhotographyBannerSection extends Mixins(PhotographyPageMixin) {
  photographyPageStore: PageStoreContract = getModule(PhotographyPageStore, this.$store)

  get photographyBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get photographyBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getPhotographyPage(): Promise<void> {
    await this.photographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.photographyPageStore.isLoading) {
      await this.getPhotographyPage()
    }
  }
}
</script>

<style lang="scss">
.photography-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;

  width:100%;
  max-height: 28rem;
}

.photography-banner-image {
  position: relative;
  width: 100%;
  height: 451px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.photography-overlay-image {
  position: absolute;
  bottom: 10rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.photography-banner-title {
  font-size: 7rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

@media (min-width: 1726px) {
  .photography-content {
    width: 100%;
  }
}
</style>
