import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Globo from '@/views/Globo.vue'
import visualIdentityRoutes from './visualIdentityRoutes'
import brandPlataformRoutes from './brandPlataformRoutes'
import Login from '@/views/Login/Login.vue'
import Logout from '@/views/Login/Logout.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    meta: {
      hiddenOnNavigation: true
    },
    component: Home
  },
  {
    path: '/globo',
    name: 'globo',
    meta: {
      hiddenOnNavigation: true
    },
    component: Globo
  },
  visualIdentityRoutes,
  brandPlataformRoutes,

  //Login
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      labels: ['Login'],
      hiddenOnNavigation: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      hiddenOnNavigation: true
    }
  },

  //Admin
  {
    path: '/admin',
    name: 'admin',
    beforeEnter() {
      window.location.replace(`${process.env.VUE_APP_CENTRALDEMARCA_BACK_ADMIN}`)
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, _, next) => {
  const { isLogged } = store.state.login
  const isUnprotectedRoute = (name: string | null | undefined) => {
    if (name) return ['login', 'logout'].includes(name)
    return false
  }

  const unprotected = isUnprotectedRoute(to.name)

  if (unprotected) {
    next()
  } else {
    if (!isLogged) {
      next({ name: 'login' })
      return
    }

    next()
  }
})

export default router
