<template>
  <div class="logo-about-images-content">
    <div class="title-about-images-wrapper">
      <h1 class="logo-about-images-title-span">{{ logoAboutImagesTitleSpan }}</h1>
      <h1 class="logo-about-images-title">{{ logoAboutImagesTitle }}</h1>
    </div>
    <div class="description-about-images-wrapper">
      <div class="icon-wrapper">
        <IconCheck />
        <p class="logo-about-images-description">{{ logoAboutImagesDescription }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCancel />
        <p class="logo-about-images-description">{{ logoAboutImagesDescription1 }}</p>
      </div>
    </div>
    <div class="logo-about-images-card-item" :style="{ backgroundImage: `url(${logoAboutImagesImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import IconCancel from '@/assets/icons/IconCancel.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    IconCheck,
    IconCancel
  }
})
export default class LogoAboutImagesSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoAboutImagesTitleSpan(): string {
    return this.getElementTitle(8, 0)
  }

  get logoAboutImagesTitle(): string {
    return this.getElementText(8, 0)
  }

  get logoAboutImagesDescription(): string {
    return this.getElementText(8, 1)
  }

  get logoAboutImagesDescription1(): string {
    return this.getElementText(8, 2)
  }

  get logoAboutImagesImageSrc(): string {
    return this.getElementMedia(8, 3)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-about-images-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.title-about-images-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-about-images-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-about-images-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-about-images-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.logo-about-images-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.title-image {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: left;

  padding-left: 3rem;
  width: 187px;
}

.logo-about-images-card-item {
  position: relative;
  bottom: 0;
  height: 731px;
  width: 95%;
  max-width: 1045px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  left: 2rem;
}

.logo-about-images-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  margin: 1rem 0rem 5rem 0rem;
}
</style>
