<template>
  <div class="illustration-photos-content">
    <div
      class="illustration-photos-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})`, paddingTop: '4rem' }"
    ></div>
    <div
      class="illustration-photos-card-item"
      :style="{ backgroundImage: `url(${ImageSrc2})`, bottom: '2rem' }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import IllustrationPageMixin from '@/mixins/illustration-page-mixin'
import { IllustrationPageStore } from '@/store/visual-identity-page/illustration-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class IllustrationPhotosSection extends Mixins(IllustrationPageMixin) {
  illustrationPageStore: PageStoreContract = getModule(IllustrationPageStore, this.$store)

  get ImageSrc(): string {
    return this.getElementMedia(24, 0)
  }

  get ImageSrc2(): string {
    return this.getElementMedia(24, 1)
  }

  async getIllustrationPage(): Promise<void> {
    await this.illustrationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.illustrationPageStore.isLoading) {
      await this.getIllustrationPage()
    }
  }
}
</script>

<style lang="scss">
.illustration-photos-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.illustration-photos-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 650px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
