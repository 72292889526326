<template>
  <div class="layout-content">
    <div
      class="layout-banner-image"
      :style="{ backgroundImage: `url(${layoutBannerImageSrc})` }"
    ></div>
    <div class="layout-overlay-image">
      <h1 class="layout-banner-title">{{ layoutBannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LayoutsSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get layoutBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layout-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;

  width: 100%;
  max-height: 28rem;
}

.layout-banner-image {
  position: relative;
  width: 100%;
  height: 451px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.layout-overlay-image {
  position: absolute;
  bottom: 10rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.layout-banner-title {
  font-size: 7rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

@media (min-width: 1726px) {
  .layout-content {
    width: 100%;
  }
}
</style>
