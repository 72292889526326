<template>
  <div class="typography-what-not-content">
    <div class="title-typography-what-not-wrapper">
      <h1 class="typography-what-not-span">{{ typographyWhatNotToDoTitleSpan }}</h1>
      <h1 class="typography-what-not-title">{{ typographyWhatNotToDoTitle }}</h1>
    </div>
    <div class="description-typography-what-not-wrapper">
      <div class="typography-what-not-icon">
        <IconCancel />
        <p class="typography-what-not-description">{{ typographyWhatNotToDoDescription1 }}</p>
      </div>

      <div>
        <p class="typography-what-not-description">
          {{ typographyWhatNotToDoDescription2 }}
        </p>
      </div>
    </div>
    <div
      class="typography-what-not-card-item"
      :style="{ backgroundImage: `url(${typographyWhatNotToDoImageSrc})` }"
    ></div>

    <div
      class="typography-what-not-card-item"
      :style="{ backgroundImage: `url(${typographyWhatNotToDoImageSrc2})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCancel from '@/assets/icons/IconCancel.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCancel } })
export default class TypographyWhatNotToDoSection extends Mixins(TypographyPageMixin) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyWhatNotToDoTitleSpan(): string {
    return this.getElementTitle(10, 0)
  }

  get typographyWhatNotToDoTitle(): string {
    return this.getElementText(10, 0)
  }

  get typographyWhatNotToDoDescription1(): string {
    return this.getElementText(10, 1)
  }

  get typographyWhatNotToDoDescription2(): string {
    return this.getElementText(10, 2)
  }

  get typographyWhatNotToDoImageSrc(): string {
    return this.getElementMedia(10, 3)
  }

  get typographyWhatNotToDoImageSrc2(): string {
    return this.getElementMedia(10, 4)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-what-not-content {
  display: grid;
  max-width: 1045px;
  width: 100%;
}

.title-typography-what-not-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.typography-what-not-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.typography-what-not-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.typography-what-not-icon {
  display: flex;
  align-items: center;
}

.description-typography-what-not-wrapper {
  padding: 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.typography-what-not-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 430px;
}

.typography-what-not-card-item {
  position: relative;
  bottom: 4rem;

  max-width: 1045px;
  width: 100%;
  height: 828px;

  border-radius: 8px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
