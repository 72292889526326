<template>
  <div class="content">
    <component :is="currentComponent" :key="$route.name" />
    <NavInternal />
  </div>
</template>

<script lang="ts">
import NavInternal from '@/components/BrandPlataformPage/NavInternal.vue'
import DifferentiatingBenefitsBannerSection from '@/components/BrandPlataformPage/sections/differentiating-benefits/DifferentiatingBenefitsBannerSection.vue'
import OurBeliefsBannerSection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsBannerSection.vue'
import PersonalityBannerSection from '@/components/BrandPlataformPage/sections/personality/PersonalityBannerSection.vue'
import PublicBannerSection  from '@/components/BrandPlataformPage/sections/public/PublicBannerSection.vue'
import VocationBannerSection  from '@/components/BrandPlataformPage/sections/vocation/VocationBannerSection.vue'
import { Component, Vue } from 'vue-property-decorator'
import BannerBrandPlataformImageSection from '@/components/BrandPlataformPage/sections/BannerBrandPlataformImageSection.vue'

@Component({
  components: {
    NavInternal,
    DifferentiatingBenefitsBannerSection,
    OurBeliefsBannerSection,
    PersonalityBannerSection,
    PublicBannerSection,
    VocationBannerSection
  }
})
export default class BrandPlataformView extends Vue {
  private routeComponentMap: { [key: string]: any } = {
    'plataforma-de-marca': BannerBrandPlataformImageSection,
    'plataforma-de-marca.beneficios-diferenciadores': DifferentiatingBenefitsBannerSection,
    'plataforma-de-marca.nossas-crencas': OurBeliefsBannerSection,
    'plataforma-de-marca.personalidade': PersonalityBannerSection,
    'plataforma-de-marca.publico': PublicBannerSection,
    'plataforma-de-marca.vocacao':VocationBannerSection
  }

  get currentComponent() {
    return this.routeComponentMap[this.$route.name as string] || BannerBrandPlataformImageSection
  }
}
</script>

<style lang="scss">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
