<template>
  <div class="content">
    <ColorsDoItBeautifulSection />
    <ColorsConceptSection />
    <ColorsGridPhotosSection />
    <ColorsMainPalleteSection />
    <ColorsGradientsSection />
    <ColorsForTextSection />
    <ColorsCodesSection />
    <ColorsGridWhatNotToDo />
    <ColorsGridUsageExamplesSection />
  </div>
</template>

<script lang="ts">
import ColorsDoItBeautifulSection from '@/components/VisualIdentityPage/sections/colors/ColorsDoItBeautifulSection.vue'
import ColorsConceptSection from '@/components/VisualIdentityPage/sections/colors/ColorsConceptSection.vue'
import ColorsGridPhotosSection from '@/components/VisualIdentityPage/sections/colors/ColorsGridPhotosSection.vue'
import ColorsMainPalleteSection from '@/components/VisualIdentityPage/sections/colors/ColorsMainPalleteSection.vue'
import ColorsGradientsSection from '@/components/VisualIdentityPage/sections/colors/ColorsGradientsSection.vue'
import ColorsForTextSection from '@/components/VisualIdentityPage/sections/colors/ColorsForTextSection.vue'
import ColorsCodesSection from '@/components/VisualIdentityPage/sections/colors/ColorsCodesSection.vue'
import ColorsGridWhatNotToDo from '@/components/VisualIdentityPage/sections/colors/ColorsGridWhatNotToDo.vue'
import ColorsGridUsageExamplesSection from '@/components/VisualIdentityPage/sections/colors/ColorsGridUsageExamplesSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ColorsDoItBeautifulSection,
    ColorsConceptSection,
    ColorsGridPhotosSection,
    ColorsMainPalleteSection,
    ColorsGradientsSection,
    ColorsForTextSection,
    ColorsCodesSection,
    ColorsGridWhatNotToDo,
    ColorsGridUsageExamplesSection
  }
})
export default class ColorView extends Vue {}
</script>

<style lang="scss"></style>
