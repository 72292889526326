<template>
  <div class="our-beliefs-technology-content">
    <div
      class="our-beliefs-technology-banner-image"
      :style="{ backgroundImage: `url(${ourBeliefsTechnologyImageSrc})` }"
    ></div>

    <div class="our-beliefs-technology-overlay-title-image">
      <p class="our-beliefs-technology-image-technology-title">
        {{ ourBeliefsTechnologyImageTitle }}
      </p>
      <p class="our-beliefs-technology-image-technology-title">
        {{ ourBeliefsTechnologyImageTitle2 }}
      </p>
      <p class="our-beliefs-technology-image-technology-title2">
        {{ ourBeliefsTechnologyImageTitle3 }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import OurBeliefsPageMixin from '@/mixins/our-beliefs-page-mixin'
import { OurBeliefsPageStore } from '@/store/brand-plataform-page/our-beliefs-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class OurBeliefsTechnologySection extends Mixins(OurBeliefsPageMixin) {
  ourBeliefsPageStore: PageStoreContract = getModule(OurBeliefsPageStore, this.$store)

  get ourBeliefsTechnologyImageTitle(): string {
    return this.getElementText(2, 0)
  }

  get ourBeliefsTechnologyImageSrc(): string {
    return this.getElementMedia(2, 0)
  }

  get ourBeliefsTechnologyImageTitle2(): string {
    return this.getElementText(2, 1)
  }

  get ourBeliefsTechnologyImageTitle3(): string {
    return this.getElementText(2, 2)
  }

  async getOurBeliefsPageStore(): Promise<void> {
    await this.ourBeliefsPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.ourBeliefsPageStore.isLoading) {
      await this.getOurBeliefsPageStore()
    }
  }
}
</script>

<style lang="scss">
.our-beliefs-technology-content {
  display: grid;
  margin-top: 2rem;
  position: relative;  
  justify-content: center;
  align-items: center;  
}

.our-beliefs-technology-banner-image {
  position: relative;
  bottom: 0;  
  width: 1045px;
  height: 513px;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5)!important; 
  }
}

.our-beliefs-technology-overlay-title-image {
  position: absolute;
  bottom: 0rem;
  left: 1rem;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
  margin-top: 50rem;
  align-items: center;
}

.our-beliefs-technology-image-technology-title {
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: center;
  width: 1015px;
  margin: 0;
}

.our-beliefs-technology-image-technology-title2 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 20px;
  text-align: center;
  width: 509px;
  margin: 0;
}
</style>
