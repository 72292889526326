<template>
  <div class="color-main-pallete-content">
    <div class="color-main-pallete-title-wrapper">
      <h1 class="color-main-pallete-title-span">{{ colorMainPalleteTitleSpan }}</h1>
      <h1 class="color-main-pallete-title">{{ colorMainPalleteTitle }}</h1>
    </div>
    <div class="description-color-main-pallete-title-icons-wrapper">
      <p class="color-main-pallete-description">{{ colorMainPalleteDescription1 }}</p>
      <p class="color-main-pallete-description">{{ colorMainPalleteDescription2 }}</p>
      <div class="color-main-pallete-icon-wrapper-first">
        <IconCheck />
        <p class="color-main-pallete-description">{{ colorMainPalleteDescription3 }}</p>
      </div>
      <div class="color-main-pallete-icon-wrapper">
        <div class="color-description-detaque">
          <p class="color-main-pallete-description">{{ colorMainPalleteDescription4 }}</p>
          <p class="color-main-pallete-description">{{ colorMainPalleteDescription5 }}</p>
        </div>
      </div>
    </div>
    <div
      class="color-main-pallete-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})` }"
    ></div>
    <div class="button-main-pallete-wrapper">
      <ColorsButton
        :imageSrc="colorMainPalleteImageSrc"
        buttonText="Acesse aqui nosso banco de arquivos"
        downloadName="Cores.zip"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import ColorsButton from './ColorsButton.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'

@Component({
  components: {
    IconCheck,
    ColorsButton
  }
})
export default class ColorsMainPalleteSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get colorMainPalleteTitleSpan(): string {
    return this.getElementTitle(4, 0)
  }

  get colorMainPalleteTitle(): string {
    return this.getElementText(4, 0)
  }

  get colorMainPalleteDescription1(): string {
    return this.getElementText(4, 1)
  }

  get colorMainPalleteDescription2(): string {
    return this.getElementText(4, 2)
  }

  get colorMainPalleteDescription3(): string {
    return this.getElementText(4, 3)
  }

  get colorMainPalleteDescription4(): string {
    return this.getElementText(4, 4)
  }

  get colorMainPalleteDescription5(): string {
    return this.getElementText(4, 5)
  }

  get ImageSrc(): string {
    return this.getElementMedia(4, 6)
  }

  get colorMainPalleteImageSrc(): string {
    return this.getElementMedia(4, 7)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.color-main-pallete-title-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.color-main-pallete-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-right: 5px;
  color: #b3b3b3;
}

.color-main-pallete-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.description-color-main-pallete-title-icons-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  width: 100%;
  max-width: 1045px;
}

.color-main-pallete-description {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.color-main-pallete-icon-wrapper-first {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}

.color-main-pallete-icon-wrapper {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}

.color-main-pallete-card-item {
  position: relative;
  bottom: 1rem;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 766px;

  background-repeat: no-repeat;
  background-position: center;
}

.color-description-detaque {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 484px;
}

.button-main-pallete-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5rem;
  padding-bottom: 8rem;
}
</style>
