<template>
  <div class="graphic-iconographic-content">
    <div class="graphic-iconographic-title-wrapper">
      <h1 class="graphic-iconographic-title-span">{{ graphicIconographicTitleSpan }}</h1>
      <h1 class="graphic-iconographic-title">{{ graphicIconographicTitle }}</h1>
    </div>
    <div class="description-graphic-iconographic-title-icons-wrapper">
      <div class="graphic-iconographic-icon-wrapper-first">
        <p class="graphic-iconographic-description">{{ graphicIconographicDescription1 }}</p>
      </div>

      <div class="graphic-iconographic-icon-wrapper-sec">
        <p class="graphic-iconographic-description">{{ graphicIconographicDescription2 }}</p>
      </div>
    </div>
    <div
      class="graphic-iconographic-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GraphicPageMixin from '@/mixins/graphic-page-mixin'
import { GraphicPageStore } from '@/store/visual-identity-page/graphic-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class GraphicsIconographicSection extends Mixins(GraphicPageMixin) {
  graphicPageStore: PageStoreContract = getModule(GraphicPageStore, this.$store)

  get graphicIconographicTitleSpan(): string {
    return this.getElementTitle(9, 0)
  }

  get graphicIconographicTitle(): string {
    return this.getElementText(9, 0)
  }

  get graphicIconographicDescription1(): string {
    return this.getElementText(9, 1)
  }

  get graphicIconographicDescription2(): string {
    return this.getElementText(9, 2)
  }

  get ImageSrc(): string {
    return this.getElementMedia(9, 3)
  }

  async getGraphicPage(): Promise<void> {
    await this.graphicPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.graphicPageStore.isLoading) {
      await this.getGraphicPage()
    }
  }
}
</script>

<style lang="scss">
.graphic-iconographic-content {
  display: grid;
}

.graphic-iconographic-title-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.graphic-iconographic-title-span {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-right: 5px;
  color: #b3b3b3;
}

.graphic-iconographic-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.description-graphic-iconographic-title-icons-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.graphic-iconographic-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin: 0;

  padding-left: 7px;
  width: 434px;
}

.graphic-iconographic-icon-wrapper-first {
  display: flex;
  align-items: center;
}

.graphic-iconographic-icon-wrapper-sec {
  display: flex;
  align-items: center;
}

.graphic-iconographic-icon-wrapper {
  display: flex;
  align-items: center;
}

.graphic-iconographic-icon-wrapper-thr {
  display: flex;
  align-items: center;
}

.graphic-iconographic-card-item {
  position: relative;
  bottom: 1rem;
  left: 3rem;
  width: 1045px;
  height: 766px;
  background-repeat: no-repeat;
  background-position: center;
}

.graphic-iconographic-description-fica-a-dica {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 434px;
}
</style>
