<template>
    <div class="public-graphic-content">
      <div class="public-graphic-content">    
      <div
        class="public-graphic-card-item"
        :style="{ backgroundImage: `url(${graphicImageSrc})` }"
      ></div>
    </div>
    </div>
  </template>
  
  <script lang="ts">
    import { PageStoreContract } from '@/data/store/page.store.contract'
    import PublicPageMixin from '@/mixins/public-page-mixin'
    import { PublicPageStore } from '@/store/brand-plataform-page/public-store'
    import { Component, Mixins } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
  
  @Component({})
  export default class PublicGraphicSection extends Mixins(PublicPageMixin) {
    publicPageStore: PageStoreContract = getModule(PublicPageStore, this.$store)
  
    get graphicImageSrc(): string {
      return this.getElementMedia(3, 0)
    }  
  
    async getPublicPageStore(): Promise<void> {
      await this.publicPageStore.getDataPage()
    }
  
    async mounted(): Promise<void> {
      if (!this.publicPageStore.isLoading) {
        await this.getPublicPageStore()
      }
    }
  }
  </script>
  
  <style lang="scss">
  .public-graphic-content {
    margin-top: 5px;
    display: grid;  
    position: relative;  
    justify-content: center;
    align-items: center;    
  }
  
  .public-graphic-card-item {
    position: relative;
    bottom: 0;  
    width: 1045px;
    height: 646px;
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
  }
  </style>
  