<template>
  <div class="content-navbar">
    <b-navbar class="brand-plataform-nav" variant="light">
      <router-link :to="{ name: 'plataforma-de-marca' }" class="nav-link" active-class="active-link"
        >Plataforma de marca</router-link
      >
      <router-link
        :to="{ name: 'plataforma-de-marca.publico' }"
        class="nav-link"
        active-class="active-link"
        >Público</router-link
      >
      <router-link
        :to="{ name: 'plataforma-de-marca.beneficios-diferenciadores' }"
        class="nav-link"
        active-class="active-link"
        >Benefícios Diferenciadores</router-link
      >
      <router-link
        :to="{ name: 'plataforma-de-marca.nossas-crencas' }"
        class="nav-link"
        active-class="active-link"
        >Nossas Crenças</router-link
      >
      <router-link
        :to="{ name: 'plataforma-de-marca.personalidade' }"
        class="nav-link"
        active-class="active-link"
        >Personalidade
      </router-link>
      <router-link
        :to="{ name: 'plataforma-de-marca.vocacao' }"
        class="nav-link"
        active-class="active-link"
        >Vocação
      </router-link>
    </b-navbar>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class NavInternal extends Vue {}
</script>

<style lang="scss">
.brand-plataform-nav {
  display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    margin-top: 3rem;
    max-width: 949px;
}

.brand-plataform-nav a {
  text-decoration: none;
  color: #999;
  font-weight: bold;
  padding: 10px;
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: left;
}

.brand-plataform-nav a.active-link {
  color: #000;
  border-bottom: 2px solid #000;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
