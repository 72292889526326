<template>
  <div class="color-gradients-content">
    <div class="color-gradients-title-wrapper">
      <h1 class="color-gradients-title-span">{{ colorGradientsTitleSpan }}</h1>
      <h1 class="color-gradients-title">{{ colorGradientsTitle }}</h1>
    </div>
    <div class="description-color-gradients-title-icons-wrapper">
      <div class="color-gradients-icon-wrapper-first">
        <IconCheck />
        <p class="color-gradients-description">{{ colorGradientsDescription }}</p>
      </div>
      <div class="color-gradients-icon-wrapper">
        <IconCancel />
        <div class="color-description-gradients">
          <p class="color-description-detaque">{{ colorGradientsDescription2 }}</p>
          <p class="color-gradients-description">{{ colorGradientsDescription3 }}</p>
        </div>
      </div>
    </div>
    <div class="color-gradients-card-item" :style="{ backgroundImage: `url(${ImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import IconCancel from '@/assets/icons/IconCancel.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck, IconCancel } })
export default class ColorsGradientsSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get colorGradientsTitleSpan(): string {
    return this.getElementTitle(5, 0)
  }

  get colorGradientsTitle(): string {
    return this.getElementText(5, 0)
  }

  get colorGradientsDescription(): string {
    return this.getElementText(5, 1)
  }

  get colorGradientsDescription2(): string {
    return this.getElementText(5, 2)
  }

  get colorGradientsDescription3(): string {
    return this.getElementText(5, 3)
  }

  get ImageSrc(): string {
    return this.getElementMedia(5, 4)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.color-gradients-content {
  display: grid;
  max-width: 1045px;
  width: 100%;
}

.color-gradients-title-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.color-gradients-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-right: 5px;
  color: #b3b3b3;
}

.color-gradients-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.description-color-gradients-title-icons-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  width: 100%;
  max-width: 1045px;
}

.color-gradients-description {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  width: 374px;
  padding: 1rem;
}

.color-gradients-icon-wrapper-first {
  display: flex;
  align-items: center;
}

.color-gradients-icon-wrapper {
  display: flex;
  align-items: center;
}

.color-gradients-card-item {
  position: relative;
  bottom: 3rem;
  left: 0;

  max-width: 1045px;
  width: 100%;
  height: 766px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
