import { render, staticRenderFns } from "./OurBeliefsCredibilitySection.vue?vue&type=template&id=ef5499fa"
import script from "./OurBeliefsCredibilitySection.vue?vue&type=script&lang=ts"
export * from "./OurBeliefsCredibilitySection.vue?vue&type=script&lang=ts"
import style0 from "./OurBeliefsCredibilitySection.vue?vue&type=style&index=0&id=ef5499fa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports