<template>
  <div class="iconography-content">
    <div
      class="iconography-banner-image"
      :style="{ backgroundImage: `url(${iconographyBannerImageSrc})` }"
    ></div>
    <div class="iconography-overlay-image">
      <h1 class="iconography-banner-title">{{ iconographyBannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import IconographyPageMixin from '@/mixins/iconography-page-mixin'
import { IconographyPageStore } from '@/store/visual-identity-page/iconography-page'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class IconographyBannerSection extends Mixins(IconographyPageMixin) {
  iconographyPageStore: PageStoreContract = getModule(IconographyPageStore, this.$store)

  get iconographyBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get iconographyBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getIconographyPage(): Promise<void> {
    await this.iconographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.iconographyPageStore.isLoading) {
      await this.getIconographyPage()
    }
  }
}
</script>

<style lang="scss">
.iconography-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;

  width: 100%;
  max-height: 28rem;
}

.iconography-banner-image {
  position: relative;
  width: 100%;
  height: 451px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.iconography-overlay-image {
  position: absolute;
  bottom: 10rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.iconography-banner-title {
  font-size: 7rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

@media (min-width: 1726px) {
  .iconography-content {
    width: 100%;
  }
}
</style>
