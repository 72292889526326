<template>
  <div class="layouts-combining-assets-content">
    <div class="title-layouts-combining-assets-wrapper">
      <h1 class="layouts-combining-assets-title-span">{{ layoutsCombiningAssetsTitleSpan }}</h1>
      <h1 class="layouts-combining-assets-title">{{ layoutsCombiningAssetsTitle }}</h1>
    </div>
    <div class="description-layouts-combining-assets-wrapper">
      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-combining-assets-description">{{ layoutsCombiningAssetsDescription }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-combining-assets-description">{{ layoutsCombiningAssetsDescription1 }}</p>
      </div>
    </div>
    <div
      class="layouts-combining-assets-card-item"
      :style="{ backgroundImage: `url(${layoutsCombiningAssetsImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class LayoutsCombiningAssetsSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutsCombiningAssetsTitleSpan(): string {
    return this.getElementTitle(3, 0)
  }

  get layoutsCombiningAssetsTitle(): string {
    return this.getElementText(3, 0)
  }

  get layoutsCombiningAssetsDescription(): string {
    return this.getElementText(3, 1)
  }

  get layoutsCombiningAssetsDescription1(): string {
    return this.getElementText(3, 2)
  }

  get layoutsCombiningAssetsImageSrc(): string {
    return this.getElementMedia(3, 3)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layouts-combining-assets-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-layouts-combining-assets-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.layouts-combining-assets-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.layouts-combining-assets-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-layouts-combining-assets-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.layouts-combining-assets-description {
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.layouts-combining-assets-card-item {
  position: relative;
  bottom: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 500px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
