<template>
  <div class="public-person1-content">
    <div class="description-public-person1-content">
      <div class="description-public-person1-container0" >
        <div class="description-public-person1-container left" :class="{ visible: showDescription1 }">
          <p class="public-person1-description3">{{ introDescription6 }}</p>
          <div class="line"></div>
        </div>
        <div class="description-public-person1-container right" :class="{ visible: showDescription2 }">
          <p class="public-person1-description3">{{ introDescription7 }}</p>
          <div class="line"></div>
        </div>
      </div>
      <div class="description-public-person1-container2">
        <p class="public-person1-description1" @mouseenter="showDescription1 = true"
          @mouseleave="showDescription1 = false">
          {{ introDescription }}
        </p>
        <p class="public-person1-description2">{{ introDescription2 }}</p>
        <p class="public-person1-description1" @mouseenter="showDescription2 = true"
          @mouseleave="showDescription2 = false">
          {{ introDescription3 }}
        </p>
      </div>
      <div class="description-public-person1-container3">
        <p class="public-person1-description2">{{ introDescription4 }}</p>
        <p class="public-person1-description1" @mouseenter="showDescription3 = true"
          @mouseleave="showDescription3 = false">
          {{ introDescription5 }}
        </p>
      </div>
      <div class="description-public-person3-container" :class="{ visible: showDescription3 }">
        <div class="line"></div>
        <p class="public-person3-description3">{{ introDescription8 }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PublicPageMixin from '@/mixins/public-page-mixin'
import { PublicPageStore } from '@/store/brand-plataform-page/public-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class PublicPersons1Section extends Mixins(PublicPageMixin) {
  publicPageStore: PageStoreContract = getModule(PublicPageStore, this.$store)
  showDescription1 = false
  showDescription2 = false
  showDescription3 = false

  get introDescription(): string {
    return this.getElementText(2, 0)
  }

  get introDescription2(): string {
    return this.getElementText(2, 1)
  }

  get introDescription3(): string {
    return this.getElementText(2, 2)
  }

  get introDescription4(): string {
    return this.getElementText(2, 3)
  }
  get introDescription5(): string {
    return this.getElementText(2, 4)
  }
  get introDescription6(): string {
    return this.getElementText(2, 5)
  }
  get introDescription7(): string {
    return this.getElementText(2, 6)
  }
  get introDescription8(): string {
    return this.getElementText(2, 7)
  }

  async getPublicPage(): Promise<void> {
    await this.publicPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.publicPageStore.isLoading) {
      await this.getPublicPage()
    }
  }
}
</script>

<style lang="scss">
.public-person1-content {
  width: 100%;
  max-width: 1045px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description-public-person1-content {
  max-width: 949px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .description-public-person1-container0{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 80%;
  }

  .description-public-person1-container {
    opacity: 0;
    transition: opacity 1.5s;
    display: flex;
    width: 327px;
    flex-direction: column;
    align-items: center;
    position: relative;
    &.right{
      right: -56px;
    }
    .line {
      width: 2px;
      height: 90px;
      border-left: solid 1px #818181;
    }
  }

  .description-public-person1-container.visible {
    opacity: 1;
  }

  .description-public-person1-container2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

  }

  .description-public-person1-container3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0px;
  }
}

.public-person1-description1 {
  font-size: 80px;
  color: #000;
  margin: 0px;
  padding-right: 15px;
  padding-left: 15px;
  transition: ease-in 1.5s;

  &:hover {
    background-color: rgba(255, 0, 0, 0);
    background-image: linear-gradient(90deg, #0079FD -37.91%, #2D51FB 20.11%, #5A29FA 78.13%, #8800F8 137.91%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-size: 80px;
    margin: 0px;
  }
}

.public-person1-description2 {
  font-size: 80px;
  color: #000;
  margin: 0px;
}

.description-public-person3-container {
  opacity: 0;
  transition: opacity 1.5s;
  display: flex;
  width: 327px;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 353px;
  margin-top: 10px;
  margin-bottom: 60px;

  .line {
    width: 2px;
    height: 90px;
    border-left: solid 1px #818181;
  }
}

.description-public-person3-container.visible {
  opacity: 1;
}
</style>
