<template>
  <div class="layouts-combining-assets-photos-content">
    <div class="description-layouts-combining-assets-photos-wrapper">
      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-combining-assets-photos-description">
          {{ layoutsCombiningAssetsPhotosDescription }}
        </p>
      </div>

      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-combining-assets-photos-description">
          {{ layoutsCombiningAssetsPhotosDescription1 }}
        </p>
      </div>

      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-combining-assets-photos-description">
          {{ layoutsCombiningAssetsPhotosDescription2 }}
        </p>
      </div>
    </div>
    <div
      class="layouts-combining-assets-photos-card-item"
      :style="{ backgroundImage: `url(${layoutsCombiningAssetsPhotosImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class LayoutsCombinigAssetsPhotosSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutsCombiningAssetsPhotosDescription(): string {
    return this.getElementText(4, 0)
  }

  get layoutsCombiningAssetsPhotosDescription1(): string {
    return this.getElementText(4, 1)
  }

  get layoutsCombiningAssetsPhotosDescription2(): string {
    return this.getElementText(4, 2)
  }

  get layoutsCombiningAssetsPhotosImageSrc(): string {
    return this.getElementMedia(4, 3)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layouts-combining-assets-photos-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.description-layouts-combining-assets-photos-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.layouts-combining-assets-photos-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.layouts-combining-assets-photos-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.layouts-combining-assets-photos-description {
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.layouts-combining-assets-photos-card-item {
  position: relative;
  bottom: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 500px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
