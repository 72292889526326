import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  }
}

export abstract class HttpClient {
  protected readonly client: AxiosInstance

  protected constructor(baseURL: string, headers?: any) {
    this.client = axios.create({ baseURL, headers })

    this.client.interceptors.request.use(
      (config) => {
        const token = window.localStorage.getItem('token')

        if (token) {
          config.headers.token = `${token}`
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }
}
