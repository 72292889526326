<template>
  <div class="graphic-logo-content">
    <div class="graphic-logo-title-wrapper">
      <h1 class="graphic-logo-title-span">{{ graphicSymbolTitleSpan }}</h1>
      <h1 class="graphic-logo-title">{{ graphicSymbolTitle }}</h1>
    </div>
    <div class="description-graphic-logo-title-icons-wrapper">
      <p class="graphic-logo-description">{{ graphicSymbolDescription1 }}</p>
      <p class="graphic-logo-description">{{ graphicSymbolDescription2 }}</p>
      <div class="graphic-logo-icon-wrapper-first">
        <IconCheck />
        <p class="graphic-logo-description">{{ graphicSymbolDescription3 }}</p>
      </div>
      <div class="graphic-logo-icon-wrapper-sec">
        <IconCheck />
        <p class="graphic-logo-description">{{ graphicSymbolDescription4 }}</p>
      </div>
    </div>
    <div class="graphic-logo-card-item" :style="{ backgroundImage: `url(${ImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import GraphicPageMixin from '@/mixins/graphic-page-mixin'
import { GraphicPageStore } from '@/store/visual-identity-page/graphic-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class GraphicGraphicsLogoSection extends Mixins(GraphicPageMixin) {
  graphicPageStore: PageStoreContract = getModule(GraphicPageStore, this.$store)

  get graphicSymbolTitleSpan(): string {
    return this.getElementTitle(4, 0)
  }

  get graphicSymbolTitle(): string {
    return this.getElementText(4, 0)
  }

  get graphicSymbolDescription1(): string {
    return this.getElementText(4, 1)
  }

  get graphicSymbolDescription2(): string {
    return this.getElementText(4, 2)
  }

  get graphicSymbolDescription3(): string {
    return this.getElementText(4, 3)
  }

  get graphicSymbolDescription4(): string {
    return this.getElementText(4, 4)
  }

  get graphicSymbolDescription5(): string {
    return this.getElementText(4, 5)
  }

  get ImageSrc(): string {
    return this.getElementMedia(4, 5)
  }

  async getGraphicPage(): Promise<void> {
    await this.graphicPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.graphicPageStore.isLoading) {
      await this.getGraphicPage()
    }
  }
}
</script>

<style lang="scss">
.graphic-logo-content {
  display: grid;
}

.graphic-logo-title-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.graphic-logo-title-span {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-right: 5px;
  color: #b3b3b3;
}

.graphic-logo-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.description-graphic-logo-title-icons-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.graphic-logo-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-top: 1rem;
  padding-left: 7px;
  width: 434px;
}

.graphic-logo-icon-wrapper-first {
  display: flex;
  align-items: center;
  width: 434px;
}

.graphic-logo-icon-wrapper-sec {
  display: flex;
  align-items: center;
  width: 434px;
}

.graphic-logo-icon-wrapper {
  display: flex;
  align-items: center;
}

.graphic-logo-card-item {
  position: relative;
  bottom: 1rem;
  left: 3rem;
  width: 1045px;
  height: 766px;
  background-repeat: no-repeat;
  background-position: center;
}

.graphic-logo-description-fica-a-dica {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  margin: 0;
  padding-left: 7px;
  width: 434px;
}
</style>
