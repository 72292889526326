<template>
  <div class="content-identities">
    <h2>Identidades</h2>
    <div class="cards-identities">
      <CardIdentitiesVue class="card-identities-position-1">
        <h2>Globo</h2>
        <img class="icon" src="../../../assets/icons/brand-button.svg" />
      </CardIdentitiesVue>
      <CardIdentitiesVue class="card-identities-position-2">
        <h2>Híbrida</h2>
        <img class="icon" src="../../../assets/icons/brand-button.svg" />
      </CardIdentitiesVue>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import CardIdentitiesVue from '../CardIdentities.vue'

@Component({
  components: {
    CardIdentitiesVue
  }
})
export default class IdentitiesSection extends Vue {}
</script>

<style scoped lang="scss">
.content-identities {
  max-width: 1045px;
  width: 95%;
  padding-top: 3rem;
  padding-left: 3rem;
  margin-bottom: 10rem;
}

.cards-identities{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card-identities-position-1 {
  position: relative;
  width: 527px;
  height: 144px;
  padding: 0px;
  margin-right: 1rem;
  background: linear-gradient(270deg, #8800f8 0%, #5a29fa 33.15%, #2d51fb 66.31%, #0079fd 100.47%);

  border-radius: 9.9px;

  h2 {
    color: #fff;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 400;
    padding: 2rem;
    padding-left: 32px;
    font-family: GlobotipoCorporativaTextosBold;
  }
}

.card-identities-position-2 {
  position: relative;
  width: 527px;
  height: 144px;
  padding: 0px;
  background: linear-gradient(90deg, #fdcd02 -115.64%, #ff0c1f 215.64%);

  border-radius: 9.9px;

  h2 {
    color: #fff;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 400;
    padding: 2rem;
    padding-left: 32px;
    font-family: GlobotipoCorporativaTextosBold;
  }
}

.icon {
  position: absolute;
  top: 4rem;
  left: 24.5rem;
  
}

</style>
