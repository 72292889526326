<template>
  <div class="content">
    <DifferentiatingBenefitsIntroSection />
    <DifferentiatingBenefitsRelevanceSection />
    <DifferentiatingBenefitsBrazilianitySection />
    <DifferentiatingBenefitsQualitySection />
    <DifferentiatingBenefitsComunitSection />
    <DifferentiatingBenefitsGraphicSection/>
  </div>
</template>

<script lang="ts">
import DifferentiatingBenefitsIntroSection from '@/components/BrandPlataformPage/sections/differentiating-benefits/DifferentiatingBenefitsIntroSection.vue'
import DifferentiatingBenefitsRelevanceSection from '@/components/BrandPlataformPage/sections/differentiating-benefits/DifferentiatingBenefitsRelevanceSection.vue'
import DifferentiatingBenefitsBrazilianitySection from '@/components/BrandPlataformPage/sections/differentiating-benefits/DifferentiatingBenefitsBrazilianitySection.vue'
import DifferentiatingBenefitsQualitySection from '@/components/BrandPlataformPage/sections/differentiating-benefits/DifferentiatingBenefitsQualitySection.vue'
import DifferentiatingBenefitsComunitSection from '@/components/BrandPlataformPage/sections/differentiating-benefits/DifferentiatingBenefitsComunitSection.vue'
import DifferentiatingBenefitsGraphicSection from '@/components/BrandPlataformPage/sections/differentiating-benefits/DifferentiatingBenefitsGraphicSection.vue'
import { Component, Vue } from 'vue-property-decorator'


@Component({
  components: {
    DifferentiatingBenefitsIntroSection,
    DifferentiatingBenefitsRelevanceSection,
    DifferentiatingBenefitsBrazilianitySection,
    DifferentiatingBenefitsQualitySection,
    DifferentiatingBenefitsComunitSection,
    DifferentiatingBenefitsGraphicSection
  }
})
export default class DifferentiatingBenefitsView extends Vue {}
</script>

<style lang="scss"></style>
