<template>
  <div class="logo-full-signature-content">
    <div class="description-full-signature-wrapper">
      <div class="logo-full-signature-icon-wrapper">
        <p class="logo-full-signature-description" style="font-size: 30px; font-weight: 300">
          {{ logoFullSignatureDescription15 }}
        </p>
      </div>
    </div>
    <div
      class="logo-full-signature-card-item-4"
      :style="{ backgroundImage: `url(${logoFullSignatureImageSrc9})` }"
    ></div>
    <div
      class="logo-full-signature-card-item-5"
      :style="{ backgroundImage: `url(${logoFullSignatureImageSrc10})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LogoButton from './LogoButton.vue'

@Component({
  components: {
    LogoButton
  }
})
export default class LogoSignatureCentralizedSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoFullSignatureDescription15(): string {
    return this.getElementText(16, 24)
  }

  get logoFullSignatureImageSrc9(): string {
    return this.getElementMedia(16, 25)
  }

  get logoFullSignatureImageSrc10(): string {
    return this.getElementMedia(16, 26)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-full-signature-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.logo-full-signature-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 100%
}

.logo-full-signature-icon-wrapper {
  display: flex;
  align-items: center;
}

.logo-full-signature-card-item {
  position: relative;
  bottom: 0;
  width: 95%;
  max-width: 1045px;
  height: 558px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-full-signature-card-item-4 {
  position: relative;
  bottom: 3rem;
  left: 3rem;

  max-width: 1045px;
  width: 95%;
  height: 336px;

  border-radius: 20px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-full-signature-card-item-5 {
  position: relative;
  left: 3rem;

  max-width: 1045px;
  width: 90%;
  height: 531px;

  border-radius: 20px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
