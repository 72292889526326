<template>
  <div class="logo-full-signature-content">
    <div class="title-full-signature-wrapper">
      <h1 class="logo-full-signature-title-span">{{ logoFullSignatureTitleSpan }}</h1>
      <h1 class="logo-full-signature-title">{{ logoFullSignatureTitle }}</h1>
    </div>

    <div class="description-full-signature-wrapper">
      <div>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription }}</p>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription1 }}</p>
      </div>

      <div class="logo-full-signature-icon-wrapper">
        <IconCancel />
        <div>
          <p class="logo-full-signature-description-second-title">
            {{ logoFullSignatureDescription2 }}
          </p>
          <p class="logo-full-signature-description-second">{{ logoFullSignatureDescription3 }}</p>
        </div>
      </div>
    </div>

    <div class="logo-full-signature-card-item" :style="{ backgroundImage: `url(${logoFullSignatureImageSrc})` }"></div>
    <div class="button-full-signature-wrapper">
      <LogoButton :imageSrc="logoFullSignatureImageSrc" buttonText="Acesse aqui nosso banco de arquivos"
        downloadName="logos.png" />
    </div>

    <div class="description-full-signature-wrapper">
      <div>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription4 }}</p>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription5 }}</p>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription6 }}</p>
      </div>
    </div>
    <div class="logo-full-signature-card-item-2" :style="{ backgroundImage: `url(${logoFullSignatureImageSrc2})` }">
    </div>

    <div class="description-full-signature-wrapper">
      <div>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription7 }}</p>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription8 }}</p>
        <p class="logo-full-signature-description">{{ logoFullSignatureDescription9 }}</p>
      </div>
    </div>
    <div class="logo-full-signature-card-item-2" :style="{ backgroundImage: `url(${logoFullSignatureImageSrc3})` }">
    </div>

    <div class="description-full-signature-wrapper">
      <div class="logo-full-signature-icon-wrapper">
        <IconCheck />
        <p class="logo-full-signature-description" style="width: 374px">
          {{ logoFullSignatureDescription10 }}
        </p>
      </div>
    </div>
    <div class="logo-full-signature-card-item-3" :style="{ backgroundImage: `url(${logoFullSignatureImageSrc4})` }">
    </div>

    <div class="description-full-signature-wrapper">
      <div class="logo-full-signature-icon-wrapper" style="padding-top: 4rem">
        <IconCheck />
        <p class="logo-full-signature-description" style="width: 374px">
          {{ logoFullSignatureDescription11 }}
        </p>
      </div>
    </div>
    <div class="logo-full-signature-card-item-3" :style="{ backgroundImage: `url(${logoFullSignatureImageSrc5})` }">
    </div>

    <div class="description-full-signature-wrapper">
      <div class="logo-full-signature-icon-wrapper" style="padding-top: 4rem">
        <IconCheck />
        <p class="logo-full-signature-description" style="width: 374px">
          {{ logoFullSignatureDescription12 }}
        </p>
      </div>
    </div>
    <div class="logo-full-signature-card-item-4-new" :style="{ backgroundImage: `url(${logoFullSignatureImageSrc6})` }">
    </div>
    <div class="description-full-signature-wrapper">
      <div class="logo-full-signature-icon-wrapper">
        <p class="logo-full-signature-description" style="width: 374px">
          {{ logoFullSignatureDescription13 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LogoButton from './LogoButton.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'

@Component({
  components: {
    IconCancel,
    IconCheck,
    LogoButton
  }
})
export default class LogoFullSignatureSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoFullSignatureTitleSpan(): string {
    return this.getElementTitle(16, 0)
  }

  get logoFullSignatureTitle(): string {
    return this.getElementText(16, 0)
  }

  get logoFullSignatureDescription(): string {
    return this.getElementText(16, 1)
  }

  get logoFullSignatureDescription1(): string {
    return this.getElementText(16, 2)
  }

  get logoFullSignatureDescription2(): string {
    return this.getElementText(16, 3)
  }

  get logoFullSignatureDescription3(): string {
    return this.getElementText(16, 4)
  }

  get logoFullSignatureImageSrc(): string {
    return this.getElementMedia(16, 5)
  }
  //end sub section-1

  get logoFullSignatureDescription4(): string {
    return this.getElementText(16, 6)
  }

  get logoFullSignatureDescription5(): string {
    return this.getElementText(16, 7)
  }

  get logoFullSignatureDescription6(): string {
    return this.getElementText(16, 8)
  }

  get logoFullSignatureImageSrc2(): string {
    return this.getElementMedia(16, 9)
  }
  //end sub section-2

  get logoFullSignatureDescription7(): string {
    return this.getElementText(16, 10)
  }

  get logoFullSignatureDescription8(): string {
    return this.getElementText(16, 11)
  }

  get logoFullSignatureDescription9(): string {
    return this.getElementText(16, 12)
  }

  get logoFullSignatureImageSrc3(): string {
    return this.getElementMedia(16, 13)
  }
  //end sub section-3

  get logoFullSignatureDescription10(): string {
    return this.getElementText(16, 14)
  }

  get logoFullSignatureImageSrc4(): string {
    return this.getElementMedia(16, 15)
  }
  //end sub section-4

  get logoFullSignatureDescription11(): string {
    return this.getElementText(16, 16)
  }

  get logoFullSignatureImageSrc5(): string {
    return this.getElementMedia(16, 17)
  }
  //end sub section-5

  get logoFullSignatureDescription12(): string {
    return this.getElementText(16, 18)
  }

  get logoFullSignatureImageSrc6(): string {
    return this.getElementMedia(16, 19)
  }

  get logoFullSignatureDescription13(): string {
    return this.getElementText(16, 20)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-full-signature-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.title-full-signature-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-full-signature-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-full-signature-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.logo-full-signature-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 100%;
}

.logo-full-signature-description-second-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 100%;
}

.logo-full-signature-description-second {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 100%;
}

.logo-full-signature-icon-wrapper {
  display: flex;
  align-items: center;
}

.logo-full-signature-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 95%;
  height: 558px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-full-signature-card-item-2 {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 95%;
  height: 558px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-full-signature-card-item-3 {
  position: relative;
  bottom: 3rem;
  width: 95%;
  max-width: 1042px;
  height: 692px;
  border-radius: 20px;
  left: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-full-signature-card-item-4-new {
  position: relative;
  padding-top: 20px;
  width: 95%;
  max-width: 1042px;
  height: 500px;
  border-radius: 20px;
  left: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}


.button-full-signature-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5rem;
  padding-bottom: 8rem;
}

.description-full-signature-wrapper {
  max-width: 1045px;
  width: 95%;
  padding-left: 3rem;
  padding-bottom: 2rem;
}
</style>
