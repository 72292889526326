<script lang="ts">
export default {
  name: 'CardNews'
}
</script>

<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.card {
  border-radius: 15px;
  border: 1px solid #ddd;
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  width: 527px;
  height: 300px;
}
</style>
