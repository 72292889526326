<template>
    <div class="content">
      <VocationIntroSection/>
      <VocationPersons1Section/>
      <VocationGraphicSection/>
    </div>
  </template>
  
  <script lang="ts">
  import VocationIntroSection from '@/components/BrandPlataformPage/sections/vocation/VocationIntroSection.vue'
  import VocationPersons1Section from '@/components/BrandPlataformPage/sections/vocation/VocationPersons1Section.vue'
  import VocationGraphicSection from '@/components/BrandPlataformPage/sections/vocation/VocationGraphicSection.vue'
  import { Component, Vue } from 'vue-property-decorator'
  
  @Component({
    components: {
      VocationIntroSection,
      VocationPersons1Section,
      VocationGraphicSection
    }
  })
  export default class VocationView extends Vue {}
  </script>
  
  <style lang="scss"></style>
  