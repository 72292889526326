<template>
  <div class="illustration-fiction-content">
    <div class="illustration-fiction-wrapper">
      <h1 class="illustration-fiction-title-span">{{ fictionTitleSpan }}</h1>
      <h1 class="illustration-fiction-title">{{ fictionTitle }}</h1>
    </div>

    <div class="description-illustration-fiction-wrapper">
      <div class="illustration-fiction-icon-wrapper">
        <p class="illustration-fiction-description">{{ fictionDescription1 }}</p>
      </div>
      <div class="illustration-fiction-icon-wrapper">
        <div class="illustration-fica-a-dica">
          <p class="illustration-fiction-description">{{ fictionDescription2 }}</p>
          <p class="illustration-fiction-description">{{ fictionDescription3 }}</p>
        </div>
      </div>
    </div>

    <div
      class="illustration-fiction-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import IllustrationPageMixin from '@/mixins/illustration-page-mixin'
import { IllustrationPageStore } from '@/store/visual-identity-page/illustration-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class IllustrationFictionSection extends Mixins(IllustrationPageMixin) {
  illustrationPageStore: PageStoreContract = getModule(IllustrationPageStore, this.$store)

  get fictionTitleSpan(): string {
    return this.getElementTitle(12, 0)
  }

  get fictionTitle(): string {
    return this.getElementText(12, 0)
  }

  get fictionDescription1(): string {
    return this.getElementText(12, 1)
  }

  get fictionDescription2(): string {
    return this.getElementText(12, 2)
  }

  get fictionDescription3(): string {
    return this.getElementText(12, 3)
  }

  get ImageSrc(): string {
    return this.getElementMedia(12, 4)
  }

  async getIllustrationPage(): Promise<void> {
    await this.illustrationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.illustrationPageStore.isLoading) {
      await this.getIllustrationPage()
    }
  }
}
</script>

<style lang="scss">
.illustration-fiction-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.illustration-fiction-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.illustration-fiction-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.illustration-fiction-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-illustration-fiction-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.illustration-fiction-icon-wrapper {
  display: flex;
  align-items: center;
}

.illustration-fiction-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 434px;
}

.illustration-fiction-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 558px;

  background-repeat: no-repeat;
  background-position: center;
}

.illustration-description-fica-a-dica {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  margin: 0;
  padding-left: 7px;
  width: 434px;
}
</style>
