<template>
  <div class="vocation-person1-content">
    <div class="description-vocation-person1-content">
      <div class="description-vocation-person1-container0" >
        <div class="description-vocation-person1-container left" :class="{ visible: showDescription1 }">
          <p class="vocation-person1-description3">{{ introDescription5 }}</p>
          <div class="line"></div>
        </div>
        <div class="description-vocation-person1-container right" :class="{ visible: showDescription1 }">
          <p class="vocation-person1-description3">{{ introDescription6 }}</p>
          <div class="line"></div>
        </div>
        <div class="description-vocation-person1-container right" :class="{ visible: showDescription2 }">
          <p class="vocation-person1-description3">{{ introDescription7 }}</p>
          <div class="line"></div>
        </div>
      </div>
      <div class="description-vocation-person1-container2">
        <p class="vocation-person1-description1" 
          @mouseenter="showDescription1 = true"
          @mouseleave="showDescription1 = false">
          {{ introDescription1 }}
        </p>
        <p class="vocation-person1-description1" 
        @mouseenter="showDescription2 = true" 
        @mouseleave="showDescription2 = false"
        :class="{ hovered: isHovered }">
        {{ introDescription2 }}</p>
      </div>
      <div class="description-vocation-person1-container3">
        <p class="vocation-person1-description1"
        @mouseenter="showDescription2 = true" 
        @mouseleave="showDescription2 = false"
        :class="{ hovered: isHovered }">
          {{ introDescription3 }}
        </p>

        <p class="vocation-person1-description1" 
          @mouseenter="showDescription3 = true"
          @mouseleave="showDescription3 = false">
          {{ introDescription4 }}
        </p>
      </div>
      <div class="description-vocation-person3-container-low">
        <div class="description-vocation-person1-container" :class="{ visible: showDescription2 }">
          <div class="line"></div>
          <p class="vocation-person3-description3">{{ introDescription8 }}</p>
        </div>
        <div class="description-vocation-person1-container " :class="{ visible: showDescription3 }">
          <div class="line"></div>
          <p class="vocation-person3-description3">{{ introDescription9 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import VocationPageMixin from '@/mixins/vocation-page-mixin'
import { VocationPageStore } from '@/store/brand-plataform-page/vocation-store'
import { PageStoreContract } from '@/data/store/page.store.contract'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class Persons1Section extends Mixins(VocationPageMixin) {
  vocationPageStore: PageStoreContract = getModule(VocationPageStore, this.$store)
  showDescription1 = false
  showDescription2 = false
  showDescription3 = false

  get isHovered(): boolean {
    return this.showDescription2
  }

  get introDescription1(): string {
    return this.getElementText(2, 0)
  }

  get introDescription2(): string {
    return this.getElementText(2, 1)
  }

  get introDescription3(): string {
    return this.getElementText(2, 2)
  }

  get introDescription4(): string {
    return this.getElementText(2, 3)
  }
  get introDescription5(): string {
    return this.getElementText(2, 4)
  }
  get introDescription6(): string {
    return this.getElementText(2, 5)
  }
  get introDescription7(): string {
    return this.getElementText(2, 6)
  }
  get introDescription8(): string {
    return this.getElementText(2, 7)
  }
  get introDescription9(): string {
    return this.getElementText(2, 8)
  }
  get introDescription10(): string {
    return this.getElementText(2, 9)
  }
  async getVocationPage(): Promise<void> {
    await this.vocationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.vocationPageStore.isLoading) {
      await this.getVocationPage()
    }
  }
}
</script>

<style lang="scss">
.vocation-person1-content {
  width: 100%;
  max-width: 1045px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description-vocation-person1-content {
  max-width: 949px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .description-vocation-person1-container0{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 84%;
    margin-top: 60px;
  }

  .description-vocation-person1-container {
    opacity: 0;
    transition: opacity 1.5s;
    display: flex;
    width: 230px;
    flex-direction: column;
    align-items: center;
    position: relative;
    &.right, &.left{ 
      right: -50px;
    }
    .line {
      width: 2px;
      height: 90px;
      border-left: solid 1px #818181;
    }
  }

  .description-vocation-person1-container.visible {
    opacity: 1;
  }

  .description-vocation-person1-container2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

  }

  .description-vocation-person1-container3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0px;
  }
}

.vocation-person1-description1 {
  font-size: 75px;
  color: #000;
  margin: 0px;
  padding-right: 15px;
  transition: ease-in 1.5s;
  text-wrap: nowrap;

  &.hovered {
    background-color: rgba(255, 0, 0, 0);
    background-image: linear-gradient(90deg, #0079FD -37.91%, #2D51FB 20.11%, #5A29FA 78.13%, #8800F8 137.91%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    margin: 0px;
  }
  &:hover {
    background-color: rgba(255, 0, 0, 0);
    background-image: linear-gradient(90deg, #0079FD -37.91%, #2D51FB 20.11%, #5A29FA 78.13%, #8800F8 137.91%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    margin: 0px;
  }
}

.vocation-person1-description2 {
  font-size: 80px;
  color: #000;
  margin: 0px;
  text-wrap: nowrap;
}
.description-vocation-person3-container-low{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
  width: 92%;
}
.description-vocation-person3-container {
  opacity: 0;
  transition: opacity 1.5s;
  display: flex;
  width: 327px;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 353px;
  margin-top: 10px;
  margin-bottom: 60px;

  .line {
    width: 2px;
    height: 90px;
    border-left: solid 1px #818181;
  }
}

.description-vocation-person3-container.visible {
  opacity: 1;
}
</style>
