<template>
  <div>
  <div class="globo-content">
    <h1 class="globo-title">{{ globoTitle }}</h1>
    <div>
      <p class="globo-description">{{ globoDescription }}</p>
      <p class="globo-description-paragraph">{{ globoDescriptionParagraph1 }}</p>
      <p class="globo-description-paragraph">{{ globoDescriptionParagraph2 }}</p>
    </div>
  </div>
    <div class="card-item-globo-content">
      <div class="card-item-globo" :style="{ backgroundImage: `url(${globoImageSrc})` }"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GloboPageMixin from '@/mixins/globo-page-mixin'
import { GloboPageStore } from '@/store/globo-page/globo-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class WelcomeGloboSection extends Mixins(GloboPageMixin) {
  globoPageStore: PageStoreContract = getModule(GloboPageStore, this.$store)

  get globoTitle(): string {
    return this.getElementTitle(1, 0)
  }

  get globoDescription(): string {
    return this.getElementText(1, 0)
  }

  get globoDescriptionParagraph1(): string {
    return this.getElementText(1, 1)
  }

  get globoDescriptionParagraph2(): string {
    return this.getElementText(1, 2)
  }

  get globoImageSrc(): string {
    return this.getElementMedia(1, 3)
  }

  async getGloboPage(): Promise<void> {
    await this.globoPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.globoPageStore.isLoading) {
      await this.getGloboPage()
    }
  }
}
</script>

<style lang="scss">
.globo-content {
  display: grid;
  grid-template-columns: 48% 52%;
  max-width: 1045px;
  width: 100%;
  justify-items: stretch;
  justify-content: space-between;
}
.globo-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  padding-top: 3rem;
  padding-left: 3rem;
}
.globo-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding: 5rem 3rem 1rem 3rem;
}

.globo-description-paragraph {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding: 0rem 3rem 1rem 3rem;
}
.card-item-globo-content{
  display: flex;
  width: 100%;
  max-width: 1045px;
  padding-left: 3rem;
  .card-item-globo {
    position: relative;
    max-width: 1045px;
    width: 90%;
    height: 160px;
  
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
</style>
