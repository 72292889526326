<template>
  <div class="illustration-pure-colors-content">
    <div class="illustration-pure-colors-wrapper">
      <h1 class="illustration-pure-colors-title-span">{{ pureColorsTitleSpan }}</h1>
      <h1 class="illustration-pure-colors-title">{{ pureColorsTitle }}</h1>
    </div>

    <div class="description-illustration-pure-colors-wrapper">
      <div class="illustration-pure-colors-icon-wrapper">
        <p class="illustration-pure-colors-description">{{ pureColorsDescription1 }}</p>
      </div>
      <div class="illustration-pure-colors-icon-wrapper">
        <IconCancel />
        <div class="illustration-importante">
          <p class="illustration-pure-colors-description-sec">{{ pureColorsDescription2 }}</p>
          <p class="illustration-pure-colors-description-sec">{{ pureColorsDescription3 }}</p>
        </div>
      </div>
      <div class="illustration-pure-colors-icon-wrapper">
        <IconCheck />
        <p class="illustration-pure-colors-description-sec">{{ pureColorsDescription4 }}</p>
      </div>
    </div>

    <div
      class="illustration-pure-colors-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCancel from '@/assets/icons/IconCancel.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import IllustrationPageMixin from '@/mixins/illustration-page-mixin'
import { IllustrationPageStore } from '@/store/visual-identity-page/illustration-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCancel, IconCheck } })
export default class IllustrationPureColorsceneSection extends Mixins(IllustrationPageMixin) {
  illustrationPageStore: PageStoreContract = getModule(IllustrationPageStore, this.$store)

  get pureColorsTitleSpan(): string {
    return this.getElementTitle(20, 0)
  }

  get pureColorsTitle(): string {
    return this.getElementText(20, 0)
  }

  get pureColorsDescription1(): string {
    return this.getElementText(20, 1)
  }

  get pureColorsDescription2(): string {
    return this.getElementText(20, 2)
  }

  get pureColorsDescription3(): string {
    return this.getElementText(20, 3)
  }

  get pureColorsDescription4(): string {
    return this.getElementText(20, 4)
  }

  get ImageSrc(): string {
    return this.getElementMedia(20, 5)
  }

  async getIllustrationPage(): Promise<void> {
    await this.illustrationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.illustrationPageStore.isLoading) {
      await this.getIllustrationPage()
    }
  }
}
</script>

<style lang="scss">
.illustration-pure-colors-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.illustration-pure-colors-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.illustration-pure-colors-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.illustration-pure-colors-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-illustration-pure-colors-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.illustration-pure-colors-icon-wrapper {
  display: flex;
  align-items: center;
}

.illustration-pure-colors-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 434px;
}
.illustration-pure-colors-description-sec {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 374px;
}

.illustration-pure-colors-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 600px;

  background-repeat: no-repeat;
  background-position: center;
}

.illustration-description-importante {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  margin: 0;
  padding-left: 7px;
  width: 434px;
}
</style>
