<template>
  <div class="content">
    <IconographyIntroSection />
    <IconographyTypoUseColorsSection />
  </div>
</template>

<script lang="ts">
import IconographyIntroSection from '@/components/VisualIdentityPage/sections/iconography/IconographyIntroSection.vue'
import IconographyTypoUseColorsSection from '@/components/VisualIdentityPage/sections/iconography/IconographyTypoUseColors.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconographyIntroSection,
    IconographyTypoUseColorsSection
  }
})
export default class IconographyView extends Vue {}
</script>

<style lang="scss"></style>
