<template>
  <div class="grid-container-photos">
    <div class="grid-item item1" :style="{ backgroundImage: `url(${doPlimImageSrc})` }"></div>
    <div class="grid-item item2" :style="{ backgroundImage: `url(${ImageSrc2})` }"></div>
    <div class="grid-item item3" :style="{ backgroundImage: `url(${ImageSrc3})` }"></div>
    <div class="grid-item item4" :style="{ backgroundImage: `url(${ImageSrc4})` }"></div>
    <div class="grid-item item5" :style="{ backgroundImage: `url(${ImageSrc5})` }"></div>
    <div class="grid-item item6" :style="{ backgroundImage: `url(${ImageSrc6})` }"></div>
    <div class="grid-item item7" :style="{ backgroundImage: `url(${ImageSrc7})` }"></div>
    <div class="grid-item item8" :style="{ backgroundImage: `url(${aoPlayImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class ColorsGridPhotosSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get doPlimImageSrc(): string {
    return this.getElementMedia(3, 0)
  }

  get ImageSrc2(): string {
    return this.getElementMedia(3, 1)
  }

  get ImageSrc3(): string {
    return this.getElementMedia(3, 2)
  }

  get ImageSrc4(): string {
    return this.getElementMedia(3, 3)
  }

  get ImageSrc5(): string {
    return this.getElementMedia(3, 4)
  }

  get ImageSrc6(): string {
    return this.getElementMedia(3, 5)
  }

  get ImageSrc7(): string {
    return this.getElementMedia(3, 6)
  }

  get aoPlayImageSrc(): string {
    return this.getElementMedia(3, 7)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.grid-container-photos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 600px);
  gap: 2px;

  width: 100%;
  max-width: 1045px;
  padding: 3rem;
}

.grid-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.item1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.item2,
.item3,
.item4,
.item5,
.item6,
.item7 {
  grid-column: auto;
}

.item8 {
  grid-column: 1 / span 2;
  grid-row: 5;
}
</style>
