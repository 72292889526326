import store from '@/store'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import CentralDaMarcaBffService from '@/services/central-da-marca-bff.service'
import type { PageDTO } from '@/data/dto/page.dto'
import type { PageStoreContract } from '@/data/store/page.store.contract'

type BasicState<T> = {
  loading: boolean
  data: T | null
  error: string | null
}

type LocalState = BasicState<PageDTO>

@Module({
  name: 'GridPageStore',
  dynamic: true,
  namespaced: true,
  store
})
export class GridPageStore extends VuexModule implements PageStoreContract {
  bffService = new CentralDaMarcaBffService()

  paginationLimit = 10

  _state: LocalState = {
    loading: false,
    error: null,
    data: null
  }

  get isLoading(): boolean {
    return this._state.loading
  }

  get all(): PageDTO | null {
    return this._state.data
  }

  @Mutation
  updateGridPageState(payload: Partial<LocalState>): void {
    this._state = {
      ...this._state,
      ...payload
    }
  }

  @Action
  async getDataPage(): Promise<void> {
    this.updateGridPageState({
      loading: true,
      error: null
    })

    try {
      const response = await this.bffService.getGridPageByTitle()
      this.updateGridPageState({ data: response })
    } catch (error: any) {
      this.updateGridPageState({
        error: error?.message
      })
    } finally {
      this.updateGridPageState({
        loading: false
      })
    }
  }
}
