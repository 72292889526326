<template>
  <div>
    <img :src="src" :alt="alt" @click="openModal" class="thumbnail" />

    <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img :src="src" :alt="alt" class="modal-image" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PhotographyExpandableImage extends Vue {
  @Prop({ type: String, required: true }) src!: string
  @Prop({ type: String, default: '' }) alt!: string

  private isModalOpen = false

  private openModal(): void {
    this.isModalOpen = true
  }

  private closeModal(): void {
    this.isModalOpen = false
  }
}
</script>

<style scoped>
.thumbnail {
  width: 500px;
  height: 350px;
  border-radius: 10px;

  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.thumbnail:hover {
  transform: scale(1.05);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  padding: 10px;
  box-sizing: border-box;
}

.modal-image {
  width: 30vw;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
</style>
