<template>
  <div class="logo-signature-4-brands-content">
    <div class="title-signature-wrapper">
      <h1 class="logo-signature-4-brands-title-span">
        {{ logoSignature4BrandsVideosTitleSpan }}
      </h1>
      <h1 class="logo-signature-4-brands-title">{{ logoSignature4BrandsVideosTitle }}</h1>
    </div>

    <div
      class="logo-signature-4-brands-card-item"
      :style="{ backgroundImage: `url(${logoSignature4BrandsVideosImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LogoButton from './LogoButton.vue'

@Component({
  components: {
    LogoButton
  }
})
export default class LogoSignature4BrandsVideosSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoSignature4BrandsVideosTitleSpan(): string {
    return this.getElementTitle(20, 0)
  }

  get logoSignature4BrandsVideosTitle(): string {
    return this.getElementText(20, 0)
  }

  get logoSignature4BrandsVideosImageSrc(): string {
    return this.getElementMedia(20, 1)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-signature-4-brands-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.title-signature-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-signature-4-brands-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-signature-4-brands-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.logo-signature-4-brands-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  width: 1045px;
  height: 558px;
  margin-bottom: 2rem;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
