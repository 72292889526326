<template>
  <div class="personality-content">
    <div class="personality-banner-image" :style="{ backgroundImage: `url(${PersonalityBannerImageSrc})` }">
    </div>
    <div class="personality-overlay-image">
      <h1 class="personality-banner-title">
        {{ PersonalityBannerImageTitle }}
      </h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PersonalityPageMixin from '@/mixins/personality-page-mixin'
import { PersonalityPageStore } from '@/store/brand-plataform-page/personality-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class PersonalityBannerSection extends Mixins(
  PersonalityPageMixin
) {
  personalityPageStore: PageStoreContract = getModule(
    PersonalityPageStore,
    this.$store
  )

  get PersonalityBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get PersonalityBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getPersonalityPageStore(): Promise<void> {
    await this.personalityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.personalityPageStore.isLoading) {
      await this.getPersonalityPageStore()
    }
  }
}
</script>

<style lang="scss">
.personality-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  max-height: 28rem;
  width: 100%;
}

.personality-banner-image {
  position: relative;
  width: 100%;
  height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.personality-overlay-image {
  position: absolute;
  bottom: 5.5rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.personality-banner-title {
  font-size: 8rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}
</style>
