<template>
  <div class="typography-globotipo-content">
    <div class="title-typography-globotipo-wrapper">
      <h1 class="typography-globotipo-title">{{ typographyGlobotipoCorporativaTitle }}</h1>
    </div>
    <div class="description-typography-globotipo-wrapper">
      <div>
        <p class="typography-globotipo-description-regular">
          {{ typographyGlobotipoCorporativaDescriptionParagraph1 }}
        </p>

        <div class="typography-icon-wrapper">
          <IconCheck style="width: 29px; padding-right: 7px" />
          <p class="typography-globotipo-description-paragraph">
            {{ typographyGlobotipoCorporativaDescriptionParagraph2 }}
          </p>
        </div>
      </div>

      <div>
        <p class="typography-globotipo-description-bold">
          {{ typographyGlobotipoCorporativaDescriptionParagraph3 }}
        </p>

        <div class="typography-icon-wrapper">
          <IconCheck style="width: 29px; padding-right: 7px" />
          <p class="typography-globotipo-description-paragraph">
            {{ typographyGlobotipoCorporativaDescriptionParagraph4 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class TypographyGlobotipoCorporativaSection extends Mixins(TypographyPageMixin) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyGlobotipoCorporativaTitle(): string {
    return this.getElementText(2, 0)
  }

  get typographyGlobotipoCorporativaDescriptionParagraph1(): string {
    return this.getElementTitle(2, 1)
  }

  get typographyGlobotipoCorporativaDescriptionParagraph2(): string {
    return this.getElementText(2, 1)
  }

  get typographyGlobotipoCorporativaDescriptionParagraph3(): string {
    return this.getElementTitle(2, 2)
  }

  get typographyGlobotipoCorporativaDescriptionParagraph4(): string {
    return this.getElementText(2, 2)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-globotipo-content {
  display: grid;
  max-width: 1045px;
  width: 100%;
  margin-top: 6rem;
}

.title-typography-globotipo-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.description-typography-globotipo-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.typography-globotipo-description-paragraph {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  width: 373px;
}

.typography-globotipo-restricted-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.typography-globotipo-description-regular {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 85px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.01em;
  text-align: left;

  margin: 0;
}

.typography-globotipo-description-bold {
  font-family: GlobotipoCorporativaTextosBold;
  font-size: 85px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.01em;
  text-align: left;

  margin: 0;
}

.typography-icon-wrapper {
  display: flex;
  align-items: center;

  padding-top: 2rem;
}

.typography-globotipo-card-item {
  position: relative;
  bottom: 1rem;
  left: 3rem;
  width: 100%;
  max-width: 1045px;
  height: 766px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
