import { render, staticRenderFns } from "./ColorsButton.vue?vue&type=template&id=26d88e08&scoped=true"
import script from "./ColorsButton.vue?vue&type=script&lang=ts"
export * from "./ColorsButton.vue?vue&type=script&lang=ts"
import style0 from "./ColorsButton.vue?vue&type=style&index=0&id=26d88e08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26d88e08",
  null
  
)

export default component.exports