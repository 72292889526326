<template>
  <div class="graphic-content">
    <div
      class="graphic-banner-image"
      :style="{ backgroundImage: `url(${graphicBannerImageSrc})` }"
    ></div>
    <div class="graphic-overlay-image">
      <h1 class="graphic-banner-title">{{ graphicBannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GraphicPageMixin from '@/mixins/graphic-page-mixin'
import { GraphicPageStore } from '@/store/visual-identity-page/graphic-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class GraphicSection extends Mixins(GraphicPageMixin) {
  graphicPageStore: PageStoreContract = getModule(GraphicPageStore, this.$store)

  get graphicBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get graphicBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getGraphicPage(): Promise<void> {
    await this.graphicPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.graphicPageStore.isLoading) {
      await this.getGraphicPage()
    }
  }
}
</script>

<style lang="scss">
.graphic-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;

  width: 100%;
  max-height: 28rem;
}

.graphic-banner-image {
  position: relative;
  width: 100%;
  height: 451px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.graphic-overlay-image {
  position: absolute;
  bottom: 10rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.graphic-banner-title {
  font-size: 7rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

@media (min-width: 1726px) {
  .graphic-content {
    width: 100%;
  }
}
</style>
