<template>
  <div class="content">
    <IntroSection />
    <LogoIntroSection />
    <LogoMainVersionSection />
    <LogoConstructionSection />
    <LogoPreDefinedSizesSection />
    <LogoInvertedMainVersionSection />
    <LogoBlackAndWhiteSection />
    <LogoAboutImagesSection />
    <LogoOnFabricSection />
    <LogoOnFacadeSection />
    <LogoPositioningSection />
    <LogoGloboTextSection />
    <LogoSocialMediaVersionSection />
    <LogoRestrictedVersionSection />
    <LogoDecisionTreeSection />
    <LogoFullSignatureSection />
    <LogoSignatureAlignedLeftSection />
    <LogoSignatureCentralizedSection />
    <LogoSignature4BrandsSection />
    <LogoSignature3SecondVideosSection />
    <LogoSignature5SecondVideosSection />
    <LogoSignature4BrandsVideosSection />
    <LogoWhatNotToDoSection />
    <LogoWhatNotToDoSection2 />
  </div>
</template>

<script lang="ts">
import IntroSection from '@/components/VisualIdentityPage/sections/IntroSection.vue'
import LogoAboutImagesSection from '@/components/VisualIdentityPage/sections/logo/LogoAboutImagesSection.vue'
import LogoBlackAndWhiteSection from '@/components/VisualIdentityPage/sections/logo/LogoBlackAndWhiteSection.vue'
import LogoConstructionSection from '@/components/VisualIdentityPage/sections/logo/LogoConstructionSection.vue'
import LogoDecisionTreeSection from '@/components/VisualIdentityPage/sections/logo/LogoDecisionTreeSection.vue'
import LogoFullSignatureSection from '@/components/VisualIdentityPage/sections/logo/LogoFullSignatureSection.vue'
import LogoGloboTextSection from '@/components/VisualIdentityPage/sections/logo/LogoGloboTextSection.vue'
import LogoIntroSection from '@/components/VisualIdentityPage/sections/logo/LogoIntroSection.vue'
import LogoInvertedMainVersionSection from '@/components/VisualIdentityPage/sections/logo/LogoInvertedMainVersionSection.vue'
import LogoMainVersionSection from '@/components/VisualIdentityPage/sections/logo/LogoMainVersionSection.vue'
import LogoOnFabricSection from '@/components/VisualIdentityPage/sections/logo/LogoOnFabricSection.vue'
import LogoOnFacadeSection from '@/components/VisualIdentityPage/sections/logo/LogoOnFacadeSection.vue'
import LogoPositioningSection from '@/components/VisualIdentityPage/sections/logo/LogoPositioningSection.vue'
import LogoPreDefinedSizesSection from '@/components/VisualIdentityPage/sections/logo/LogoPreDefinedSizesSection.vue'
import LogoRestrictedVersionSection from '@/components/VisualIdentityPage/sections/logo/LogoRestrictedVersionSection.vue'
import LogoSignature3SecondVideosSection from '@/components/VisualIdentityPage/sections/logo/LogoSignature3SecondVideosSection.vue'
import LogoSignature4BrandsSection from '@/components/VisualIdentityPage/sections/logo/LogoSignature4BrandsSection.vue'
import LogoSignature4BrandsVideosSection from '@/components/VisualIdentityPage/sections/logo/LogoSignature4BrandsVideosSection.vue'
import LogoSignature5SecondVideosSection from '@/components/VisualIdentityPage/sections/logo/LogoSignature5SecondVideosSection.vue'
import LogoSignatureAlignedLeftSection from '@/components/VisualIdentityPage/sections/logo/LogoSignatureAlignedLeftSection.vue'
import LogoSignatureCentralizedSection from '@/components/VisualIdentityPage/sections/logo/LogoSignatureCentralizedSection.vue'
import LogoSocialMediaVersionSection from '@/components/VisualIdentityPage/sections/logo/LogoSocialMediaVersionSection.vue'
import LogoWhatNotToDoSection from '@/components/VisualIdentityPage/sections/logo/LogoWhatNotToDoSection.vue'
import LogoWhatNotToDoSection2 from '@/components/VisualIdentityPage/sections/logo/LogoWhatNotToDoSection2.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IntroSection,
    LogoIntroSection,
    LogoMainVersionSection,
    LogoConstructionSection,
    LogoPreDefinedSizesSection,
    LogoInvertedMainVersionSection,
    LogoBlackAndWhiteSection,
    LogoAboutImagesSection,
    LogoOnFabricSection,
    LogoOnFacadeSection,
    LogoPositioningSection,
    LogoGloboTextSection,
    LogoSocialMediaVersionSection,
    LogoRestrictedVersionSection,
    LogoDecisionTreeSection,
    LogoFullSignatureSection,
    LogoSignatureAlignedLeftSection,
    LogoSignatureCentralizedSection,
    LogoSignature4BrandsSection,
    LogoSignature3SecondVideosSection,
    LogoSignature5SecondVideosSection,
    LogoSignature4BrandsVideosSection,
    LogoWhatNotToDoSection,
    LogoWhatNotToDoSection2
  }
})
export default class LogoView extends Vue {}
</script>

<style lang="scss"></style>
