<template>
  <div class="content">
    <div class="content-container">
      <component :is="currentComponent" :key="$route.name" />
    </div>
    <NavInternal />
  </div>
</template>

<script lang="ts">
import NavInternal from '@/components/VisualIdentityPage/NavInternal.vue'
import BannerImageSection from '@/components/VisualIdentityPage/sections/BannerImageSection.vue'
import ColorsSection from '@/components/VisualIdentityPage/sections/colors/ColorsSection.vue'
import IconographyBannerSection from '@/components/VisualIdentityPage/sections/iconography/IconographyBannerSection.vue'
import PhotographyBannerSection from '@/components/VisualIdentityPage/sections/photography/PhotographyBannerSection.vue'
import GraphicSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsSection.vue'
import GridSection from '@/components/VisualIdentityPage/sections/grid/GridSection.vue'
import IllustrationSection from '@/components/VisualIdentityPage/sections/illustration/IllustrationSection.vue'
import LayoutsSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsSection.vue'
import { Component, Vue } from 'vue-property-decorator'
import TypographyBannerSection from '@/components/VisualIdentityPage/sections/typography/TypographyBannerSection.vue'

@Component({
  components: {
    BannerImageSection,
    NavInternal,
    ColorsSection,
    IconographyBannerSection,
    PhotographyBannerSection,
    GridSection
  }
})
export default class VisualIdentityView extends Vue {
  private routeComponentMap: { [key: string]: any } = {
    'identidade-visual': BannerImageSection,
    'identidade-visual.logo': BannerImageSection,
    'identidade-visual.color': ColorsSection,
    'identidade-visual.typography': TypographyBannerSection,
    'identidade-visual.iconography': IconographyBannerSection,
    'identidade-visual.grafismos': GraphicSection,
    'identidade-visual.ilustracao': IllustrationSection,
    'identidade-visual.photography': PhotographyBannerSection,
    'identidade-visual.grid': GridSection,
    'identidade-visual.layouts': LayoutsSection
  }

  get currentComponent() {
    return this.routeComponentMap[this.$route.name as string] || BannerImageSection
  }
}
</script>

<style lang="scss">
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .content-container{
    width: 100%;
  }
}
</style>
