<template>
  <div class="photography-framing-content">
    <div class="title-photography-framing-wrapper">
      <h1 class="photography-framing-title-span">{{ photographyFramingTitleSpan }}</h1>
      <h1 class="photography-framing-title">{{ photographyFramingTitle }}</h1>
    </div>
    <div class="description-photography-framing-wrapper">
      <div>
        <p class="photography-framing-description">{{ photographyFramingDescription1 }}</p>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-framing-description">{{ photographyFramingDescription2 }}</p>
        </div>
      </div>

      <div>
        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-framing-description">{{ photographyFramingDescription3 }}</p>
        </div>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-framing-description">{{ photographyFramingDescription4 }}</p>
        </div>
      </div>
    </div>

    <div class="expandable-image">
      <PhotographyExpandableImage :src="photographyFramingImageSrc1" alt="" />
      <PhotographyExpandableImage :src="photographyFramingImageSrc2" alt="" />
      <PhotographyExpandableImage :src="photographyFramingImageSrc3" alt="" />
      <PhotographyExpandableImage :src="photographyFramingImageSrc4" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PhotographyPageMixin from '@/mixins/photography-page-mixin'
import { PhotographyPageStore } from '@/store/visual-identity-page/photography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PhotographyExpandableImage from './PhotographyExpandableImage.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'

@Component({
  components: { IconCheck, PhotographyExpandableImage }
})
export default class PhotographyFramingSection extends Mixins(PhotographyPageMixin) {
  photographyPageStore: PageStoreContract = getModule(PhotographyPageStore, this.$store)

  get photographyFramingTitleSpan(): string {
    return this.getElementTitle(5, 0)
  }

  get photographyFramingTitle(): string {
    return this.getElementText(5, 0)
  }

  get photographyFramingDescription1(): string {
    return this.getElementText(5, 1)
  }

  get photographyFramingDescription2(): string {
    return this.getElementText(5, 2)
  }

  get photographyFramingDescription3(): string {
    return this.getElementText(5, 3)
  }

  get photographyFramingDescription4(): string {
    return this.getElementText(5, 4)
  }

  get photographyFramingImageSrc1(): string {
    return this.getElementMedia(5, 5)
  }

  get photographyFramingImageSrc2(): string {
    return this.getElementMedia(5, 6)
  }

  get photographyFramingImageSrc3(): string {
    return this.getElementMedia(5, 7)
  }

  get photographyFramingImageSrc4(): string {
    return this.getElementMedia(5, 8)
  }

  async getPhotographyPage(): Promise<void> {
    await this.photographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.photographyPageStore.isLoading) {
      await this.getPhotographyPage()
    }
  }
}
</script>

<style lang="scss">
.photography-framing-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-photography-framing-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.photography-framing-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.photography-framing-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-photography-framing-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.photography-framing-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.expandable-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 15px;

  width: 30vw;
  padding: 0rem 3rem 3rem 3rem;
}
</style>
