<template>
  <div class="typography-globotipo-corp-text-content">
    <div class="title-typography-globotipo-corp-text-wrapper">
      <h1 class="typography-globotipo-corp-text-title">
        {{ typographyGlobotipoCorporativaTextosTitle }}
      </h1>
    </div>
    <div class="description-typography-globotipo-corp-text-wrapper">
      <div>
        <div class="typography-icon-wrapper">
          <IconCheck />
          <p class="typography-globotipo-corp-text-description" style="width: 250px">
            {{ typographyGlobotipoCorporativaTextosDescription1 }}
          </p>
        </div>
        <p class="typography-globotipo-corp-text-description">
          {{ typographyGlobotipoCorporativaTextosDescription2 }}
        </p>
      </div>

      <div class="typography-globotipo-corp-text-card-item"
        :style="{ backgroundImage: `url(${typographyGlobotipoCorporativaTextosImageSrc})` }"></div>
    </div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class TypographyGlobotipoCorporativaTextosSection extends Mixins(
  TypographyPageMixin
) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyGlobotipoCorporativaTextosTitle(): string {
    return this.getElementTitle(2, 3)
  }

  get typographyGlobotipoCorporativaTextosDescription1(): string {
    return this.getElementText(2, 3)
  }

  get typographyGlobotipoCorporativaTextosDescription2(): string {
    return this.getElementText(2, 4)
  }

  get typographyGlobotipoCorporativaTextosImageSrc(): string {
    return this.getElementMedia(2, 5)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-globotipo-corp-text-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.title-typography-globotipo-corp-text-wrapper {
  display: flex;
  align-items: center;
  width: 440px;
  padding: 0 3rem 1rem 3rem;
}

.typography-globotipo-corp-text-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-typography-globotipo-corp-text-wrapper {
    padding: 3rem 0 0 3rem;
    position: relative;
    bottom: 5rem;
    display: grid;
    grid-template-columns: 33% 67%;
    width: 100%;
}

.typography-globotipo-corp-text-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 312px;
  padding-top: 2rem;
  padding-left: 7px;
}

.typography-globotipo-corp-text-card-item {
  position: relative;
  bottom: 3rem;
  max-width: 798px;
  height: 429px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  width: 95%;
  background-size: contain;
}
</style>
