<template>
  <div class="logo-construction-content">
    <div class="title-construction-wrapper">
      <h1 class="logo-construction-title-span">{{ logoConstructionTitleSpan }}</h1>
      <h1 class="logo-construction-title">{{ logoConstructionTitle }}</h1>
    </div>
    <div class="description-construction-wrapper">
      <p class="logo-construction-description">{{ logoConstructionDescription }}</p>
      <p class="logo-construction-description">{{ logoConstructionDescription1 }}</p>
    </div>
    <p class="title-image">Construção</p>
    <div class="logo-construction-card-item" :style="{ backgroundImage: `url(${logoConstructionImageSrc})` }"></div>
    <div class="logo-construction-card-wrapper">
      <div>
        <p class="title-image-bottom">Área de proteção</p>
        <div class="logo-construction-card-item-bottom"
          :style="{ backgroundImage: `url(${logoConstructionImageSrcBottom})` }"></div>
      </div>
      <div>
        <p class="title-image-bottom">Tamanho mínimo</p>
        <div class="logo-construction-card-item-bottom"
          :style="{ backgroundImage: `url(${logoConstructionImageSrcBottom2})` }"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LogoConstructionSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoConstructionTitleSpan(): string {
    return this.getElementTitle(4, 0)
  }

  get logoConstructionTitle(): string {
    return this.getElementText(4, 0)
  }

  get logoConstructionDescription(): string {
    return this.getElementText(4, 1)
  }

  get logoConstructionDescription1(): string {
    return this.getElementText(4, 2)
  }

  get logoConstructionImageSrc(): string {
    return this.getElementMedia(4, 3)
  }

  get logoConstructionImageSrcBottom(): string {
    return this.getElementMedia(4, 4)
  }

  get logoConstructionImageSrcBottom2(): string {
    return this.getElementMedia(4, 5)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-construction-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.title-construction-wrapper {
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-bottom: 3rem;
}

.logo-construction-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-construction-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-construction-wrapper {
  position: relative;
  bottom: 3rem;
  display: grid;
  grid-template-columns: 39% 43%;
  gap: 0rem;
  justify-content: space-around;
  max-width: 1045px;
}

.logo-construction-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-top: 1rem;
  max-width: 487px;
}

.title-image {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: left;

  padding-left: 3rem;
  width: 187px;
}

.logo-construction-card-item {
  position: relative;
  bottom: 0;
  max-width: 982px;
  width: 100%;
  height: 368px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 3rem;
}

.logo-construction-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  max-width: 1045px;
  margin: 1rem 3rem 5rem 3rem;
}

.title-image-bottom {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  position: relative;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  background: #fff;
  width: 200px;
}

.logo-construction-card-item-bottom {
  position: relative;

  width: 462px;
  height: 296px;

  border-radius: 31px;
  border-style: solid;
  border-width: 1px;
  border-color: #000000;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: center;

}
</style>
