<template>
  <div class="intro-differentiating-benefits-intro-content">
    <div class="description-differentiating-benefits-intro-content">
      <p class="intro-differentiating-benefits-intro-description">{{ introDescription }}</p>
    </div>

    <div
      class="intro-differentiating-benefits-intro-card-item"
      :style="{ backgroundImage: `url(${introImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import DifferentiatingBenefitsPageMixin from '@/mixins/differentiating-benefits-page-mixin'
import { DifferentiatingBenefitsPageStore } from '@/store/brand-plataform-page/differentiating-benefits-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class DifferentiatingBenefitsIntroSection extends Mixins(
  DifferentiatingBenefitsPageMixin
) {
  differentiatingBenefitsPageStore: PageStoreContract = getModule(
    DifferentiatingBenefitsPageStore,
    this.$store
  )

  get introDescription(): string {
    return this.getElementText(1, 0)
  }

  get introImageSrc(): string {
    return this.getElementMedia(1, 0)
  }

  async getDifferentiatingBenefitsPage(): Promise<void> {
    await this.differentiatingBenefitsPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.differentiatingBenefitsPageStore.isLoading) {
      await this.getDifferentiatingBenefitsPage()
    }
  }
}
</script>

<style lang="scss">
.intro-differentiating-benefits-intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 44% 56%;
  width: 100%;
  max-width: 949px;
  justify-items: center;
  justify-content: space-around;
}

.description-differentiating-benefits-intro-content {
  max-width: 495px;
  width: 100%;
  height: 558px;
  line-height: 20px;
  text-align: left;
}

.intro-differentiating-benefits-intro-description {
  font-size: 56px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
  padding-right: 20px;
}

.intro-differentiating-benefits-intro-card-item {
  position: relative;
  max-width: 543px;
  width: 100%;
  height: 622px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
