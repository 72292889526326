<template>
  <div class="content">
    <GraphicsIntroSection />
    <GraphicsSymbolSection />
    <GraphicsSymbolTwoSection />
    <GraphicsLogoSection />
    <GraphicsLogoTwoSection />
    <GraphicsTypographicSection />
    <GraphicsTypographicTwoSection />
    <GraphicsTypographicThreeSection />
    <GraphicsIconographicSection />
    <GraphicsIconographicTwoSection />
    <GraphicsPhotosSection />
  </div>
</template>

<script lang="ts">
import GraphicsIntroSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsIntroSection.vue'
import GraphicsSymbolSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsSymbolSection.vue'
import GraphicsSymbolTwoSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsSymbolTwoSection.vue'
import GraphicsLogoSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsLogoSection.vue'
import GraphicsLogoTwoSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsLogoTwoSection.vue'
import GraphicsTypographicSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsTypographicSection.vue'
import GraphicsTypographicTwoSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsTypographicTwoSection.vue'
import GraphicsTypographicThreeSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsTypographicThreeSection.vue'
import GraphicsIconographicSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsIconographicSection.vue'
import GraphicsIconographicTwoSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsIconographicTwoSection.vue'
import GraphicsPhotosSection from '@/components/VisualIdentityPage/sections/graphics/GraphicsPhotosSection.vue'
import { Component, Vue } from 'vue-property-decorator'


@Component({
  components: {    
    GraphicsIntroSection,
    GraphicsSymbolSection,
    GraphicsSymbolTwoSection,
    GraphicsLogoSection,
    GraphicsLogoTwoSection,
    GraphicsTypographicSection,
    GraphicsTypographicTwoSection,
    GraphicsTypographicThreeSection,
    GraphicsIconographicSection,
    GraphicsIconographicTwoSection,
    GraphicsPhotosSection
   }
})
export default class GraphicView extends Vue {}
</script>

<style lang="scss"></style>
