<template>
  <div class="content-container">
    <PersonalityIntroSection />
    <PersonalitySociallyFluentSection />
    <PersonalityInspiringNewIdeasSection />
    <PersonalityIPassionForEverythingSection />
    <PersonalityLoyalToValuesSection />
    <PersonalityMagnetictSection />
    <PersonalityGraphicSection />
  </div>
</template>

<script lang="ts">
import PersonalityIntroSection from '@/components/BrandPlataformPage/sections/personality/PersonalityIntroSection.vue'
import PersonalitySociallyFluentSection from '@/components/BrandPlataformPage/sections/personality/PersonalitySociallyFluentSection.vue'
import PersonalityInspiringNewIdeasSection from '@/components/BrandPlataformPage/sections/personality/PersonalityInspiringNewIdeasSection.vue'
import PersonalityIPassionForEverythingSection from '@/components/BrandPlataformPage/sections/personality/PersonalityIPassionForEverythingSection.vue'
import PersonalityLoyalToValuesSection from '@/components/BrandPlataformPage/sections/personality/PersonalityILoyalToValuesSection.vue'
import PersonalityMagnetictSection from '@/components/BrandPlataformPage/sections/personality/PersonalityMagneticSection.vue'
import PersonalityGraphicSection from '@/components/BrandPlataformPage/sections/personality/PersonalityGraphicSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    PersonalityIntroSection,
    PersonalityInspiringNewIdeasSection,
    PersonalitySociallyFluentSection,
    PersonalityIPassionForEverythingSection,
    PersonalityLoyalToValuesSection,
    PersonalityMagnetictSection,
    PersonalityGraphicSection
  }
})
export default class PersonalityView extends Vue { }
</script>

<style lang="scss">
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>