<template>
  <div class="vocation-banner-content">
    <div
      class="vocation-banner-image"
      :style="{ backgroundImage: `url(${vocationPageStoreBannerImageSrc})` }"
    ></div>
    <div class="vocation-overlay-image">
      <h1 class="vocation-banner-title">
        {{ vocationPageStoreBannerImageTitle }}
      </h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VocationPageMixin from '@/mixins/vocation-page-mixin'
import { VocationPageStore } from '@/store/brand-plataform-page/vocation-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
 
@Component({})
export default class VocationBannerSection extends Mixins(VocationPageMixin) {
  vocationPageStore: PageStoreContract = getModule( VocationPageStore, this.$store)

  get vocationPageStoreBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get vocationPageStoreBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getVocationPageStore(): Promise<void> {
    await this.vocationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.vocationPageStore.isLoading) {
      await this.getVocationPageStore()
    }
  }
}
</script>

<style lang="scss">
.vocation-banner-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  max-height: 28rem;
  width: 100%;
  justify-items: center;
  justify-content: center;
}

.vocation-banner-image {
  position: relative;
  width: 100%;
  height: 451px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.vocation-overlay-image {
  position: absolute;
  bottom: 5.5rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.vocation-banner-title {
  font-size: 8rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}
</style>
