<template>
  <div class="photography-intro-content">
    <h1 class="photography-intro-title">{{ photographyIntroTitle }}</h1>
    <div class="description-content-photo">
      <p class="photography-intro-description-paragraph">
        {{ photographyIntroDescriptionParagraph1 }}
      </p>
      <p class="photography-intro-description-paragraph">
        {{ photographyIntroDescriptionParagraph2 }}
      </p>
      <p class="photography-intro-description-paragraph">
        {{ photographyIntroDescriptionParagraph3 }}
      </p>

      <div class="button-photography-intro-wrapper">
        <ButtonPhotography
          :imageSrc="photographyIntroSrcZip"
          buttonText="Acesse aqui nosso banco de arquivos"
          downloadName="fotografia.zip"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PhotographyPageMixin from '@/mixins/photography-page-mixin'
import { PhotographyPageStore } from '@/store/visual-identity-page/photography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import ButtonPhotography from './ButtonPhotography.vue'

@Component({
  components: {
    ButtonPhotography
  }
})
export default class PhotographyIntroSection extends Mixins(PhotographyPageMixin) {
  photographyPageStore: PageStoreContract = getModule(PhotographyPageStore, this.$store)

  get photographyIntroTitle(): string {
    return this.getElementTitle(1, 0)
  }

  get photographyIntroDescriptionParagraph1(): string {
    return this.getElementText(1, 1)
  }

  get photographyIntroDescriptionParagraph2(): string {
    return this.getElementText(1, 2)
  }

  get photographyIntroDescriptionParagraph3(): string {
    return this.getElementText(1, 3)
  }

  get photographyIntroSrcZip(): string {
    return this.getElementMedia(1, 4)
  }

  async getPhotographyPage(): Promise<void> {
    await this.photographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.photographyPageStore.isLoading) {
      await this.getPhotographyPage()
    }
  }
}
</script>

<style lang="scss">
.photography-intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 44% 52%;
  padding-left: 3rem;
  width: 95%;
  max-width: 1045px;
}

.photography-intro-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin: 0;
}

.description-content-photo {
  max-width: 506px;
  width: 100%;
  margin-left: 1rem;
}

.photography-intro-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.photography-intro-description-paragraph {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.photography-intro-card-item {
  position: relative;
  bottom: 4rem;

  width: 564px;
  height: 388px;

  background-repeat: no-repeat;
  background-position: center;

  margin-bottom: 3rem;
}

.button-photography-intro-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
</style>
