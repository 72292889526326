import { HttpClient } from '@/core/http-client'
import { CredentialsDTO } from '@/data/dto/credentials.dto'
import type { PageDTO } from '@/data/dto/page.dto'
import { SessionDTO } from '@/data/dto/session.dto'

export interface ICentralDaMarcaBffService {
  login(credentials: CredentialsDTO): Promise<SessionDTO>
  getHomePageByTitle(): Promise<PageDTO>
  getGloboPageByTitle(): Promise<PageDTO>
  getVisualIdentityPageByTitle(): Promise<PageDTO>
  getBrandPlataformPageByTitle(): Promise<PageDTO>
  getPersonalityPageByTitle(): Promise<PageDTO>
}

export default class CentralDaMarcaBffService
  extends HttpClient
  implements ICentralDaMarcaBffService
{
  constructor() {
    super(process.env.VUE_APP_CENTRALDEMARCA_BACK_HOST!)
  }

  // session
  login = (credentials: CredentialsDTO): Promise<SessionDTO> =>
    this.client.post('/login/', {
      code: credentials.code,
      redirect_uri: credentials.redirect_uri,
    })

  // HomePage
  getHomePageByTitle = async () => await this.client.get<PageDTO>('/page/home/')
  // GloboPage
  getGloboPageByTitle = async () => await this.client.get('/page/a-globo/')

  // IdentidadeVisualLogoPage
  getVisualIdentityPageByTitle = async () => await this.client.get('/page/identidade-visual-logo/')
  // ColorPage
  getColorPageByTitle = async () => await this.client.get('/page/identidade-visual-cores/')
  //TypographyPage
  getTypographyPageByTitle = async () => await this.client.get('/page/identidade-visual-tipografia')
  // GraphicPage
  getGraphicPageByTitle = async () => await this.client.get('/page/identidade-visual-grafismos/')
  // IconographyPage
  getIconographyPageByTitle = async () =>
    await this.client.get('/page/identidade-visual-iconografia/')
  // PhotographyPage
  getPhotographyPageByTitle = async () =>
    await this.client.get('/page/identidade-visual-fotografia/')
  // GridPage
  getGridPageByTitle = async () => await this.client.get('/page/identidade-visual-grid/')
  // IllustrationPage
  getIllustrationPageByTitle = async () =>
    await this.client.get('/page/identidade-visual-ilustracao/')
  // LayoutPage
  getLayoutPageByTitle = async () => await this.client.get('/page/identidade-visual-layouts/')

  // BrandPlataformPage
  getBrandPlataformPageByTitle = async () => await this.client.get('/page/plataforma-de-marca/')
  // DifferentiatingBenefitsPage
  getDifferentiatingBenefitsPageByTitle = async () =>
    await this.client.get('/page/plataforma-de-marca-beneficios-diferenciadores/')
  // OurBeliefsPage
  getOurBeliefsPageByTitle = async () =>
    await this.client.get('/page/plataforma-de-marca-nossas-crencas/')
  //PersonalityPage
  getPersonalityPageByTitle = async () =>
    await this.client.get('/page/plataforma-de-marca-personalidade/')
  //PublicPage
  getPublicPageByTitle = async () => await this.client.get('/page/plataforma-de-marca-publico')
  //VocationPage
  getVocationPageByTitle = async () => await this.client.get('/page/plataforma-de-marca-vocacao')
}
