<template>
  <div class="content-brand">
    <h2>Aplicando a Marca Globo</h2>
    <div class="cards">
      <CardBrand
        class="card-item-position-1"
        :style="{ backgroundImage: `url(${brandImageSrcPosition1})` }"
      >
        <div class="text-position-1">
          <h2>{{ brandTitlePosition1 }}</h2>
          <p>{{ brandDescriptionPosition1 }}</p>
          <img class="icon" src="../../../assets/icons/brand-button.svg" />
        </div>
      </CardBrand>
      <CardBrand
        class="card-item-position-2"
        :style="{ backgroundImage: `url(${brandImageSrcPosition2})` }"
      >
        <div class="text-position-2">
          <h2>{{ brandTitlePosition2 }}</h2>
          <p>{{ brandDescriptionPosition2 }}</p>
          <img class="icon" src="../../../assets/icons/brand-button.svg" />
        </div>
      </CardBrand>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import CardBrand from '../CardBrand.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import { getModule } from 'vuex-module-decorators'
import { HomePageStore } from '@/store/home-page/home-store'
import HomePageMixin from '@/mixins/home-page-mixin'

@Component({
  components: {
    CardBrand
  }
})
export default class BrandSection extends Mixins(HomePageMixin) {
  homePageStore: PageStoreContract = getModule(HomePageStore, this.$store)

  get brandTitlePosition1(): string {
    return this.getElementTitle(1, 0)
  }

  get brandDescriptionPosition1(): string {
    return this.getElementText(1, 0)
  }

  get brandImageSrcPosition1(): string {
    return this.getElementMedia(1, 0)
  }

  get brandTitlePosition2(): string {
    return this.getElementTitle(1, 1)
  }

  get brandDescriptionPosition2(): string {
    return this.getElementText(1, 1)
  }

  get brandImageSrcPosition2(): string {
    return this.getElementMedia(1, 1)
  }

  async getHomePage(): Promise<void> {
    await this.homePageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.homePageStore.isLoading) {
      await this.getHomePage()
    }
  }
}
</script>

<style scoped lang="scss">
.content-brand {
  margin-top: 2rem;
  padding: 5rem 0px 3rem 3rem;
  max-width: 1045px;
  width: 95%;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1045px;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 5rem;
}
.card-item-position-1{
  margin-right: 1rem;
}
.card-item-position-1,
.card-item-position-2 {
  position: relative;
  max-width: 527px;
  width: 95%;
  height: 300px;
  background: rgb(79, 79, 77);
  border-radius: 9.9px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card-item-position-1 h2,
.card-item-position-2 h2 {
  color: #fff;
  margin-bottom: 0;
  font-size: 2.5rem;
  font-weight: 100;
}

.card-item-position-1 p,
.card-item-position-2 p {
  color: #fff;
}

.text-position-1,
.text-position-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
}

@media (min-width: 1726px) {
  .content-brand {
    margin-top: 12rem;
  }
  .icon {
    left: 30rem;
  }
}

@media (min-width: 1349px) {
  .icon {
    left: 24rem;
  }
}
@media (min-width: 1440px) {
  .icon {
    left: 27rem;
  }
}
</style>
