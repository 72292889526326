<template>
  <div class="layout-intro-content">
    <h1 class="layout-intro-title">{{ layoutIntroTitle }}</h1>
    <div class="description-content">
      <p class="layout-intro-description">{{ layoutIntroDescription }}</p>
      <p class="layout-intro-description">{{ layoutIntroDescription2 }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LayoutsIntroSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutIntroTitle(): string {
    return this.getElementText(1, 0)
  }

  get layoutIntroDescription(): string {
    return this.getElementText(1, 1)
  }

  get layoutIntroDescription2(): string {
    return this.getElementText(1, 2)
  }

  async getColorPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.layout-intro-content {
  display: grid;
  grid-template-columns: 44% 52%;
  margin-top: 6rem;

  width: 90%;
  max-width: 1045px;
}

.layout-intro-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.layout-intro-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin: 0;
}
</style>
