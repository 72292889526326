<template>
  <div class="content">
    <LayoutsIntroSection />
    <LayoutsOurAssetsSection />
    <LayoutsCombinigAssetsSection />
    <LayoutsCombinigAssetsPhotosSection />
    <LayoutsCombinigAssetsPhotosGraphicSection />
    <LayoutsCombinigAssetsCroppedPhotosSection />
    <LayoutsCombinigAssetsIllustrationSection />
    <LayoutsUseOfBarSection />
    <LayoutsUseOfBarTwoSection />
    <LayoutsUseOfBarThreeSection />
    <LayoutsUseOfBarFourSection />
    <LayoutsMoodColorsSection />
    <LayoutsMoodGlobeSection />
    <LayoutsMoodBlueSection />
    <LayoutsMoodRedSection />
    <LayoutsMoodOrangeSection />
    <LayoutsWhatNotToDoSection />
    <LayoutsButtonsExemploSection />
    <LayoutsMosaicSection />
    <LayoutsPhotosSection />
  </div>
</template>

<script lang="ts">
import LayoutsIntroSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsIntroSection.vue'
import LayoutsOurAssetsSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsOurAssetsSection.vue'
import LayoutsCombinigAssetsSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsCombinigAssetsSection.vue'
import LayoutsCombinigAssetsPhotosSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsCombinigAssetsPhotosSection.vue'
import LayoutsCombinigAssetsPhotosGraphicSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsCombinigAssetsPhotosGraphicSection.vue'
import LayoutsCombinigAssetsCroppedPhotosSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsCombinigAssetsCroppedPhotosSection.vue'
import LayoutsCombinigAssetsIllustrationSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsCombinigAssetsIllustrationSection.vue'
import LayoutsUseOfBarSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsUseOfBarSection.vue'
import LayoutsUseOfBarTwoSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsUseOfBarTwoSection.vue'
import LayoutsUseOfBarThreeSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsUseOfBarThreeSection.vue'
import LayoutsUseOfBarFourSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsUseOfBarFourSection.vue'
import LayoutsMoodColorsSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsMoodColorsSection.vue'
import LayoutsMoodGlobeSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsMoodGlobeSection.vue'
import LayoutsMoodBlueSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsMoodBlueSection.vue'
import LayoutsMoodRedSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsMoodRedSection.vue'
import LayoutsMoodOrangeSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsMoodOrangeSection.vue'
import LayoutsWhatNotToDoSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsWhatNotToDoSection.vue'
import LayoutsButtonsExemploSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsButtonsExemploSection.vue'
import LayoutsMosaicSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsMosaicSection.vue'
import LayoutsPhotosSection from '@/components/VisualIdentityPage/sections/layouts/LayoutsPhotosSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    LayoutsIntroSection,
    LayoutsOurAssetsSection,
    LayoutsCombinigAssetsSection,
    LayoutsCombinigAssetsPhotosSection,
    LayoutsCombinigAssetsPhotosGraphicSection,
    LayoutsCombinigAssetsCroppedPhotosSection,
    LayoutsCombinigAssetsIllustrationSection,
    LayoutsUseOfBarSection,
    LayoutsUseOfBarTwoSection,
    LayoutsUseOfBarThreeSection,
    LayoutsUseOfBarFourSection,
    LayoutsMoodColorsSection,
    LayoutsMoodGlobeSection,
    LayoutsMoodBlueSection,
    LayoutsMoodRedSection,
    LayoutsMoodOrangeSection,
    LayoutsWhatNotToDoSection,
    LayoutsButtonsExemploSection,
    LayoutsMosaicSection,
    LayoutsPhotosSection
  }
})
export default class LayoutView extends Vue {}
</script>

<style lang="scss"></style>
