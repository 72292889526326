<template>
  <div class="our-beliefs-intro-content">
    <div class="description-our-beliefs-intro-content">
      <p class="our-beliefs-intro-description">{{ introDescription }}</p>
    </div>

    <div class="our-beliefs-intro-card-item" :style="{ backgroundImage: `url(${introImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import OurBeliefsPageMixin from '@/mixins/our-beliefs-page-mixin'
import { OurBeliefsPageStore } from '@/store/brand-plataform-page/our-beliefs-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class OurBeliefsIntroSection extends Mixins(OurBeliefsPageMixin) {
  ourBeliefsPageStore: PageStoreContract = getModule(OurBeliefsPageStore, this.$store)

  get introDescription(): string {
    return this.getElementText(1, 0)
  }

  get introImageSrc(): string {
    return this.getElementMedia(1, 0)
  }

  async getOurBeliefsPage(): Promise<void> {
    await this.ourBeliefsPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.ourBeliefsPageStore.isLoading) {
      await this.getOurBeliefsPage()
    }
  }
}
</script>

<style lang="scss">
.our-beliefs-intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 45% 50%;
  max-width: 949px;
  width: 100%;
  grid-column-gap: 2rem;
  justify-content: space-between;
  justify-items: stretch;
}

.description-our-beliefs-intro-content {
  max-width: 495px;
  width: 100%;
  height: 558px;

  line-height: 20px;
  text-align: left;
}

.our-beliefs-intro-description {
  font-size: 56px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
}

.our-beliefs-intro-card-item {
  position: relative;
  max-width: 543px;
  width: 100%;
  height: 622px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
