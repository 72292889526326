<template>
  <div class="content-logo">
    <div class="logo-banner-image-content">
      <div
        class="logo-banner-image"
        :style="{ backgroundImage: `url(${logoBannerImageSrc})` }"
      ></div>
      <div class="logo-overlay-image">
        <h1 class="logo-banner-title">{{ logoBannerImageTitle }}</h1>
      </div>
    </div>
    <div class="logo-content">
      <h1 class="logo-title">{{ logoTitle }}</h1>
      <div>
        <p class="logo-description">{{ logoDescription }}</p>
        <p class="logo-description-paragraph">{{ logoDescriptionParagraph1 }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LogoIntroSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoBannerImageTitle(): string {
    return this.getElementTitle(2, 0)
  }

  get logoBannerImageSrc(): string {
    return this.getElementMedia(2, 0)
  }

  get logoTitle(): string {
    return this.getElementTitle(2, 1)
  }

  get logoDescription(): string {
    return this.getElementText(2, 2)
  }

  get logoDescriptionParagraph1(): string {
    return this.getElementText(2, 3)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.content-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo-banner-image-content {
  position: relative;
  background-color: gray;
  width: 100%;
  max-width: 1045px;
  max-height: 28rem;
}

.logo-banner-image {
  position: relative;
  width: 100%;
  max-width: 1045px;
  height: 308px;

  background-size: cover;

  background-repeat: no-repeat;
  background-position: center;
}

.logo-overlay-image {
  position: absolute;
  bottom: 6rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.logo-banner-title {
  font-size: 8rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

.logo-content {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 1045px;
}

.logo-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  padding: 3rem;
}

.logo-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding: 5rem 3rem 1rem 3rem;
}

.logo-description-paragraph {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding: 0rem 3rem 1rem 3rem;
}

@media (min-width: 1726px) {
  .logo-banner-image-content {
    max-width: 1045px;
    width: 100%;
  }
}
</style>
