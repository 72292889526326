<template>
  <div class="vocation-intro-content">
    <div class="description-vocation-intro-content">
      <p class="vocation-intro-description">{{ introDescription }}</p>
    </div>

    <div class="vocation-intro-card-item" :style="{ backgroundImage: `url(${introImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VocationPageMixin from '@/mixins/vocation-page-mixin'
import { VocationPageStore } from '@/store/brand-plataform-page/vocation-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
 
@Component({})
export default class VocationIntroSection extends Mixins(VocationPageMixin) {
  vocationPageStore: PageStoreContract = getModule( VocationPageStore, this.$store)
  get introDescription(): string {
    return this.getElementText(1, 0)
  }

  get introImageSrc(): string {
    return this.getElementMedia(1, 1)
  }

  async getVocationPage(): Promise<void> {
    await this.vocationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.vocationPageStore.isLoading) {
      await this.getVocationPage()
    }
  }
}
</script>

<style lang="scss">
.vocation-intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 52% 45%;
  max-width: 949px;
  width: 100%;
  max-height: 750px;
  grid-column-gap: 1rem;
  justify-content: space-between;
  justify-items: stretch;
}

.description-vocation-intro-content {
  max-width: 495px;
  width: 100%;
  height: 558px;

  line-height: 20px;
  text-align: left;
}

.vocation-intro-description {
  font-size: 54px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  margin: 0;
}

.vocation-intro-card-item {
  position: relative;
  max-width: 543px;
  width: 100%;
  height: 622px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
</style>
