<template>
  <div class="layouts-photos-content">
    <div
      class="layouts-photos-card-item"
      :style="{ backgroundImage: `url(${ImageSrc1})`, bottom: '2rem' }"
    ></div>
    <div
      class="layouts-photos-card-item"
      :style="{ backgroundImage: `url(${ImageSrc2})`, bottom: '2rem' }"
    ></div>
    <div
      class="layouts-photos-card-item"
      :style="{ backgroundImage: `url(${ImageSrc3})`, bottom: '2rem' }"
    ></div>
    <div
      class="layouts-photos-card-item"
      :style="{ backgroundImage: `url(${ImageSrc4})`, bottom: '2rem' }"
    ></div>
    <div
      class="layouts-photos-card-item"
      :style="{ backgroundImage: `url(${ImageSrc5})`, bottom: '2rem' }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LayoutsPhotosSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get ImageSrc1(): string {
    return this.getElementMedia(20, 0)
  }

  get ImageSrc2(): string {
    return this.getElementMedia(20, 1)
  }

  get ImageSrc3(): string {
    return this.getElementMedia(20, 2)
  }

  get ImageSrc4(): string {
    return this.getElementMedia(20, 3)
  }

  get ImageSrc5(): string {
    return this.getElementMedia(20, 4)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layouts-photos-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.layouts-photos-card-item {
  position: relative;
  bottom: 0;

  max-width: 1045px;
  width: 100%;
  height: 520px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
