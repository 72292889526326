<template>
  <div class="content">
    <TypographyIntroSection />
    <TypographyIntroTwoSection />
    <TypographyGlobotipoCorporativaSection />
    <TypographyGlobotipoCorporativaTextosSection />
    <TypographyTypographicScaleSection />
    <TypographyTypographicScaleTwoSection />
    <TypographyHierarchySection />
    <TypographyAlignmentSection />
    <TypographyDisplaySection />
    <TypographyHighlightSection />
    <TypographyShadesAndOpacitySection />
    <TypographyWhatNotToDoSection />
  </div>
</template>

<script lang="ts">
import TypographyAlignmentSection from '@/components/VisualIdentityPage/sections/typography/TypographyAlignmentSection.vue'
import TypographyDisplaySection from '@/components/VisualIdentityPage/sections/typography/TypographyDisplaySection.vue'
import TypographyGlobotipoCorporativaSection from '@/components/VisualIdentityPage/sections/typography/TypographyGlobotipoCorporativaSection.vue'
import TypographyGlobotipoCorporativaTextosSection from '@/components/VisualIdentityPage/sections/typography/TypographyGlobotipoCorporativaTextosSection.vue'
import TypographyHierarchySection from '@/components/VisualIdentityPage/sections/typography/TypographyHierarchySection.vue'
import TypographyHighlightSection from '@/components/VisualIdentityPage/sections/typography/TypographyHighlightSection.vue'
import TypographyIntroSection from '@/components/VisualIdentityPage/sections/typography/TypographyIntroSection.vue'
import TypographyIntroTwoSection from '@/components/VisualIdentityPage/sections/typography/TypographyIntroTwoSection.vue'
import TypographyShadesAndOpacitySection from '@/components/VisualIdentityPage/sections/typography/TypographyShadesAndOpacitySection.vue'
import TypographyTypographicScaleSection from '@/components/VisualIdentityPage/sections/typography/TypographyTypographicScaleSection.vue'
import TypographyTypographicScaleTwoSection from '@/components/VisualIdentityPage/sections/typography/TypographyTypographicScaleTwoSection.vue'
import TypographyWhatNotToDoSection from '@/components/VisualIdentityPage/sections/typography/TypographyWhatNotToDoSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TypographyIntroSection,
    TypographyIntroTwoSection,
    TypographyGlobotipoCorporativaSection,
    TypographyGlobotipoCorporativaTextosSection,
    TypographyTypographicScaleSection,
    TypographyTypographicScaleTwoSection,
    TypographyHierarchySection,
    TypographyAlignmentSection,
    TypographyDisplaySection,
    TypographyHighlightSection,
    TypographyShadesAndOpacitySection,
    TypographyWhatNotToDoSection
  }
})
export default class TypographyView extends Vue {}
</script>

<style lang="scss"></style>
