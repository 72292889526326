<template>
  <div class="intro-content-wrapper">
    <div class="intro-content">
      <h1 class="intro-title">{{ introTitle }}</h1>
      <div class="description-content">
        <p class="intro-description">{{ introDescription }}</p>
      </div>
    </div>
    <div class="card-item-content">
      <div class="card-item-color" :style="{ backgroundImage: `url(${logosImageSrc})` }"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class ColorsConceptSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get introTitle(): string {
    return this.getElementTitle(2, 0)
  }

  get introDescription(): string {
    return this.getElementText(2, 0)
  }

  get logosImageSrc(): string {
    return this.getElementMedia(2, 1)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.intro-content-wrapper {
  justify-items: center;
  width: 100%;
  max-width: 1045px;
}

.intro-content {
  display: grid;
  grid-template-columns: 44% 52%;
  margin-top: 6rem;
  width: 100%;
  max-width: 1045px;
}

.card-item-content {
  width: 95%;
  max-width: 1045px;
  padding: 3rem;
}

.card-item-color {
  position: relative;
  max-width: 1045px;
  width: 98%;
  height: 67px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}
</style>
