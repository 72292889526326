<template>
  <div class="intro-content">
    <h1 class="intro-title">{{ introTitle }}</h1>
    <div class="description-content">
      <p class="intro-description">{{ introDescription }}</p>
      <p class="intro-description-paragraph">{{ introDescriptionParagraph1 }}</p>
      <p class="intro-description-paragraph">{{ introDescriptionParagraph2 }}</p>
      <p class="intro-description-paragraph">{{ introDescriptionParagraph3 }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class ColorsDoItBeautifulSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get introTitle(): string {
    return this.getElementTitle(1, 0)
  }

  get introDescription(): string {
    return this.getElementText(1, 0)
  }
  get introDescriptionParagraph1(): string {
    return this.getElementText(1, 1)
  }
  get introDescriptionParagraph2(): string {
    return this.getElementText(1, 2)
  }
  get introDescriptionParagraph3(): string {
    return this.getElementText(1, 3)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 44% 52%;
  max-width: 1045px;
}
.intro-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  padding: 0 3rem 3rem 3rem;

  margin: 0;
}

.description-content {
  width: 506px;
  margin-left: 6rem;
}

.intro-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.intro-description-paragraph {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
</style>
