<template>
  <div class="intro-illustration-content">
    <div
      class="intro-illustration-card-item"
      :style="{ backgroundImage: `url(${introImageSrc})` }"
    ></div>

    <div class="description-illustration-content">
      <p class="intro-illustration-description">{{ introDescription }}</p>
    </div>
    <div class="button-illustration-wrapper">
      <IllustrationButton
        :imageSrc="illustrationImageSrc"
        buttonText="Acesse aqui nosso banco de arquivos"
        downloadName="05_Ilustracoes.zip"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import IllustrationPageMixin from '@/mixins/illustration-page-mixin'
import { IllustrationPageStore } from '@/store/visual-identity-page/illustration-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import IllustrationButton from './IllustrationButton.vue'

@Component({
  components: {
    IllustrationButton
  }
})
export default class IllustrationIntroSection extends Mixins(IllustrationPageMixin) {
  illustrationPageStore: PageStoreContract = getModule(IllustrationPageStore, this.$store)

  get introDescription(): string {
    return this.getElementText(1, 0)
  }

  get introImageSrc(): string {
    return this.getElementMedia(1, 0)
  }

  get illustrationImageSrc(): string {
    return this.getElementMedia(1, 2)
  }

  async getIllustrationPage(): Promise<void> {
    await this.illustrationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.illustrationPageStore.isLoading) {
      await this.getIllustrationPage()
    }
  }
}
</script>

<style lang="scss">
.intro-illustration-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 44% 52%;

  width: 100%;
  max-width: 1045px;
}

.intro-illustration-title {
  font-size: 65px;
  font-weight: 600;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 0 3rem 3rem 3rem;
  margin: 0;
}

.description-illustration-content {
  width: 434px;
  margin-left: 9rem;
  padding-top: 3rem;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.intro-illustration-card-item {
  position: relative;

  width: 538px;
  height: 580px;

  background-repeat: no-repeat;
  background-position: center;
  margin-left: 3rem;
}

.button-illustration-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5rem;
  padding-top: 2rem;
}
</style>
