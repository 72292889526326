<template>
  <div class="differentiating-benefits-graphic-content">    
    <div
      class="differentiating-benefits-graphic-card-item"
      :style="{ backgroundImage: `url(${graphicImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import DifferentiatingBenefitsPageMixin from '@/mixins/differentiating-benefits-page-mixin'
import { DifferentiatingBenefitsPageStore } from '@/store/brand-plataform-page/differentiating-benefits-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class DifferentiatingBenefitsGraphicSection extends Mixins(
  DifferentiatingBenefitsPageMixin
) {
  differentiatingBenefitsPageStore: PageStoreContract = getModule(
    DifferentiatingBenefitsPageStore,
    this.$store
  )
  
  get graphicImageSrc(): string {
    return this.getElementMedia(6, 0)
  }

  async getDifferentiatingBenefitsPageStore(): Promise<void> {
    await this.differentiatingBenefitsPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.differentiatingBenefitsPageStore.isLoading) {
      await this.getDifferentiatingBenefitsPageStore()
    }
  }
}
</script>

<style lang="scss">
.differentiating-benefits-graphic-content {
  margin-top: 5px;
  display: grid;  
  position: relative;  
  justify-content: center;
  align-items: center;    
}

.differentiating-benefits-graphic-card-item {
  position: relative;
  bottom: 0;  
  width: 1045px;
  height: 646px;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover; 
}
</style>
