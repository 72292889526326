import { render, staticRenderFns } from "./TypographyGlobotipoCorporativaSection.vue?vue&type=template&id=adfbd2e0"
import script from "./TypographyGlobotipoCorporativaSection.vue?vue&type=script&lang=ts"
export * from "./TypographyGlobotipoCorporativaSection.vue?vue&type=script&lang=ts"
import style0 from "./TypographyGlobotipoCorporativaSection.vue?vue&type=style&index=0&id=adfbd2e0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports