<template>
  <div class="our-beliefs-banner-content">
    <div
      class="our-beliefs-banner-image"
      :style="{ backgroundImage: `url(${ourBeliefsPageStoreBannerImageSrc})` }"
    ></div>
    <div class="our-beliefs-overlay-image">
      <h1 class="our-beliefs-banner-title">
        {{ ourBeliefsPageStoreBannerImageTitle }}
      </h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import OurBeliefsPageMixin from '@/mixins/our-beliefs-page-mixin'
import { OurBeliefsPageStore } from '@/store/brand-plataform-page/our-beliefs-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class OurBeliefsBannerSection extends Mixins(OurBeliefsPageMixin) {
  ourBeliefsPageStore: PageStoreContract = getModule(OurBeliefsPageStore, this.$store)

  get ourBeliefsPageStoreBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get ourBeliefsPageStoreBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getOurBeliefsPageStore(): Promise<void> {
    await this.ourBeliefsPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.ourBeliefsPageStore.isLoading) {
      await this.getOurBeliefsPageStore()
    }
  }
}
</script>

<style lang="scss">
.our-beliefs-banner-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  max-height: 28rem;
  width: 100%;
  justify-items: center;
  justify-content: center;
}

.our-beliefs-banner-image {
  position: relative;
  width: 100%;
  height: 451px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.our-beliefs-overlay-image {
  position: absolute;
  bottom: 5.5rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.our-beliefs-banner-title {
  font-size: 8rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}
</style>
