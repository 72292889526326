<template>
  <div class="intro-content">
    <h1 class="intro-title-id-visual">{{ introTitle }}</h1>
    <div class="intro-description-content">
      <p class="intro-description">{{ introDescription }}</p>
      <p class="intro-description-paragraph">{{ introDescriptionParagraph1 }}</p>
      <p class="intro-description-paragraph">{{ introDescriptionParagraph2 }}</p>
      <p class="intro-description-paragraph">{{ introDescriptionParagraph3 }}</p>
    </div>
    <div class="intro-card-item" :style="{ backgroundImage: `url(${introImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class IntroSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get introTitle(): string {
    return this.getElementTitle(1, 0)
  }

  get introDescription(): string {
    return this.getElementText(1, 0)
  }
  get introDescriptionParagraph1(): string {
    return this.getElementText(1, 1)
  }
  get introDescriptionParagraph2(): string {
    return this.getElementText(1, 2)
  }
  get introDescriptionParagraph3(): string {
    return this.getElementText(1, 3)
  }

  get introImageSrc(): string {
    return this.getElementMedia(1, 0)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  max-width: 1045px;
}

.intro-title-id-visual {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  padding: 0 5rem 0 3rem;

  margin: 0;
}

.intro-description-content {
  width: 80%;
}

.intro-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.intro-description-paragraph {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.intro-card-item {
  position: relative;
  bottom: 4rem;
  width: 100%;
  max-width: 564px;
  height: 388px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 3rem;
  margin-top: -40px;

}

@media (max-width: 1440px) {
  .intro-card-item {
    padding-left: 0;
  }
}
</style>
