<template>
  <div class="brand-intro-content">
    <h1 class="brand-intro-title">{{ BrandIntroTitle }}</h1>
    <div class="description-intro-content">
      <p class="brand-intro-description">{{ BrandIntroDescription }}</p>
      <p class="brand-intro-description">{{ BrandIntroDescription2 }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import BrandPlataformPageMixin from '@/mixins/brand-plataform-page-mixin'
import { BrandPlataformPageStore } from '@/store/brand-plataform-page/brand-plataform-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class BrandIntroSection extends Mixins(BrandPlataformPageMixin) {
  brandPlataformPageStore: PageStoreContract = getModule(BrandPlataformPageStore, this.$store)

  get BrandIntroTitle(): string {
    return this.getElementText(1, 0)
  }

  get BrandIntroDescription(): string {
    return this.getElementText(1, 1)
  }

  get BrandIntroDescription2(): string {
    return this.getElementText(1, 2)
  }

  async getBrandPlataformPage(): Promise<void> {
    await this.brandPlataformPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.brandPlataformPageStore.isLoading) {
      await this.getBrandPlataformPage()
    }
  }
}
</script>

<style lang="scss">
.brand-intro-content {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 4rem;
  max-width: 949px;
  justify-items: center;

  .description-intro-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100% !important;
  }
}

.brand-intro-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

}

.brand-intro-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

</style>