<template>
  <div class="banner-image-visual-id">
    <div class="banner-image" :style="{ backgroundImage: `url(${bannerImageSrc})` }"></div>
    <div class="overlay-image">
      <h1 class="banner-title">{{ bannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class BannerImageSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get bannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get bannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.banner-image-visual-id {
  position: relative;
  background-color: gray;

  width: 100%;
  max-height: 28rem;
}

.banner-image {
  position: relative;
  width: 100%;
  height: 451px;

  background-size: cover;

  background-repeat: no-repeat;
  background-position: center;
}

.overlay-image {
  position: absolute;
  bottom: 5.5rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.banner-title {
  font-size: 8rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;

}
</style>
