<template>
  <div class="banner-brand-image-content">
    <div class="banner-brand-image" :style="{ backgroundImage: `url(${bannerImageSrc})` }"></div>
    <div class="overlay-brand-image">
      <h1 class="banner-brand-title">{{ bannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import BrandPlataformPageMixin from '@/mixins/brand-plataform-page-mixin'
import { BrandPlataformPageStore } from '@/store/brand-plataform-page/brand-plataform-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class BannerBrandPlataformImageSection extends Mixins(BrandPlataformPageMixin) {
  brandPlataformPageStore: PageStoreContract = getModule(BrandPlataformPageStore, this.$store)

  get bannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get bannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getBrandPlataformPage(): Promise<void> {
    await this.brandPlataformPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.brandPlataformPageStore.isLoading) {
      await this.getBrandPlataformPage()
    }
  }
}
</script>

<style lang="scss">
.banner-brand-image-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  width: 100% !important;
  max-height: 28rem;
}

.banner-brand-image {
  position: relative;
  width: 100%;
  height: 451px;

  background-size: cover;

  background-repeat: no-repeat;
  background-position: center;
}

.overlay-brand-image {
  position: absolute;
  bottom: 5.1rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.banner-brand-title {
  font-size: 8rem;
  width: 55rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}
</style>
