<template>
  <div class="iconography-intro-content-wrapper">
    <div class="iconography-intro-content">
      <div class="description-iconography-content">
        <div class="title-iconography-intro-wrapper">
          <h1 class="iconography-intro-title-span">{{ iconographyIntroTitleSpan }}</h1>
          <h1 class="iconography-intro-title">{{ iconographyIntroTitle }}</h1>
        </div>

        <p class="iconography-intro-description-paragraph1">
          {{ iconographyIntroDescriptionParagraph1 }}
        </p>
        <p class="iconography-intro-description-paragraph">
          {{ iconographyIntroDescriptionParagraph2 }}
        </p>
        <p class="iconography-intro-description-paragraph">
          {{ iconographyIntroDescriptionParagraph3 }}
        </p>

        <div class="button-iconography-intro-wrapper">
          <IconographyButton
            :imageSrc="iconographyIntroImageSrcZip"
            buttonText="Clique aqui para acessar a biblioteca"
            downloadName="icones.zip"
          />
        </div>
      </div>

      <div
        class="iconography-intro-card-item"
        :style="{ backgroundImage: `url(${iconographyIntroImageSrc})` }"
      ></div>

      <div>
        <div
          class="iconography-intro-card-item-bottom-1"
          :style="{ backgroundImage: `url(${iconographyIntroImageSrcBottom1})` }"
        ></div>
      </div>
    </div>

    <div
      class="iconography-intro-card-item-bottom-2"
      :style="{ backgroundImage: `url(${iconographyIntroImageSrcBottom2})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import IconographyPageMixin from '@/mixins/iconography-page-mixin'
import { IconographyPageStore } from '@/store/visual-identity-page/iconography-page'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import IconographyButton from './IconographyButton.vue'

@Component({
  components: { IconographyButton }
})
export default class IconographyIntroSection extends Mixins(IconographyPageMixin) {
  iconographyPageStore: PageStoreContract = getModule(IconographyPageStore, this.$store)

  get iconographyIntroTitleSpan(): string {
    return this.getElementTitle(1, 0)
  }
  get iconographyIntroTitle(): string {
    return this.getElementText(1, 0)
  }
  get iconographyIntroDescriptionParagraph1(): string {
    return this.getElementText(1, 1)
  }
  get iconographyIntroDescriptionParagraph2(): string {
    return this.getElementText(1, 2)
  }
  get iconographyIntroDescriptionParagraph3(): string {
    return this.getElementText(1, 3)
  }
  get iconographyIntroImageSrc(): string {
    return this.getElementMedia(1, 4)
  }
  get iconographyIntroImageSrcBottom1(): string {
    return this.getElementMedia(1, 5)
  }
  get iconographyIntroImageSrcBottom2(): string {
    return this.getElementMedia(1, 6)
  }

  get iconographyIntroImageSrcZip(): string {
    return this.getElementMedia(1, 7)
  }

  async getGraphicPage(): Promise<void> {
    await this.iconographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.iconographyPageStore.isLoading) {
      await this.getGraphicPage()
    }
  }
}
</script>

<style lang="scss">
.iconography-intro-content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1045px;
  width: 100%;
}

.iconography-intro-content {
  margin-top: 0;
  display: grid;
  grid-template-columns: 44% 52%;
  justify-content: space-between;

  max-width: 1045px;
  width: 100%;
}

.title-iconography-intro-wrapper {
  display: flex;
  align-items: center;
}

.iconography-intro-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.iconography-intro-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-iconography-content {
  width: 434px;
  margin-left: 4rem;
  padding-top: 3rem;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.iconography-intro-card-item {
  position: relative;

  max-width: 670px;
  width: 100%;
  height: 647px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.iconography-intro-card-item-bottom-1 {
  position: relative;
  left: 3.8rem;
  bottom: 8rem;

  max-width: 405px;
  width: 100%;
  height: 255px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.iconography-intro-card-item-bottom-2 {
  position: relative;
  left: 3rem;
  bottom: 4rem;

  max-width: 1045px;
  width: 100%;
  height: 659px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.button-iconography-intro-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5rem;
  padding-top: 5rem;
}
</style>
