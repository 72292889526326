<template>
  <div class="illustration-gradients-content">
    <div class="illustration-gradients-wrapper">
      <h1 class="illustration-gradients-title-span">{{ gradientsTitleSpan }}</h1>
      <h1 class="illustration-gradients-title">{{ gradientsTitle }}</h1>
    </div>

    <div class="description-illustration-gradients-wrapper">
      <div class="illustration-gradients-icon-wrapper">
        <p class="illustration-gradients-description">{{ gradientsDescription }}</p>
      </div>
    </div>
    <div
      class="illustration-gradients-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import IllustrationPageMixin from '@/mixins/illustration-page-mixin'
import { IllustrationPageStore } from '@/store/visual-identity-page/illustration-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class IllustrationGradientsSection extends Mixins(IllustrationPageMixin) {
  illustrationPageStore: PageStoreContract = getModule(IllustrationPageStore, this.$store)

  get gradientsTitleSpan(): string {
    return this.getElementTitle(18, 0)
  }

  get gradientsTitle(): string {
    return this.getElementText(18, 0)
  }

  get gradientsDescription(): string {
    return this.getElementText(18, 1)
  }

  get ImageSrc(): string {
    return this.getElementMedia(18, 2)
  }

  async getIllustrationPage(): Promise<void> {
    await this.illustrationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.illustrationPageStore.isLoading) {
      await this.getIllustrationPage()
    }
  }
}
</script>

<style lang="scss">
.illustration-gradients-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.illustration-gradients-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.illustration-gradients-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.illustration-gradients-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-illustration-gradients-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.illustration-gradients-icon-wrapper {
  display: flex;
  align-items: center;
}

.illustration-gradients-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 434px;
}

.illustration-gradients-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 600px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
