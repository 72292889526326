<template>
  <div class="grid-layout-content">
    <div class="grid-layout-title-wrapper">
      <h1 class="grid-layout-title-span">{{ gridTitleSpan }}</h1>
      <h1 class="grid-layout-title">{{ gridTitle }}</h1>
    </div>

    <div class="grid-layout-card-item" :style="{ backgroundImage: `url(${ImageSrc1})` }"></div>
    <div class="grid-layout-card-item" :style="{ backgroundImage: `url(${ImageSrc2})` }"></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GridPageMixin from '@/mixins/grid-page-mixin'
import { GridPageStore } from '@/store/visual-identity-page/grid-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class GridsIntroSection extends Mixins(GridPageMixin) {
  gridPageStore: PageStoreContract = getModule(GridPageStore, this.$store)

  get gridTitleSpan(): string {
    return this.getElementTitle(1, 0)
  }

  get gridTitle(): string {
    return this.getElementText(1, 0)
  }

  get ImageSrc1(): string {
    return this.getElementMedia(1, 1)
  }

  get ImageSrc2(): string {
    return this.getElementMedia(1, 2)
  }

  async getGridPage(): Promise<void> {
    await this.gridPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.gridPageStore.isLoading) {
      await this.getGridPage()
    }
  }
}
</script>

<style lang="scss">
.grid-layout-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.grid-layout-title-wrapper {
  margin-top: -4rem;
  display: flex;
  align-items: center;
  padding: 3rem;
}

.grid-layout-title-span {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.grid-layout-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.grid-layout-card-item {
  position: relative;
  bottom: 2rem;

  max-width: 1045px;
  width: 100%;
  height: 558px;
  margin-bottom: 2rem;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
