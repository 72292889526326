<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5ZM23.225 40.725L14.25 31.75C13.275 30.775 13.275 29.2 14.25 28.225C15.225 27.25 16.8 27.25 17.775 28.225L25 35.425L42.2 18.225C43.175 17.25 44.75 17.25 45.725 18.225C46.7 19.2 46.7 20.775 45.725 21.75L26.75 40.725C25.8 41.7 24.2 41.7 23.225 40.725Z"
        fill="#06AA48"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class IconCheck extends Vue {}
</script>
