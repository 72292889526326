import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { SessionDTO } from '@/data/dto/session.dto'
import { CredentialsDTO } from '@/data/dto/credentials.dto'
import CentralDaMarcaBffService from '@/services/central-da-marca-bff.service'

@Module
export default class LoginModule extends VuexModule {
  bffService = new CentralDaMarcaBffService()
  isLogged = !!window.localStorage.getItem('token')

  @Action
  async login(credentials: CredentialsDTO): Promise<void> {
    await this.context.dispatch('logout')

    const sessionResponse = await this.bffService.login(credentials)
    const session = sessionResponse.data

    if (!session.token || !session.refreshToken) {
      return
    }

    this.context.commit('loginSuccess', session)
  }

  @Action
  logout(): void {
    this.context.commit('clearSession')
  }

  @Mutation
  loginSuccess(session: SessionDTO) {
    window.localStorage.setItem('token', session.token)
    window.localStorage.setItem('refreshToken', session.refreshToken)

    this.isLogged = true
  }

  @Mutation
  clearSession() {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('refreshToken')
    this.isLogged = false
  }
}
