<template>
  <div class="intro-graphic-content">
    <div
      class="intro-graphic-card-item"
      :style="{ backgroundImage: `url(${introGraphicImageSrc})` }"
    ></div>    
    <div class="description-graphic-content">
      <p class="intro-graphic-description">{{ introGraphicDescription }}</p>
      <p class="intro-graphic-description-paragraph1">{{ introGraphicDescriptionParagraph1 }}</p>
      <p class="intro-graphic-description-paragraph">{{ introGraphicDescriptionParagraph2 }}</p>
      <p class="intro-graphic-description-paragraph">{{ introGraphicDescriptionParagraph3 }}</p>
    </div>
    
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GraphicPageMixin from '@/mixins/graphic-page-mixin'
import { GraphicPageStore } from '@/store/visual-identity-page/graphic-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class GraphicIntroSection extends Mixins(GraphicPageMixin) {
  graphicPageStore: PageStoreContract = getModule(GraphicPageStore, this.$store)

  get introGraphicDescription(): string {
    return this.getElementText(1, 0)
  }
  get introGraphicDescriptionParagraph1(): string {
    return this.getElementText(1, 1)
  }
  get introGraphicDescriptionParagraph2(): string {
    return this.getElementText(1, 2)
  }
  get introGraphicDescriptionParagraph3(): string {
    return this.getElementText(1, 3)
  }
  get introGraphicImageSrc(): string {
    return this.getElementMedia(1, 0)
  }

  async getGraphicPage(): Promise<void> {
    await this.graphicPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.graphicPageStore.isLoading) {
      await this.getGraphicPage()
    }
  }
}
</script>

<style lang="scss">
.intro-graphic-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 44% 52%;
  max-width: 1045px;
  width: 100%;
}

.intro-graphic-title {
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 0 3rem 3rem 3rem;
  margin: 0;
}

.description-graphic-content {
  width: 434px;
  margin-left: 9rem;
  padding-top: 3rem;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.intro-graphic-card-item {
  position: relative;  
  width: 538px;
  height: 580px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 3rem;
}
</style>
