<template>
  <div class="photography-what-not-content">
    <div class="title-photography-what-not-wrapper">
      <h1 class="photography-what-not-title-span">{{ photographyWhatNoToDoTitleSpan }}</h1>
      <h1 class="photography-what-not-title">{{ photographyWhatNoToDoTitle }}</h1>
    </div>
    <div class="description-photography-what-not-wrapper">
      <div>
        <div class="icon-wrapper">
          <IconCancel />
          <p class="photography-what-not-description">
            {{ photographyWhatNoToDoDescription1 }}
          </p>
        </div>

        <div class="icon-wrapper">
          <IconCancel />
          <p class="photography-what-not-description">
            {{ photographyWhatNoToDoDescription2 }}
          </p>
        </div>
      </div>

      <div>
        <div class="icon-wrapper">
          <IconCancel />
          <p class="photography-what-not-description">
            {{ photographyWhatNoToDoDescription3 }}
          </p>
        </div>

        <div class="icon-wrapper">
          <IconCancel />
          <p class="photography-what-not-description">
            {{ photographyWhatNoToDoDescription4 }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="photography-what-not-card-item"
      :style="{ backgroundImage: `url(${photographyWhatNoToDoImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PhotographyPageMixin from '@/mixins/photography-page-mixin'
import { PhotographyPageStore } from '@/store/visual-identity-page/photography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PhotographyExpandableImage from './PhotographyExpandableImage.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'

@Component({
  components: { IconCancel, PhotographyExpandableImage }
})
export default class PhotographyWhatNoToDoSection extends Mixins(PhotographyPageMixin) {
  photographyPageStore: PageStoreContract = getModule(PhotographyPageStore, this.$store)

  get photographyWhatNoToDoTitleSpan(): string {
    return this.getElementTitle(8, 0)
  }

  get photographyWhatNoToDoTitle(): string {
    return this.getElementText(8, 0)
  }

  get photographyWhatNoToDoDescription1(): string {
    return this.getElementText(8, 1)
  }

  get photographyWhatNoToDoDescription2(): string {
    return this.getElementText(8, 2)
  }

  get photographyWhatNoToDoDescription3(): string {
    return this.getElementText(8, 3)
  }

  get photographyWhatNoToDoDescription4(): string {
    return this.getElementText(8, 4)
  }

  get photographyWhatNoToDoImageSrc(): string {
    return this.getElementMedia(8, 5)
  }

  async getPhotographyPage(): Promise<void> {
    await this.photographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.photographyPageStore.isLoading) {
      await this.getPhotographyPage()
    }
  }
}
</script>

<style lang="scss">
.photography-what-not-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-photography-what-not-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.photography-what-not-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.photography-what-not-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-photography-what-not-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.photography-what-not-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.photography-what-not-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 700px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
