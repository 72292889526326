<template>
  <div class="graphic-photos-content">
    <div class="graphic-photos-card-item" :style="{ backgroundImage: `url(${ImageSrc})` }"></div>
    <div class="graphic-photos-card-item" :style="{ backgroundImage: `url(${ImageSrc2})` }"></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GraphicPageMixin from '@/mixins/graphic-page-mixin'
import { GraphicPageStore } from '@/store/visual-identity-page/graphic-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class GraphicsPhotosSection extends Mixins(GraphicPageMixin) {
  graphicPageStore: PageStoreContract = getModule(GraphicPageStore, this.$store)

  get ImageSrc(): string {
    return this.getElementMedia(11, 0)
  }

  get ImageSrc2(): string {
    return this.getElementMedia(11, 1)
  }

  async getGraphicPage(): Promise<void> {
    await this.graphicPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.graphicPageStore.isLoading) {
      await this.getGraphicPage()
    }
  }
}
</script>

<style lang="scss">
.graphic-photos-content {
  display: grid;
}

.graphic-photos-card-item {
  position: relative;
  bottom: 1rem;
  left: 3rem;
  width: 1045px;
  height: 766px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
