<template>
  <div class="video-player">
    <video ref="videoElement" :src="videoSrc" autoplay muted controls></video>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import HomePageMixin from '@/mixins/home-page-mixin'
import { HomePageStore } from '@/store/home-page/home-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class BannerVideoSection extends Mixins(HomePageMixin) {
  homePageStore: PageStoreContract = getModule(HomePageStore, this.$store)

  get videoTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get videoDescription(): string {
    return this.getElementText(0, 0)
  }

  get videoSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getHomePage(): Promise<void> {
    await this.homePageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.homePageStore.isLoading) {
      await this.getHomePage()
      const videoElement = this.$refs.videoElement as HTMLVideoElement
      videoElement.addEventListener('loadedmetadata', this.onLoadedMetadata)
    }
  }

  beforeUnmount() {
    const videoElement = this.$refs.videoElement as HTMLVideoElement
    videoElement.removeEventListener('loadedmetadata', this.onLoadedMetadata)
  }

  onLoadedMetadata() {
    console.log('Video metadata loaded')
  }
}
</script>

<style lang="scss">
.video-player {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  width: 100%;
  max-height: 33rem;


video {
  width: 100% !important;
}
}
.overlay {
  position: absolute;
  top: 10rem;
  left: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.video-title {
  font-size: 5rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

.video-description {
  font-size: 1.2rem;
  margin: 10px 0;
  width: 33rem;
  letter-spacing: -0.005em;
  text-align: left;
  font-family: GlobotipoCorporativaTextosRegular;
}

@media (min-width: 1726px) {
  video {
    width: 100% !important;
  }
}
</style>
