<template>
  <div class="logo-decision-tree-content">
    <div class="title-logo-decision-tree-wrapper">
      <h1 class="logo-decision-tree-title-span">{{ logoDecisionTreeTitleSpan }}</h1>
      <h1 class="logo-decision-tree-title">{{ logoDecisionTreeTitle }}</h1>
    </div>
    <div class="description-logo-decision-tree-wrapper">
      <p class="logo-decision-tree-description">
        {{ logoDecisionTreeDescriptionParagraph1 }}
      </p>
      <p class="logo-decision-tree-description">
        {{ logoDecisionTreeDescriptionParagraph2 }}
      </p>
      <p class="logo-decision-tree-description">
        {{ logoDecisionTreeDescriptionParagraph3 }}
      </p>
    </div>
    <div
      class="logo-decision-tree-card-item"
      :style="{ backgroundImage: `url(${logoDecisionTreeImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LogoDecisionTreeSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoDecisionTreeTitleSpan(): string {
    return this.getElementTitle(15, 0)
  }

  get logoDecisionTreeTitle(): string {
    return this.getElementText(15, 0)
  }

  get logoDecisionTreeDescriptionParagraph1(): string {
    return this.getElementText(15, 1)
  }

  get logoDecisionTreeDescriptionParagraph2(): string {
    return this.getElementText(15, 2)
  }

  get logoDecisionTreeDescriptionParagraph3(): string {
    return this.getElementText(15, 3)
  }

  get logoDecisionTreeImageSrc(): string {
    return this.getElementMedia(15, 4)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-decision-tree-content {
  display: grid;
  margin-top: 6rem;
  width: 100%;
  max-width: 1045px;
}

.title-logo-decision-tree-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.description-logo-decision-tree-wrapper {
  padding: 0rem 3rem 0rem 3rem;
  position: relative;
  bottom: 4rem;

  width: 50rem;
}

.logo-decision-tree-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-decision-tree-restricted-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.logo-decision-tree-description {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 50px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
}

.logo-decision-tree-card-item {
  position: relative;
  bottom: 1rem;

  max-width: 1045px;
  width: 95%;
  height: 766px;
  left: 2.4rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
