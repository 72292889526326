<template>
  <div class="logo-inverted-main-content">
    <div class="title-inverted-main-wrapper">
      <h1 class="logo-inverted-main-span">{{ logoInvertedMainVersionTitleSpan }}</h1>
      <h1 class="logo-inverted-main-title">{{ logoInvertedMainVersionTitle }}</h1>
    </div>
    <div class="description-inverted-main-wrapper">
      <div>
        <p class="logo-inverted-main-description">{{ logoInvertedMainVersionDescription }}</p>
        <p class="logo-inverted-main-description">{{ logoInvertedMainVersionDescription1 }}</p>
      </div>

      <div class="inverted-main-icon">
        <IconCheck />
        <p class="logo-inverted-main-description2">{{ logoInvertedMainVersionDescription2 }}</p>
      </div>
    </div>
    <div
      class="logo-inverted-main-card-item"
      :style="{ backgroundImage: `url(${logoInvertedMainVersionImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class LogoInvertedMainVersionSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoInvertedMainVersionTitleSpan(): string {
    return this.getElementTitle(6, 0)
  }

  get logoInvertedMainVersionTitle(): string {
    return this.getElementText(6, 0)
  }

  get logoInvertedMainVersionDescription(): string {
    return this.getElementText(6, 1)
  }

  get logoInvertedMainVersionDescription1(): string {
    return this.getElementText(6, 2)
  }

  get logoInvertedMainVersionDescription2(): string {
    return this.getElementText(6, 3)
  }

  get logoInvertedMainVersionImageSrc(): string {
    return this.getElementMedia(6, 4)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-inverted-main-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.title-inverted-main-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-inverted-main-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-inverted-main-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.inverted-main-icon {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.description-inverted-main-wrapper {
  padding: 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.logo-inverted-main-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 374px;
  padding-top: 3rem;
}

.logo-inverted-main-description2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 374px;
  padding-left: 7px;
}

.logo-inverted-main-card-item {
  position: relative;
    bottom: 3rem;
    left: 3rem;
    width: 92%;
    max-width: 1034px;
    height: 319px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
</style>
