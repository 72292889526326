<template>
  <div class="typography-intro-content">
    <h1 class="typography-intro-title">{{ typographyIntroTitle }}</h1>
    <div class="description-content">
      <p class="typography-intro-description">{{ typographyIntroDescription }}</p>
      <p class="typography-intro-description-paragraph">
        {{ typographyIntroDescriptionParagraph1 }}
      </p>
      <p class="typography-intro-description-paragraph">
        {{ typographyIntroDescriptionParagraph2 }}
      </p>
    </div>

    <div class="wrapper-content-2">
      <h1 class="typography-intro-title-2">{{ typographyIntroTitle2 }}</h1>
      <p class="typography-intro-description-2">{{ typographyIntroDescription2 }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class TypographyIntroSection extends Mixins(TypographyPageMixin) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyIntroTitle(): string {
    return this.getElementTitle(1, 0)
  }

  get typographyIntroDescription(): string {
    return this.getElementText(1, 0)
  }
  get typographyIntroDescriptionParagraph1(): string {
    return this.getElementText(1, 1)
  }
  get typographyIntroDescriptionParagraph2(): string {
    return this.getElementText(1, 2)
  }

  get typographyIntroTitle2(): string {
    return this.getElementTitle(1, 3)
  }

  get typographyIntroDescription2(): string {
    return this.getElementText(1, 3)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 44% 52%;
  max-width: 1045px;
  width: 100%;
}

.typography-intro-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  padding: 0 3rem 3rem 3rem;

  margin: 0;
}

.description-content {
  width: 506px;
  margin-left: 6rem;
}

.typography-intro-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.wrapper-content-2 {
  display: grid;
  justify-items: center;
  width: 65rem;

  padding-bottom: 3rem;
}

.typography-intro-title-2 {
  cursor: pointer;
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 70px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: center;

  padding: 6rem 3rem 0 3rem;

  margin: 0;
}

.typography-intro-title-2:hover {
  font-family: GlobotipoCorporativaTextosBold;
}

.typography-intro-description-2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;

  padding-top: 2rem;
  width: 495px;
}

.typography-intro-description-paragraph {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.typography-intro-card-item {
  position: relative;
  bottom: 4rem;

  max-width: 564px;
  width: 100%;
  height: 388px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  margin-bottom: 3rem;
}
</style>
