<template>
  <div class="personality-loyal-content">
    <div
      class="personality-loyal-banner-image"
      :style="{ backgroundImage: `url(${PersonalityLoyalToValuesImageSrc})` }"
    ></div>

    <div class="personality-loyal-overlay-title-image">
      <p class="personality-loyal-image-technology-title">
        {{ PersonalityLoyalToValuesTitle }}
      </p>
      <p class="personality-loyal-image-technology-title2">
        {{ PersonalityLoyalToValuesTitle2}}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PersonalityPageMixin from '@/mixins/personality-page-mixin'
import {PersonalityPageStore } from '@/store/brand-plataform-page/personality-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class PersonalityLoyalToValuesSection extends Mixins(PersonalityPageMixin) {
  personalityPageStore: PageStoreContract = getModule(PersonalityPageStore, this.$store)

  get PersonalityLoyalToValuesTitle(): string {
    return this.getElementText(5, 0)
  }

  get PersonalityLoyalToValuesImageSrc(): string {
    return this.getElementMedia(5, 0)
  }

  get PersonalityLoyalToValuesTitle2(): string {
    return this.getElementText(5, 1)
  }

  get PersonalityLoyalToValuesTitle3(): string {
    return this.getElementText(5, 2)
  }

  async getPersonalityPageStore(): Promise<void> {
    await this.personalityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.personalityPageStore.isLoading) {
      await this.getPersonalityPageStore()
    }
  }
}
</script>

<style lang="scss">
.personality-loyal-content {
  display: grid;
  margin-top: 5px;
  position: relative;  
  justify-content: center;
  align-items: center;  
}

.personality-loyal-banner-image {
  position: relative;
  bottom: 0;  
  width: 1045px;
  height: 513px;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5)!important; 
  }
}

.personality-loyal-overlay-title-image {
  position: absolute;
  bottom: 0rem;
  left: 1rem;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
  margin-top: 50rem;
  align-items: center;
}

.personality-loyal-image-technology-title {
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: center;
  width: 1015px;
  margin: 0;
}

.personality-loyal-image-technology-title2 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 20px;
  text-align: center;
  width: 509px;
  margin: 0;
}
</style>
