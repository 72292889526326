<template>
  <div class="logo-social-media-content">
    <div class="title-social-media-wrapper">
      <h1 class="logo-social-media-title-span">{{ logoSocialMediaVersionTitleSpan }}</h1>
      <h1 class="logo-social-media-title">{{ logoSocialMediaVersionTitle }}</h1>
    </div>
    <div class="description-social-media-wrapper">
      <div>
        <p class="logo-social-media-description">{{ logoSocialMediaVersionDescription }}</p>

        <div class="icon-social-media-wrapper">
          <IconCheck />
          <p class="logo-social-media-description">{{ logoSocialMediaVersionDescription1 }}</p>
        </div>
      </div>

      <div
        class="logo-social-media-card-item"
        :style="{ backgroundImage: `url(${logoSocialMediaVersionImageSrc})` }"
      ></div>
    </div>

    <div class="logo-social-media-img-bottom">
      <div
        class="logo-social-media-card-item-bottom"
        :style="{ backgroundImage: `url(${logoSocialMediaVersionImageBottomSrc})` }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class LogoSocialMediaVersionSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoSocialMediaVersionTitleSpan(): string {
    return this.getElementTitle(13, 0)
  }

  get logoSocialMediaVersionTitle(): string {
    return this.getElementText(13, 0)
  }

  get logoSocialMediaVersionDescription(): string {
    return this.getElementText(13, 1)
  }

  get logoSocialMediaVersionDescription1(): string {
    return this.getElementText(13, 2)
  }

  get logoSocialMediaVersionDescription2(): string {
    return this.getElementText(13, 3)
  }

  get logoSocialMediaVersionImageSrc(): string {
    return this.getElementMedia(13, 3)
  }

  get logoSocialMediaVersionImageBottomSrc(): string {
    return this.getElementMedia(13, 4)
  }

  get logoSocialMediaVersionImageBottomSrc2(): string {
    return this.getElementMedia(13, 5)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-social-media-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.title-social-media-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-social-media-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-social-media-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.icon-social-media-wrapper {
  display: flex;
  align-items: center;

  margin-top: 3rem;
}

.description-social-media-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.logo-social-media-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.logo-social-media-card-item {
  position: relative;
  bottom: 3rem;
  left: 3rem;

  width: 361px;
  height: 291px;

  background-repeat: no-repeat;
  background-position: center;
}

.logo-social-media-img-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-social-media-card-item-bottom {
  position: relative;
    bottom: 3rem;
    width: 95%;
    height: 220px;
    left: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.logo-social-media-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  margin: 1rem 0rem 5rem 0rem;
}
</style>
