<template>
  <div class="grid-intro-two-content">
    <div class="title-signature-brands-wrapper">
      <h1 class="grid-intro-two-title-span">{{ gridIntroTwoTitleSpan }}</h1>
      <h1 class="grid-intro-two-title">{{ gridIntroTwoTitle }}</h1>
    </div>
    <div class="description-signature-brands-wrapper">
      <div class="icon-wrapper">
        <p class="grid-intro-two-description">{{ gridIntroTwoDescription1 }}</p>
      </div>

      <div class="icon-wrapper">
        <p class="grid-intro-two-description">{{ gridIntroTwoDescription2 }}</p>
      </div>

      <div class="icon-wrapper">
        <p class="grid-intro-two-description">{{ gridIntroTwoDescription3 }}</p>
      </div>

      <div class="icon-wrapper">
        <p class="grid-intro-two-description">{{ gridIntroTwoDescription4 }}</p>
      </div>

      <div class="icon-wrapper">
        <p class="grid-intro-two-description">{{ gridIntroTwoDescription5 }}</p>
      </div>
    </div>
    <div
      class="grid-intro-two-card-item"
      :style="{ backgroundImage: `url(${gridIntroTwoImageSrc})` }"
    ></div>
    <div class="button-grid-wrapper">
      <GridButton
        :imageSrc="gridLayoutsImageSrc"
        buttonText="Acesse aqui nosso banco de arquivos"
        downloadName="00_Design system.ai"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GridPageMixin from '@/mixins/grid-page-mixin'
import { GridPageStore } from '@/store/visual-identity-page/grid-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import GridButton from './GridButton.vue'

@Component({
  components: {
    GridButton
  }
})
export default class GriIntroTwoSection extends Mixins(GridPageMixin) {
  gridPageStore: PageStoreContract = getModule(GridPageStore, this.$store)

  get gridIntroTwoTitleSpan(): string {
    return this.getElementTitle(2, 0)
  }

  get gridIntroTwoTitle(): string {
    return this.getElementText(2, 0)
  }

  get gridIntroTwoDescription1(): string {
    return this.getElementText(2, 1)
  }

  get gridIntroTwoDescription2(): string {
    return this.getElementText(2, 2)
  }

  get gridIntroTwoDescription3(): string {
    return this.getElementText(2, 3)
  }

  get gridIntroTwoDescription4(): string {
    return this.getElementText(2, 4)
  }

  get gridIntroTwoDescription5(): string {
    return this.getElementText(2, 5)
  }

  get gridIntroTwoImageSrc(): string {
    return this.getElementMedia(2, 6)
  }

  get gridLayoutsImageSrc(): string {
    return this.getElementMedia(2, 8)
  }

  async getGridPage(): Promise<void> {
    await this.gridPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.gridPageStore.isLoading) {
      await this.getGridPage()
    }
  }
}
</script>

<style lang="scss">
.grid-intro-two-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-signature-brands-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.grid-intro-two-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}
.grid-intro-two-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-signature-brands-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid-intro-two-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.title-image {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: left;

  padding-left: 3rem;
  width: 187px;
}

.grid-intro-two-card-item {
  position: relative;
  bottom: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 731px;

  background-repeat: no-repeat;
  background-position: center;
}

.grid-intro-two-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  margin: 1rem 0rem 5rem 0rem;
}

.button-grid-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 8rem;
}
</style>
