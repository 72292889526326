<template>
  <div class="content-home">
    <BannerVideoSection />
    <div class="content-sections-home">
      <BrandSection />
      <NewsSection />
      <IdentitiesSection />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import BannerVideoSection from '@/components/HomePage/sections/BannerVideoSection.vue'
import BrandSection from '@/components/HomePage/sections/BrandSection.vue'
import IdentitiesSection from '@/components/HomePage/sections/IdentitiesSection.vue'
import NewsSection from '@/components/HomePage/sections/NewsSection.vue'

@Component({
  components: {
    BannerVideoSection,
    BrandSection,
    IdentitiesSection,
    NewsSection
  }
})
export default class HomeView extends Vue {}
</script>

<style lang="scss">
.content-home{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-sections-home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1045px;
  width: 100%;
}
</style>
