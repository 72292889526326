<template>
  <div class="brand-graphs-content">
    <div class="brand-graphs-row1">
      <div class="graph-description-content">
        <p class="brand-graphs-description">{{ BrandGraphsDescription1 }}</p>
        <p class="brand-graphs-description">{{ BrandGraphsDescription2 }}</p>
        <p class="brand-graphs-description">{{ BrandGraphsDescription3 }}</p>
      </div>
      <div class="brand-graph-card-item" :style="{ backgroundImage: `url(${ImageSrc1})` }">
      </div>
    </div>
    <div class="brand-graphs-row2">
      <div class="graph-description-content">
        <p class="brand-graphs-description">{{ BrandGraphsDescription4 }}</p>
        <p class="brand-graphs-description">{{ BrandGraphsDescription5 }}</p>
        <p class="brand-graphs-description">{{ BrandGraphsDescription6 }}</p>
      </div>
      <div class="brand-graph-card-item" :style="{ backgroundImage: `url(${ImageSrc2})` }">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import BrandPlataformPageMixin from '@/mixins/brand-plataform-page-mixin'
import { BrandPlataformPageStore } from '@/store/brand-plataform-page/brand-plataform-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class BrandGraphsSection extends Mixins(BrandPlataformPageMixin) {
  brandPlataformPageStore: PageStoreContract = getModule(BrandPlataformPageStore, this.$store)

  get BrandGraphsDescription1(): string {
    return this.getElementText(2, 0)
  }

  get BrandGraphsDescription2(): string {
    return this.getElementText(2, 1)
  }

  get BrandGraphsDescription3(): string {
    return this.getElementText(2, 2)
  }

  get ImageSrc1(): string {
    return this.getElementMedia(2, 3)
  }

  get BrandGraphsDescription4(): string {
    return this.getElementText(2, 4)
  }

  get BrandGraphsDescription5(): string {
    return this.getElementText(2, 5)
  }

  get BrandGraphsDescription6(): string {
    return this.getElementText(2, 6)
  }

  get ImageSrc2(): string {
    return this.getElementMedia(2, 7)
  }

  async getBrandPlataformPage(): Promise<void> {
    await this.brandPlataformPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.brandPlataformPageStore.isLoading) {
      await this.getBrandPlataformPage()
    }
  }
}
</script>

<style lang="scss">
.brand-graphs-content {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  background: linear-gradient(90deg, #0079FD -37.91%, #2D51FB 20.11%, #5A29FA 78.13%, #8800F8 137.91%);
  max-width: 1045px;
  width: 100%;

  .brand-graphs-row1,
  .brand-graphs-row2 {
    display: grid;
    grid-template-columns: 34% 74%;
    justify-items: center;
    justify-content: space-evenly;
    align-items: center;
    margin: 67px 90px 0px 48px;

  }
  .brand-graphs-row1{
    .brand-graph-card-item{
      position: relative;
      bottom: 0;
      width: 561px !important;
      height: 561px !important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      }
  }  
  .brand-graphs-row2{
    margin-bottom: 100px;
    .brand-graph-card-item{
      position: relative;
      bottom: 0;
      width: 427px !important;
      height: 427px !important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      }
  }
  .graph-description-content {
    display: flex;
    flex-direction: column;
    justify-content: center
  }
}

.brand-graphs-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color:white;

}

.brand-graphs-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 65px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 0 3rem 0rem 3rem;
  margin: 0;
  margin-bottom: 10px;
}

.layout-card-item {
  position: relative;
  width: 1085px;
  height: 67px;
  background-size: cover;
  background-position: left;
  margin-bottom: 20px;
}
</style>