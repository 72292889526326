<template>
  <div class="layout-mosaic-content">
    <div class="title-layout-mosaic-wrapper">
      <h1 class="layout-mosaic-title-span">{{ layoutMosaicTitleSpan }}</h1>
      <h1 class="layout-mosaic-title">{{ layoutMosaicTitle }}</h1>
    </div>
    <div class="descriptiom-layout-mosaic-wrapper">
      <div class="icon-wrapper">
        <p class="layout-mosaic-description">{{ layoutMosaicDescription1 }}</p>
      </div>
    </div>
    <div
      class="layout-mosaic-card-item"
      :style="{ backgroundImage: `url(${layoutMosaicImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LayoutsMosaicSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutMosaicTitleSpan(): string {
    return this.getElementTitle(19, 0)
  }

  get layoutMosaicTitle(): string {
    return this.getElementText(19, 0)
  }

  get layoutMosaicDescription1(): string {
    return this.getElementText(19, 1)
  }

  get layoutMosaicImageSrc(): string {
    return this.getElementMedia(19, 2)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layout-mosaic-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-layout-mosaic-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.layout-mosaic-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.layout-mosaic-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.descriptiom-layout-mosaic-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.layout-mosaic-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 434px;
}

.layout-mosaic-card-item {
  position: relative;
  bottom: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 570px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
