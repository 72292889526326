<template>
  <div class="photography-studio-content">
    <div class="title-photography-studio-wrapper">
      <h1 class="photography-studio-title-span">{{ photographyStudioTitleSpan }}</h1>
      <h1 class="photography-studio-title">{{ photographyStudioTitle }}</h1>
    </div>
    <div class="description-photography-studio-wrapper">
      <div>
        <p class="photography-studio-description">{{ photographyStudioDescription1 }}</p>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-studio-description">{{ photographyStudioDescription2 }}</p>
        </div>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-studio-description">{{ photographyStudioDescription3 }}</p>
        </div>

        <p class="photography-studio-description">{{ photographyStudioDescription4 }}</p>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-studio-description">{{ photographyStudioDescription5 }}</p>
        </div>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-studio-description">{{ photographyStudioDescription6 }}</p>
        </div>
      </div>

      <div>
        <p class="photography-studio-description">{{ photographyStudioDescription7 }}</p>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-studio-description">{{ photographyStudioDescription8 }}</p>
        </div>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-studio-description">{{ photographyStudioDescription9 }}</p>
        </div>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-studio-description">{{ photographyStudioDescription10 }}</p>
        </div>
      </div>
    </div>

    <div class="expandable-image">
      <PhotographyExpandableImage :src="photographyStudioImageSrc1" alt="" />
      <PhotographyExpandableImage :src="photographyStudioImageSrc2" alt="" />
      <PhotographyExpandableImage :src="photographyStudioImageSrc3" alt="" />
      <PhotographyExpandableImage :src="photographyStudioImageSrc4" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import PhotographyPageMixin from '@/mixins/photography-page-mixin'
import { PhotographyPageStore } from '@/store/visual-identity-page/photography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PhotographyExpandableImage from './PhotographyExpandableImage.vue'

@Component({
  components: { IconCheck, PhotographyExpandableImage }
})
export default class PhotographyStudioSection extends Mixins(PhotographyPageMixin) {
  photographyPageStore: PageStoreContract = getModule(PhotographyPageStore, this.$store)

  get photographyStudioTitleSpan(): string {
    return this.getElementTitle(3, 0)
  }

  get photographyStudioTitle(): string {
    return this.getElementText(3, 0)
  }

  get photographyStudioDescription1(): string {
    return this.getElementText(3, 1)
  }

  get photographyStudioDescription2(): string {
    return this.getElementText(3, 2)
  }

  get photographyStudioDescription3(): string {
    return this.getElementText(3, 3)
  }

  get photographyStudioDescription4(): string {
    return this.getElementText(3, 4)
  }

  get photographyStudioDescription5(): string {
    return this.getElementText(3, 5)
  }

  get photographyStudioDescription6(): string {
    return this.getElementText(3, 6)
  }

  get photographyStudioDescription7(): string {
    return this.getElementText(3, 7)
  }

  get photographyStudioDescription8(): string {
    return this.getElementText(3, 8)
  }

  get photographyStudioDescription9(): string {
    return this.getElementText(3, 9)
  }

  get photographyStudioDescription10(): string {
    return this.getElementText(3, 10)
  }

  get photographyStudioImageSrc1(): string {
    return this.getElementMedia(3, 11)
  }

  get photographyStudioImageSrc2(): string {
    return this.getElementMedia(3, 12)
  }

  get photographyStudioImageSrc3(): string {
    return this.getElementMedia(3, 13)
  }

  get photographyStudioImageSrc4(): string {
    return this.getElementMedia(3, 14)
  }

  async getPhotographyPage(): Promise<void> {
    await this.photographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.photographyPageStore.isLoading) {
      await this.getPhotographyPage()
    }
  }
}
</script>

<style lang="scss">
.photography-studio-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-photography-studio-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.photography-studio-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.photography-studio-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-photography-studio-wrapper {
  align-items: baseline;
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.photography-studio-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.expandable-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 15px;

  width: 30vw;
  padding: 0rem 3rem 3rem 3rem;
}
</style>
