<template>
  <div class="typography-highlight-content">
    <div class="title-typography-highlight-wrapper">
      <h1 class="typography-highlight-span">{{ typographyHighlightTitleSpan }}</h1>
      <h1 class="typography-highlight-title">{{ typographyHighlightTitle }}</h1>
    </div>
    <div class="description-typography-highlight-wrapper">
      <div class="typography-highlight-icon" style="padding-top: 1rem">
        <IconCheck />
        <p class="typography-highlight-description">{{ typographyHighlightDescription1 }}</p>
      </div>
    </div>
    <div
      class="typography-highlight-card-item"
      :style="{ backgroundImage: `url(${typographyHighlightImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class TypographyHighlightSection extends Mixins(TypographyPageMixin) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyHighlightTitleSpan(): string {
    return this.getElementTitle(8, 0)
  }

  get typographyHighlightTitle(): string {
    return this.getElementText(8, 0)
  }

  get typographyHighlightDescription1(): string {
    return this.getElementText(8, 1)
  }

  get typographyHighlightImageSrc(): string {
    return this.getElementMedia(8, 2)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-highlight-content {
  display: grid;
  max-width: 1045px;
  width: 100%;
}

.title-typography-highlight-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.typography-highlight-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.typography-highlight-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.typography-highlight-icon {
  display: flex;
  align-items: center;
}

.description-typography-highlight-wrapper {
  padding: 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.typography-highlight-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 430px;
}

.typography-highlight-card-item {
  position: relative;
  bottom: 4rem;

  max-width: 1045px;
  width: 100%;
  height: 829px;

  border-radius: 8px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
