<template>
  <div>
    <div :class="isUnprotectedRoute ? 'content-root-out' : 'content-root'">
      <MenuComponent v-if="!isUnprotectedRoute" />

      <transition name="fade">
        <router-view />
      </transition>
    </div>

    <span class="footer-line"></span>

    <FooterComponent v-if="!isUnprotectedRoute" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import MenuComponent from './components/Menu.vue'
import FooterComponent from './components/Footer.vue'

@Component({
  components: {
    MenuComponent,
    FooterComponent
  }
})
export default class App extends Vue {
  get isUnprotectedRoute(): boolean {
    const isUnprotectedRoute = (name: string | null | undefined) => {
      if (name) return ['login', 'logout'].includes(name)
      return false
    }

    const currentRouteName = this.$route.name
    return isUnprotectedRoute(currentRouteName)
  }
}
</script>

<style lang="scss">
@import './styles/main.scss';

.content-root {
  background-color: rgba(255, 255, 255, 1);
  display: grid;
  grid-template-columns: 191px 1fr;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: auto;
}

.content-root-out {
  background-color: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: auto;
}

.footer-line {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: rgba(179, 179, 179, 1);
}

/* fade (screen transition) */
.fade-leave-active {
  transition: opacity 0.5s linear;
}

.fade-enter-active {
  transition: opacity 0.5s linear 0.5s;
}

.fade-enter,
.fade-leave-to,
.fade-leave-active

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
