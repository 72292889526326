<template>
  <div class="logo-black-and-white-content">
    <div class="title-black-and-white-wrapper">
      <h1 class="logo-black-and-white-span">{{ logoBlackAndWhiteTitleSpan }}</h1>
      <h1 class="logo-black-and-white-title">{{ logoBlackAndWhiteTitle }}</h1>
    </div>
    <div class="description-black-and-white-wrapper">
      <IconCheck />
      <p class="logo-black-and-white-description">{{ logoBlackAndWhiteDescription }}</p>
    </div>
    <div>
      <p class="title-image-white">{{ logoBlackAndWhiteTitleImageSrc }}</p>
      <div
        class="logo-black-and-white-card-item"
        :style="{ backgroundImage: `url(${logoBlackAndWhiteImageSrc})` }"
      ></div>
    </div>
    <div>
      <p class="title-image-black">{{ logoBlackAndWhiteTitleImageSrcSecond }}</p>
      <div
        class="logo-black-and-white-card-item"
        :style="{ backgroundImage: `url(${logoBlackAndWhiteImageSrcSecond})` }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class LogoBlackAndWhiteSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoBlackAndWhiteTitleSpan(): string {
    return this.getElementTitle(7, 0)
  }

  get logoBlackAndWhiteTitle(): string {
    return this.getElementText(7, 0)
  }

  get logoBlackAndWhiteDescription(): string {
    return this.getElementText(7, 1)
  }

  get logoBlackAndWhiteImageSrc(): string {
    return this.getElementMedia(7, 4)
  }

  get logoBlackAndWhiteImageSrcSecond(): string {
    return this.getElementMedia(7, 5)
  }

  get logoBlackAndWhiteTitleImageSrc(): string {
    return this.getElementText(7, 2)
  }

  get logoBlackAndWhiteTitleImageSrcSecond(): string {
    return this.getElementText(7, 3)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-black-and-white-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.title-black-and-white-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem 3rem 1rem 3rem;
}

.logo-black-and-white-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-black-and-white-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-black-and-white-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;

  padding: 0rem 3rem 0rem 3rem;
}

.logo-black-and-white-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 374px;
  padding-top: 2rem;
  padding-left: 7px;
}

.logo-black-and-white-card-item {
  position: relative;
  bottom: 3rem;
  width: 96%;
  max-width: 1034px;
  height: 319px;
  border-radius: 8px;

  background-repeat: no-repeat;
  background-position: center;

  margin: 2rem 1rem 0rem 3rem;
}

.title-image-white {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  position: relative;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  color: #fff;
  width: 200px;
}

.title-image-black {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #000000;

  position: relative;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  width: 200px;
}
</style>
