<template>
  <div class="content">
    <PublicIntroSection/>
    <PublicPersons1Section/>
    <PublicGraphicSection/>
  </div>
</template>

<script lang="ts">
import PublicIntroSection from '@/components/BrandPlataformPage/sections/public/PublicIntroSection.vue'
import PublicPersons1Section from '@/components/BrandPlataformPage/sections/public/PublicPersons1Section.vue'
import PublicGraphicSection from '@/components/BrandPlataformPage/sections/public/PublicGraphicSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    PublicIntroSection,
    PublicPersons1Section,
    PublicGraphicSection
  }
})
export default class PublicView extends Vue {}
</script>

<style lang="scss"></style>
