<template>
  <div class="iconography-typo-content">
    <div class="title-iconography-typo-wrapper">
      <h1 class="iconography-typo-title-span">{{ iconographyTypoUseColorsTitleSpan }}</h1>
      <h1 class="iconography-typo-title">{{ iconographyTypoUseColorsTitle }}</h1>
    </div>

    <div class="description-iconography-typo-wrapper">
      <div>
        <p class="iconography-typo-description">{{ iconographyTypoUseColorsDescription }}</p>

        <p class="iconography-typo-description" style="padding-top: 1rem; padding-bottom: 1rem">
          {{ iconographyTypoUseColorsDescription1 }}
        </p>

        <div class="wrapper-content-description">
          <IconCheck />
          <p class="iconography-typo-description-second-title">
            {{ iconographyTypoUseColorsDescription2 }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="iconography-typo-card-item"
      :style="{ backgroundImage: `url(${iconographyTypoUseColorsImageSrc})` }"
    ></div>
    <div
      class="iconography-typo-card-item"
      :style="{ backgroundImage: `url(${iconographyTypoUseColorsImageSrc2})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import IconographyPageMixin from '@/mixins/iconography-page-mixin'
import { IconographyPageStore } from '@/store/visual-identity-page/iconography-page'
import IconCheck from '@/assets/icons/IconCheck.vue'

@Component({
  components: { IconCheck }
})
export default class IconographyTypoUseColorsSection extends Mixins(IconographyPageMixin) {
  iconographyPageStore: PageStoreContract = getModule(IconographyPageStore, this.$store)

  get iconographyTypoUseColorsTitleSpan(): string {
    return this.getElementTitle(2, 0)
  }

  get iconographyTypoUseColorsTitle(): string {
    return this.getElementText(2, 0)
  }

  get iconographyTypoUseColorsDescription(): string {
    return this.getElementText(2, 1)
  }

  get iconographyTypoUseColorsDescription1(): string {
    return this.getElementText(2, 2)
  }

  get iconographyTypoUseColorsDescription2(): string {
    return this.getElementText(2, 3)
  }

  get iconographyTypoUseColorsImageSrc(): string {
    return this.getElementMedia(2, 4)
  }

  get iconographyTypoUseColorsImageSrc2(): string {
    return this.getElementMedia(2, 5)
  }

  async getIconographyPage(): Promise<void> {
    await this.iconographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.iconographyPageStore.isLoading) {
      await this.getIconographyPage()
    }
  }
}
</script>

<style lang="scss">
.iconography-typo-content {
  display: grid;

  max-width: 1045px;
  width: 100%;
}

.title-iconography-typo-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.iconography-typo-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.iconography-typo-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-iconography-typo-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.wrapper-content-description {
  display: flex;
  align-items: center;
}

.iconography-typo-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 484px;
}

.iconography-typo-description-second-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 484px;
}

.iconography-typo-description-second {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 484px;
}

.iconography-typo-icon-wrapper {
  display: flex;
  align-items: center;
}

.iconography-typo-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 558px;
  padding-bottom: 3rem;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
