<template>
  <div class="content">
    <PhotographyIntroSection />
    <PhotographyCastingSection />
    <PhotographyStudioSection />
    <PhotographyExternalSection />
    <PhotographyFramingSection />
    <PhotographyCollaboratorsSection />
    <PhotographyCollaboratorsTwoSection />
    <PhotographyWhatNoToDoSection />
  </div>
</template>

<script lang="ts">
import PhotographyCastingSection from '@/components/VisualIdentityPage/sections/photography/PhotographyCastingSection.vue'
import PhotographyCollaboratorsSection from '@/components/VisualIdentityPage/sections/photography/PhotographyCollaboratorsSection.vue'
import PhotographyCollaboratorsTwoSection from '@/components/VisualIdentityPage/sections/photography/PhotographyCollaboratorsTwoSection.vue'
import PhotographyExternalSection from '@/components/VisualIdentityPage/sections/photography/PhotographyExternalSection.vue'
import PhotographyFramingSection from '@/components/VisualIdentityPage/sections/photography/PhotographyFramingSection.vue'
import PhotographyIntroSection from '@/components/VisualIdentityPage/sections/photography/PhotographyIntroSection.vue'
import PhotographyStudioSection from '@/components/VisualIdentityPage/sections/photography/PhotographyStudioSection.vue'
import PhotographyWhatNoToDoSection from '@/components/VisualIdentityPage/sections/photography/PhotographyWhatNoToDoSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    PhotographyIntroSection,
    PhotographyCastingSection,
    PhotographyStudioSection,
    PhotographyExternalSection,
    PhotographyFramingSection,
    PhotographyCollaboratorsSection,
    PhotographyCollaboratorsTwoSection,
    PhotographyWhatNoToDoSection
  }
})
export default class PhotographyView extends Vue {}
</script>

<style lang="scss"></style>
