<template>
  <section class="login-wrapper">
    <div class="text-center">
      <img
        class="logo-image"
        src="../../assets/img/logo-central-da-marca.svg"
        alt="Logo Central da Marca"
      />
      <div class="error-message" v-if="isErrorAuthUser">
        <p>Ocorreu um erro na autenticação com o Accounts Connect.</p>
        <b-button @click="redirectToLoginPage" variant="secondary"> Tentar novamente </b-button>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AuthService, { IAuthService } from '@/services/auth.service'
import { CredentialsDTO } from '@/data/dto/credentials.dto'

@Component({
  components: {}
})
export default class Login extends Vue {
  private authService: IAuthService = new AuthService()
  private isErrorAuthUser = false

  redirectToLoginPage(): void {
    window.location.href = this.authService.generateAuthRedirection().toString()
  }

  async validateUser(callbackParams: CredentialsDTO): Promise<void> {
    try {      
      await this.$store.dispatch('login', callbackParams)
      await this.$router.push({ name: 'home', query: {} })
    } catch (e) {
      return
    }
  }

  async mounted(): Promise<void> {
    const currentUrl = new URL(window.location.href)
    try {
      const cred = await this.authService.checkSigninRedirectCallback(currentUrl)
      await this.validateUser(cred)
    } catch (err: any) {
      if (err.name === 'UndetectedRedirection') {
        setTimeout(() => {
          this.redirectToLoginPage()
        }, 1000)
      } else {
        this.isErrorAuthUser = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #fff;

  .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-image {
    height: 132px;
    max-width: 80%;
  }

  .error-message {
    border: 1px solid #fff;
    padding: 30px;
  }
}
</style>
