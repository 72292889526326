var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"menu-container"},[_c('nav',{staticClass:"fixed-menu"},[_c('div',{staticClass:"header"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/img/logo-central-da-marca.svg"),"alt":"Logo Central da Marca"}})])],1),_c('ul',[_c('li',[_c('router-link',{class:{
            active: _vm.isRouteActive('/globo')
          },attrs:{"to":{
            path: '/globo'
          }}},[_vm._v("A Globo")])],1),_c('li',[_c('router-link',{class:{
            active: _vm.isRouteActive('/plataforma-de-marca')
          },attrs:{"to":{
            path: '/plataforma-de-marca'
          }}},[_vm._v(" Plataforma de Marca ")])],1),_c('li',[_c('router-link',{staticClass:"disabled",class:{
            active: _vm.isRouteActive('/section3')
          },attrs:{"to":{
            path: '/section3'
          }}},[_vm._v("Principios de Expressão")])],1),_c('li',[_c('router-link',{staticClass:"disabled",class:{
            active: _vm.isRouteActive('/section4')
          },attrs:{"to":{
            path: '/section4'
          }}},[_vm._v("Identidade Verbal")])],1),_c('li',[_c('router-link',{class:{
            active: _vm.isRouteActive('/identidade-visual')
          },attrs:{"to":{
            path: '/identidade-visual'
          }}},[_vm._v("Identidade Visual")])],1),_c('li',[_c('router-link',{staticClass:"disabled",class:{
            active: _vm.isRouteActive('/section6')
          },attrs:{"to":{
            path: '/section6'
          }}},[_vm._v("Marcas da Globo")])],1),_c('li',[_c('a',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Log Out")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }