<template>
  <div class="logo-positioning-content">
    <div class="title-positioning-wrapper">
      <h1 class="logo-positioning-title-span">{{ logoPositioningTitleSpan }}</h1>
      <h1 class="logo-positioning-title">{{ logoPositioningTitle }}</h1>
    </div>
    <div class="description-positioning-wrapper">
      <p class="logo-positioning-description">{{ logoPositioningDescription }}</p>
    </div>
    <div
      class="logo-positioning-card-item"
      :style="{ backgroundImage: `url(${logoPositioningImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LogoPositioningSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoPositioningTitleSpan(): string {
    return this.getElementTitle(11, 0)
  }

  get logoPositioningTitle(): string {
    return this.getElementText(11, 0)
  }

  get logoPositioningDescription(): string {
    return this.getElementText(11, 1)
  }

  get logoPositioningImageSrc(): string {
    return this.getElementMedia(11, 2)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-positioning-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.title-positioning-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-positioning-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}
.logo-positioning-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-positioning-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.logo-positioning-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  width: 526px;
}

.title-image {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: left;

  padding-left: 3rem;
  width: 187px;
}

.logo-positioning-card-item {
  position: relative;
  bottom: 2rem;
  left: 2.2rem;
  width: 92%;
  max-width: 1045px;
  height: 724px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-positioning-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  margin: 1rem 0rem 5rem 0rem;
}
</style>
