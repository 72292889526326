<template>
  <div class="personality-graphic-content">
    <div class="personality-graphic-content">    
    <div
      class="personality-graphic-card-item"
      :style="{ backgroundImage: `url(${graphicImageSrc})` }"
    ></div>
  </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PersonalityPageMixin from '@/mixins/personality-page-mixin'
import { PersonalityPageStore } from '@/store/brand-plataform-page/personality-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'


@Component({})
export default class PersonalityGraphicSection extends Mixins(PersonalityPageMixin) {
  personalityPageStore: PageStoreContract = getModule(PersonalityPageStore, this.$store)

  get graphicImageSrc(): string {
    return this.getElementMedia(7, 0)
  }  

  async getPersonalityPageStore(): Promise<void> {
    await this.personalityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.personalityPageStore.isLoading) {
      await this.getPersonalityPageStore()
    }
  }
}
</script>

<style lang="scss">
.personality-graphic-content {
  margin-top: 2px;
  display: grid;  
  position: relative;  
  justify-content: center;
  align-items: center;    
}

.personality-graphic-card-item {
  position: relative;
  bottom: 0;  
  width: 1045px;
  height: 646px;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
}
</style>
