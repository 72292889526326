<script lang="ts">
export default {
  name: 'FooterComponent'
}
</script>

<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-column">
        <div class="content-images">
          <img class="logo-footer" src="../assets/img/logo-globo.png" />
          <div class="footer-icons">
            <img src="../assets/icons/facebook.png" />
            <img src="../assets/icons/twitter.png" />
            <img src="../assets/icons/youtube.png" />
            <img src="../assets/icons/instagram.png" />
            <img src="../assets/icons/linkedin.png" />
          </div>
        </div>
      </div>
      <div class="footer-column">
        <ul>
          <li><a href="#">A Globo</a></li>
          <li><a href="#" class="disabled">Histórico, principais marcos</a></li>
          <li><a href="#">Plataforma de Marca</a></li>
          <li><a href="#" class="disabled">Estratégia marca Globo</a></li>
          <li><a href="#" class="disabled">Público</a></li>
          <li><a href="#" class="disabled">Benefícios diferenciadores</a></li>
          <li><a href="#" class="disabled">Personalidade</a></li>
          <li><a href="#" class="disabled">Vocação</a></li>
        </ul>
      </div>
      <div class="footer-column">
        <ul>
          <li><a href="#">Princípios de Expressão</a></li>
          <li><a href="#">Identidade Verbal</a></li>
          <li><a href="#">Central da Marca</a></li>
          <li><a href="#" class="disabled">Guia de Marcas</a></li>
          <li><a href="#" class="disabled">Intro</a></li>
          <li><a href="#" class="disabled">Organização Visual Globo</a></li>
          <li><a href="#" class="disabled">Identidades (Globo, Híbrida e Original)</a></li>
        </ul>
      </div>
      <div class="footer-column">
        <ul>
          <li><a href="#">Identidade Visual</a></li>
          <li><a href="#" class="disabled">Logo</a></li>
          <li><a href="#" class="disabled">Árvore de decisão de qual logo usar</a></li>
          <li><a href="#" class="disabled">Assinatura entre marcas</a></li>
          <li><a href="#" class="disabled">Convivência com outras marcas</a></li>
          <li><a href="#" class="disabled">Cores</a></li>
          <li><a href="#" class="disabled">Tipografia</a></li>
          <li><a href="#" class="disabled">ESG</a></li>
        </ul>
      </div>
      <div class="footer-column">
        <ul>
          <li><a href="#" class="disabled">Imprensa</a></li>
          <li><a href="#" class="disabled">Relações com investidores</a></li>
          <li><a href="#" class="disabled">ECAD</a></li>
          <li><a href="#" class="disabled">Grupo Globo</a></li>
          <li><a href="#" class="disabled">Princípios Editoriais</a></li>
        </ul>
      </div>
    </div>
    <div class="footer-end">
      <span>© Copyright 2000-2024 Globo Comunicação e Participações S.A.</span>
      <p>política de privacidade</p>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 0;
  z-index: 1;
}

.footer-line {
  width: 1260px;
  height: 10px;
  border: 1px 0px 0px 0px;
  background-color: #000;
}

.content-images {
  padding: 1rem;
}

.footer-icons {
  display: flex;
  align-items: center;

  img {
    padding: 5px;
  }
}

.logo-footer {
  margin-left: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  padding-top: 5rem;
  margin: 0 auto;
}

.footer-column {
  flex: 1;
  padding: 0 10px;
}

.footer-column h3 {
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 1rem;
}

.footer-column ul li a {
  text-decoration: none;
  color: #000;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;

  span {
    font-size: 12px;
    color: rgba(112, 122, 143, 1);
  }

  p {
    font-size: 15px;
  }
}
</style>
