<template>
  <div class="about-us-content"> 
  <div class="about-us-globo-content">
    <div>
      <p class="about-us-globo-title">{{ aboutUsTitle }}</p>
      <p class="about-us-globo-description">{{ aboutUsDescription }}</p>
    </div>
    <div class="about-us-img" :style="{ backgroundImage: `url(${aboutUsImageSrc})` }"></div>
  </div>
    <div class="about-us-img-bottom-content">
      <div class="about-us-img-bottom" :style="{ backgroundImage: `url(${aboutUsImageSrc2})` }"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GloboPageMixin from '@/mixins/globo-page-mixin'
import { GloboPageStore } from '@/store/globo-page/globo-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class AboutUsGloboSection extends Mixins(GloboPageMixin) {
  globoPageStore: PageStoreContract = getModule(GloboPageStore, this.$store)

  get aboutUsTitle(): string {
    return this.getElementTitle(2, 0)
  }

  get aboutUsDescription(): string {
    return this.getElementText(2, 1)
  }

  get aboutUsImageSrc(): string {
    return this.getElementMedia(2, 2)
  }

  get aboutUsImageSrc2(): string {
    return this.getElementMedia(2, 3)
  }

  async getGloboPage(): Promise<void> {
    await this.globoPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.globoPageStore.isLoading) {
      await this.getGloboPage()
    }
  }
}
</script>

<style lang="scss">
.about-us-content{
  max-width: 1045px;
  width: 100%;
}
.about-us-globo-content {
  display: grid;
  grid-template-columns: 52% 48%;
  width: 95%;
  max-width: 949px;
  padding-left: 3rem;
  padding-top: 3rem;
}

.about-us-globo-title {
  font-family: GlobotipoCorporativaTextosBold;
  font-size: 25px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
}

.about-us-globo-description {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 40px;
  font-weight: 400;
  line-height: 43px;
  text-align: left;
  margin: 0;
  padding-right: 2rem;
}

.about-us-img {
  position: relative;
  width: 98%;
  max-width: 551px;
  height: 578px;

  background-repeat: no-repeat;
  background-position: center;
  background-size:contain;
  margin-bottom: 2rem;
}
.about-us-img-bottom-content {
  width: 95%;
  padding-left: 3rem;
  max-width: 949px;
  padding-bottom: 10rem;
  .about-us-img-bottom {
    position: relative;
    max-width: 1045px;
    width: 100%;
    height: 572px;
  
    background-repeat: no-repeat;
    background-position: center;
    background-size:contain;
  }
}
</style>
