<template>
  <div class="layout-what-not-to-do-content">
    <div class="title-layout-what-not-to-do-wrapper">
      <h1 class="layout-what-not-to-do-title-span">{{ layoutWhatNotToDoTitleSpan }}</h1>
      <h1 class="layout-what-not-to-do-title">{{ layoutWhatNotToDoTitle }}</h1>
    </div>
    <div class="descriptiom-layout-what-not-to-do-wrapper">
      <div class="icon-wrapper">
        <IconCancel />
        <p class="layout-what-not-to-do-description">{{ layoutWhatNotToDoDescription1 }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCancel />
        <p class="layout-what-not-to-do-description">{{ layoutWhatNotToDoDescription2 }}</p>
      </div>
    </div>
    <div
      class="layout-what-not-to-do-card-item"
      :style="{ backgroundImage: `url(${layoutWhatNotToDoImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCancel from '@/assets/icons/IconCancel.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCancel } })
export default class LayoutsWhatNotToDoSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutWhatNotToDoTitleSpan(): string {
    return this.getElementTitle(17, 0)
  }

  get layoutWhatNotToDoTitle(): string {
    return this.getElementText(17, 0)
  }

  get layoutWhatNotToDoDescription1(): string {
    return this.getElementText(17, 1)
  }

  get layoutWhatNotToDoDescription2(): string {
    return this.getElementText(17, 2)
  }

  get layoutWhatNotToDoImageSrc(): string {
    return this.getElementMedia(17, 3)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layout-what-not-to-do-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-layout-what-not-to-do-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.layout-what-not-to-do-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.layout-what-not-to-do-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.descriptiom-layout-what-not-to-do-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.layout-what-not-to-do-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.layout-what-not-to-do-card-item {
  position: relative;
  bottom: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 484px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
