<template>
  <div class="intro-personality-intro-content">
    <div class="description-personality-intro-content">
      <p class="intro-personality-intro-description">{{ introDescription }}</p>
    </div>

    <div
      class="intro-personality-intro-card-item"
      :style="{ backgroundImage: `url(${introImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PersonalityPageMixin from '@/mixins/personality-page-mixin'
import {PersonalityPageStore } from '@/store/brand-plataform-page/personality-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class PersonalityIntroSection extends Mixins(
  PersonalityPageMixin
) {
  personalityPageStore: PageStoreContract = getModule(
    PersonalityPageStore,
    this.$store
  )

  get introDescription(): string {
    return this.getElementText(1, 0)
  }

  get introImageSrc(): string {
    return this.getElementMedia(1,1)
  }

  async getPersonalityPage(): Promise<void> {
    await this.personalityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.personalityPageStore.isLoading) {
      await this.getPersonalityPage()
    }
  }
}
</script>

<style lang="scss">
.intro-personality-intro-content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 949px;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  align-items: start;
}

.description-personality-intro-content {
  max-width: 495px;
  height: 558px;
  width: 100%;

  line-height: 20px;
  text-align: left;
}

.intro-personality-intro-description {
  font-size: 54px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 0 3rem 3rem 0rem;
  margin: 0;
}

.intro-personality-intro-card-item {
  position: relative;
  max-width: 543px;
  width: 100%;
  height: 622px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
</style>
