import { Vue, Component } from 'vue-property-decorator'
import { PageDTO, PageContainerDTO, PageElementsDTO } from '@/data/dto/page.dto'

@Component
export default class IconographyPageMixin extends Vue {
  get getIconographyPageAll(): PageDTO | null {
    return (this as any).iconographyPageStore.all
  }

  getContainer(index: number): PageContainerDTO | undefined {
    return this.getIconographyPageAll?.data?.containers[index]
  }

  getElement(containerIndex: number, elementIndex: number): PageElementsDTO | undefined {
    return this.getContainer(containerIndex)?.elements[elementIndex]
  }

  getElementTitle(containerIndex: number, elementIndex: number): string {
    return this.getElement(containerIndex, elementIndex)?.title || ''
  }

  getElementText(containerIndex: number, elementIndex: number): string {
    return this.getElement(containerIndex, elementIndex)?.text || ''
  }

  getElementMedia(containerIndex: number, elementIndex: number): string {
    return this.getElement(containerIndex, elementIndex)?.media || ''
  }
}
