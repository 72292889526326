<template>
  <div class="our-beliefs-graphic-content">
    <div class="our-beliefs-graphic-content">    
    <div
      class="our-beliefs-graphic-card-item"
      :style="{ backgroundImage: `url(${graphicImageSrc})` }"
    ></div>
  </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import OurBeliefsPageMixin from '@/mixins/our-beliefs-page-mixin'
import { OurBeliefsPageStore } from '@/store/brand-plataform-page/our-beliefs-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class OurBeliefsGraphicSection extends Mixins(OurBeliefsPageMixin) {
  ourBeliefsPageStore: PageStoreContract = getModule(OurBeliefsPageStore, this.$store)

  get graphicImageSrc(): string {
    return this.getElementMedia(7, 0)
  }  

  async getOurBeliefsPageStore(): Promise<void> {
    await this.ourBeliefsPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.ourBeliefsPageStore.isLoading) {
      await this.getOurBeliefsPageStore()
    }
  }
}
</script>

<style lang="scss">
.our-beliefs-graphic-content {
  margin-top: 5px;
  display: grid;  
  position: relative;  
  justify-content: center;
  align-items: center;    
}

.our-beliefs-graphic-card-item {
  position: relative;
  bottom: 0;  
  width: 1045px;
  height: 646px;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
}
</style>
