<template>
  <div class="layouts-our-assets-content">
    <div class="title-style-wrapper">
      <h1 class="layouts-our-assets-title-span">{{ layoutsOurAssetsTitleSpan }}</h1>
      <h1 class="layouts-our-assets-title">{{ layoutsOurAssetsTitle }}</h1>
    </div>
    <div class="description-style-wrapper">
      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-our-assets-description">{{ layoutsOurAssetsDescription }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-our-assets-description">{{ layoutsOurAssetsDescription1 }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-our-assets-description">{{ layoutsOurAssetsDescription2 }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-our-assets-description">{{ layoutsOurAssetsDescription3 }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCheck />
        <p class="layouts-our-assets-description">{{ layoutsOurAssetsDescription4 }}</p>
      </div>
    </div>
    <div
      class="layouts-our-assets-card-item"
      :style="{ backgroundImage: `url(${layoutsOurAssetsImageSrc})` }"
    ></div>
    <div class="button-layouts-wrapper">
      <LayoutsButton
        :imageSrc="layoutsImageSrc"
        buttonText="Acesse aqui nosso banco de arquivos"
        downloadName="Layouts.zip"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LayoutsButton from './LayoutsButton.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'

@Component({
  components: {
    IconCheck,
    LayoutsButton
  }
})
export default class LayoutsOurAssetsSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutsOurAssetsTitleSpan(): string {
    return this.getElementTitle(2, 0)
  }

  get layoutsOurAssetsTitle(): string {
    return this.getElementText(2, 0)
  }

  get layoutsOurAssetsDescription(): string {
    return this.getElementText(2, 1)
  }

  get layoutsOurAssetsDescription1(): string {
    return this.getElementText(2, 2)
  }

  get layoutsOurAssetsDescription2(): string {
    return this.getElementText(2, 3)
  }

  get layoutsOurAssetsDescription3(): string {
    return this.getElementText(2, 4)
  }

  get layoutsOurAssetsDescription4(): string {
    return this.getElementText(2, 5)
  }

  get layoutsOurAssetsImageSrc(): string {
    return this.getElementMedia(2, 6)
  }

  get layoutsImageSrc(): string {
    return this.getElementMedia(2, 7)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layouts-our-assets-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-style-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.layouts-our-assets-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.layouts-our-assets-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-style-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.layouts-our-assets-description {
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.title-image {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: left;

  padding-left: 3rem;
  width: 187px;
}

.layouts-our-assets-card-item {
  position: relative;
  bottom: 3rem;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 570px;

  background-repeat: no-repeat;
  background-position: center;
}

.button-layouts-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2rem;
}
</style>
