<template>
  <div class="photography-casting-content">
    <div class="title-photography-casting-wrapper">
      <h1 class="photography-casting-title-span">{{ photographyCastingTitleSpan }}</h1>
      <h1 class="photography-casting-title">{{ photographyCastingTitle }}</h1>
    </div>
    <div class="description-photography-casting-wrapper">
      <p class="photography-casting-description">{{ photographyCastingDescription }}</p>

      <div>
        <p class="photography-casting-description" style="margin: 0">
          {{ photographyCastingDescription1 }}
        </p>

        <div class="icon-wrapper">
          <p class="photography-casting-description" style="margin: 0">
            {{ photographyCastingDescription2 }}
          </p>
        </div>

        <div class="icon-wrapper">
          <p class="photography-casting-description">{{ photographyCastingDescription3 }}</p>
        </div>

        <div class="icon-wrapper">
          <p class="photography-casting-description" style="margin: 0">
            {{ photographyCastingDescription4 }}
          </p>
        </div>
      </div>
    </div>
    <div class="expandable-image">
      <PhotographyExpandableImage :src="photographyCastingImageSrc1" alt="" />
      <PhotographyExpandableImage :src="photographyCastingImageSrc2" alt="" />
      <PhotographyExpandableImage :src="photographyCastingImageSrc3" alt="" />
      <PhotographyExpandableImage :src="photographyCastingImageSrc4" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PhotographyPageMixin from '@/mixins/photography-page-mixin'
import { PhotographyPageStore } from '@/store/visual-identity-page/photography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PhotographyExpandableImage from './PhotographyExpandableImage.vue'

@Component({
  components: { PhotographyExpandableImage }
})
export default class PhotographyCastingSection extends Mixins(PhotographyPageMixin) {
  photographyPageStore: PageStoreContract = getModule(PhotographyPageStore, this.$store)

  get photographyCastingTitleSpan(): string {
    return this.getElementTitle(2, 0)
  }

  get photographyCastingTitle(): string {
    return this.getElementText(2, 0)
  }

  get photographyCastingDescription(): string {
    return this.getElementText(2, 1)
  }

  get photographyCastingDescription1(): string {
    return this.getElementText(2, 2)
  }

  get photographyCastingDescription2(): string {
    return this.getElementText(2, 3)
  }

  get photographyCastingDescription3(): string {
    return this.getElementText(2, 4)
  }

  get photographyCastingDescription4(): string {
    return this.getElementText(2, 5)
  }

  get photographyCastingImageSrc1(): string {
    return this.getElementMedia(2, 6)
  }

  get photographyCastingImageSrc2(): string {
    return this.getElementMedia(2, 7)
  }

  get photographyCastingImageSrc3(): string {
    return this.getElementMedia(2, 8)
  }

  get photographyCastingImageSrc4(): string {
    return this.getElementMedia(2, 9)
  }

  async getPhotographyPage(): Promise<void> {
    await this.photographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.photographyPageStore.isLoading) {
      await this.getPhotographyPage()
    }
  }
}
</script>

<style lang="scss">
.photography-casting-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.title-photography-casting-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.photography-casting-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.photography-casting-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-photography-casting-wrapper {
  align-items: baseline;
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
}

.photography-casting-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.expandable-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 15px;

  width: 30vw;
  padding: 0rem 3rem 3rem 3rem;
}
</style>
