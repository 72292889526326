<template>
  <div class="grid-container-usage-examples">
    <div class="grid-usage-examples-image" :style="{ backgroundImage: `url(${ImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class ColorsGridUsageExamplesSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get ImageSrc(): string {
    return this.getElementMedia(9, 0)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.grid-container-usage-examples {
  display: grid;
  max-width: 1045px;
  width: 100%;
}

.grid-usage-examples-image {
  position: relative;
  bottom: 1rem;
  left: 0;

  max-width: 1045px;
  width: 100%;
  height: 766px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
