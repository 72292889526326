<template>
  <div class="content">
    <OurBeliefsIntroSection />
    <OurBeliefsTechnologySection />
    <OurBeliefsConversationSection />
    <OurBeliefsEducationSection />
    <OurBeliefsCredibilitySection />
    <OurBeliefsInnovativeSection />
    <OurBeliefsGraphicSection />
  </div>
</template>

<script lang="ts">
import OurBeliefsIntroSection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsIntroSection.vue'
import OurBeliefsTechnologySection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsTechnologySection.vue'
import OurBeliefsConversationSection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsConversationSection.vue'
import OurBeliefsEducationSection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsEducationSection.vue'
import OurBeliefsCredibilitySection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsCredibilitySection.vue'
import OurBeliefsInnovativeSection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsInnovativeSection.vue'
import OurBeliefsGraphicSection from '@/components/BrandPlataformPage/sections/our-beliefs/OurBeliefsGraphicSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    OurBeliefsIntroSection,
    OurBeliefsTechnologySection,
    OurBeliefsConversationSection,
    OurBeliefsEducationSection,
    OurBeliefsCredibilitySection,
    OurBeliefsInnovativeSection,
    OurBeliefsGraphicSection
  }
})
export default class OurBeliefsView extends Vue {}
</script>

<style lang="scss"></style>
