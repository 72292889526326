<template>
  <div class="logo-main-version-content">
    <div class="title-wrapper">
      <h1 class="logo-main-title-span">{{ logoMainVersionTitleSpan }}</h1>
      <h1 class="logo-main-title">{{ logoMainVersionTitle }}</h1>
    </div>

    <div>
      <div
        class="logo-main-card-item"
        :style="{ backgroundImage: `url(${logoMainVersionImageSrc})` }"
      ></div>
      <div class="button-logo-main-wrapper">
        <LogoButton
          :imageSrc="logoMainVersionImageSrc"
          buttonText="Acesse aqui nosso banco de arquivos"
          downloadName="globo-logo-azul-rgb-1.png"
        />
      </div>
    </div>

    <div class="description-wrapper">
      <p class="logo-main-description">{{ logoMainVersionDescription }}</p>
      <p class="logo-main-description">{{ logoMainVersionDescription1 }}</p>
      <p class="logo-main-description">{{ logoMainVersionDescription2 }}</p>

      <div class="icon-wrapper-first">
        <IconCheck />
        <p class="logo-main-description-icon">{{ logoMainVersionDescription3 }}</p>
      </div>
      <div class="icon-wrapper">
        <IconCancel />
        <p class="logo-main-description-icon">{{ logoMainVersionDescription4 }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LogoButton from './LogoButton.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'

@Component({
  components: {
    IconCheck,
    IconCancel,
    LogoButton
  }
})
export default class LogoMainVersionSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoMainVersionTitleSpan(): string {
    return this.getElementTitle(3, 0)
  }

  get logoMainVersionTitle(): string {
    return this.getElementText(3, 0)
  }

  get logoMainVersionDescription(): string {
    return this.getElementText(3, 1)
  }

  get logoMainVersionDescription1(): string {
    return this.getElementText(3, 2)
  }

  get logoMainVersionDescription2(): string {
    return this.getElementText(3, 3)
  }

  get logoMainVersionImageSrc(): string {
    return this.getElementMedia(3, 6)
  }

  get logoMainVersionDescription3(): string {
    return this.getElementText(3, 4)
  }

  get logoMainVersionDescription4(): string {
    return this.getElementText(3, 5)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-main-version-content {
  display: grid;
  width: 100%;
  grid-template-columns: 44% 52%;
  max-width: 1045px;
}

.title-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-main-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}
.logo-main-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-wrapper {
  padding: 3rem;
  position: relative;
  bottom: 5rem;
}

.logo-main-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-top: 1rem;
}

.icon-wrapper-first {
  display: flex;
  align-items: center;
  margin-top: 5rem;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.logo-main-description-icon {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  width: 435px;
  padding-left: 7px;
}

.logo-main-card-item {
  position: relative;
  top: 18rem;
  left: 2rem;

  width: 516px;
  height: 148px;

  background-repeat: no-repeat;
  background-position: center;
}

.button-logo-main-wrapper {
  position: relative;
  top: 23rem;
  left: 3rem;
}
</style>
