<template>
  <div class="logo-on-fabric-content">
    <div class="title-on-fabric-wrapper">
      <h1 class="logo-on-fabric-title-span">{{ logoOnFabricTitleSpan }}</h1>
      <h1 class="logo-on-fabric-title">{{ logoOnFabricTitle }}</h1>
    </div>
    <div class="description-on-fabric-wrapper">
      <div class="icon-wrapper">
        <IconCheck />
        <p class="logo-on-fabric-description">{{ logoOnFabricDescription }}</p>
      </div>

      <div class="icon-wrapper">
        <IconCancel />
        <p class="logo-on-fabric-description">{{ logoOnFabricDescription1 }}</p>
      </div>
    </div>
    <div
      class="logo-on-fabric-card-item"
      :style="{ backgroundImage: `url(${logoOnFabricImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCancel from '@/assets/icons/IconCancel.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck, IconCancel } })
export default class LogoOnFabricSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoOnFabricTitleSpan(): string {
    return this.getElementTitle(9, 0)
  }

  get logoOnFabricTitle(): string {
    return this.getElementText(9, 0)
  }

  get logoOnFabricDescription(): string {
    return this.getElementText(9, 1)
  }

  get logoOnFabricDescription1(): string {
    return this.getElementText(9, 2)
  }

  get logoOnFabricImageSrc(): string {
    return this.getElementMedia(9, 3)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-on-fabric-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.title-on-fabric-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-on-fabric-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}
.logo-on-fabric-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-on-fabric-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.logo-on-fabric-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.title-image {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 20px;
  font-weight: 600;
  text-align: left;

  padding-left: 3rem;
  width: 187px;
}

.logo-on-fabric-card-item {
  position: relative;
  bottom: 0;
  width: 95%;
  left: 2rem;
  max-width: 1045px;
  height: 731px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-on-fabric-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  margin: 1rem 0rem 5rem 0rem;
}
</style>
