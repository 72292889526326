<template>
  <div class="graphic-logo-two-content">
    <div class="graphic-logo-two-title-wrapper">
      <h1 class="graphic-logo-two-title-span">{{ graphicLogoTwoTitleSpan }}</h1>
      <h1 class="graphic-logo-two-title">{{ graphicLogoTwoTitle }}</h1>
    </div>
    <div class="description-graphic-logo-two-title-icons-wrapper">
      <div class="graphic-logo-two-icon-wrapper-first">
        <p class="graphic-logo-two-description">{{ graphicLogoTwoDescription1 }}</p>
      </div>

      <div class="graphic-logo-two-icon-wrapper">
        <IconCheck />
        <div>
          <p class="graphic-logo-two-description">{{ graphicLogoTwoDescription2 }}</p>
          <div class="e-nao-esqueca">
            <p class="graphic-logo-two-description">{{ graphicLogoTwoDescription3 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="graphic-logo-two-card-item" :style="{ backgroundImage: `url(${ImageSrc})` }"></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import GraphicPageMixin from '@/mixins/graphic-page-mixin'
import { GraphicPageStore } from '@/store/visual-identity-page/graphic-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class GraphicsLogoTwoSection extends Mixins(GraphicPageMixin) {
  graphicPageStore: PageStoreContract = getModule(GraphicPageStore, this.$store)

  get graphicLogoTwoTitleSpan(): string {
    return this.getElementTitle(5, 0)
  }

  get graphicLogoTwoTitle(): string {
    return this.getElementText(5, 0)
  }

  get graphicLogoTwoDescription1(): string {
    return this.getElementText(5, 1)
  }

  get graphicLogoTwoDescription2(): string {
    return this.getElementText(5, 2)
  }

  get graphicLogoTwoDescription3(): string {
    return this.getElementText(5, 3)
  }

  get ImageSrc(): string {
    return this.getElementMedia(5, 4)
  }

  async getGraphicPage(): Promise<void> {
    await this.graphicPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.graphicPageStore.isLoading) {
      await this.getGraphicPage()
    }
  }
}
</script>

<style lang="scss">
.graphic-logo-two-content {
  display: grid;
}

.graphic-logo-two-title-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.graphic-logo-two-title-span {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-right: 5px;
  color: #b3b3b3;
}

.graphic-logo-two-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.description-graphic-logo-two-title-icons-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.graphic-logo-two-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin: 0;

  padding-left: 7px;
  width: 434px;
}

.graphic-logo-two-icon-wrapper-first {
  display: flex;
}

.graphic-logo-two-icon-wrapper {
  display: flex;
  align-items: center;
}

.graphic-logo-two-card-item {
  position: relative;
  bottom: 1rem;
  left: 3rem;
  width: 1045px;
  height: 766px;
  background-repeat: no-repeat;
  background-position: center;
}

.graphic-logo-two-description-e-nao-esqueca {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 434px;
}
</style>
