<template>
  <div class="logo-pre-defined-sizes-content">
    <div class="title-pre-defined-sizes-wrapper">
      <h1 class="logo-pre-defined-sizes-span">{{ logoPreDefinedSizesTitleSpan }}</h1>
      <h1 class="logo-pre-defined-sizes-title">{{ logoPreDefinedSizesTitle }}</h1>
    </div>
    <div class="description-pre-defined-sizes-wrapper">
      <p class="logo-pre-defined-sizes-description">{{ logoPreDefinedSizesDescription }}</p>
    </div>
    <div class="logo-pre-defined-sizes-card-item" :style="{ backgroundImage: `url(${logoPreDefinedSizesImageSrc})` }">
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class LogoPreDefinedSizesSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoPreDefinedSizesTitleSpan(): string {
    return this.getElementTitle(5, 0)
  }

  get logoPreDefinedSizesTitle(): string {
    return this.getElementText(5, 0)
  }

  get logoPreDefinedSizesDescription(): string {
    return this.getElementText(5, 1)
  }

  get logoPreDefinedSizesImageSrc(): string {
    return this.getElementMedia(5, 2)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-pre-defined-sizes-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.title-pre-defined-sizes-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-pre-defined-sizes-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-pre-defined-sizes-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-pre-defined-sizes-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.logo-pre-defined-sizes-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-top: 1rem;
  width: 430px;
}

.logo-pre-defined-sizes-card-item {
  position: relative;
  bottom: 3rem;
  max-width: 1034px;
  height: 822px;
  width: 99%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  left: 2rem;
}
</style>
