<template>
  <div class="photography-collaborators-content">
    <div class="description-photography-collaborators-wrapper">
      <div>
        <p class="photography-collaborators-description">
          {{ photographyCollaboratorsTwoDescription1 }}
        </p>

        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-collaborators-description">
            {{ photographyCollaboratorsTwoDescription2 }}
          </p>
        </div>
      </div>

      <div>
        <div class="icon-wrapper">
          <IconCheck />
          <p class="photography-collaborators-description">
            {{ photographyCollaboratorsTwoDescription3 }}
          </p>
        </div>
      </div>
    </div>

    <div class="expandable-image">
      <PhotographyExpandableImage :src="photographyCollaboratorsTwoImageSrc1" alt="" />
      <PhotographyExpandableImage :src="photographyCollaboratorsTwoImageSrc2" alt="" />
      <PhotographyExpandableImage :src="photographyCollaboratorsTwoImageSrc3" alt="" />
      <PhotographyExpandableImage :src="photographyCollaboratorsTwoImageSrc4" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import PhotographyPageMixin from '@/mixins/photography-page-mixin'
import { PhotographyPageStore } from '@/store/visual-identity-page/photography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PhotographyExpandableImage from './PhotographyExpandableImage.vue'
import IconCheck from '@/assets/icons/IconCheck.vue'

@Component({
  components: { IconCheck, PhotographyExpandableImage }
})
export default class PhotographyCollaboratorsTwoSection extends Mixins(PhotographyPageMixin) {
  photographyPageStore: PageStoreContract = getModule(PhotographyPageStore, this.$store)

  get photographyCollaboratorsTwoDescription1(): string {
    return this.getElementText(7, 0)
  }

  get photographyCollaboratorsTwoDescription2(): string {
    return this.getElementText(7, 1)
  }

  get photographyCollaboratorsTwoDescription3(): string {
    return this.getElementText(7, 2)
  }

  get photographyCollaboratorsTwoImageSrc1(): string {
    return this.getElementMedia(7, 3)
  }

  get photographyCollaboratorsTwoImageSrc2(): string {
    return this.getElementMedia(7, 4)
  }

  get photographyCollaboratorsTwoImageSrc3(): string {
    return this.getElementMedia(7, 5)
  }

  get photographyCollaboratorsTwoImageSrc4(): string {
    return this.getElementMedia(7, 6)
  }

  async getPhotographyPage(): Promise<void> {
    await this.photographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.photographyPageStore.isLoading) {
      await this.getPhotographyPage()
    }
  }
}
</script>

<style lang="scss">
.photography-collaborators-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.description-photography-collaborators-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.photography-collaborators-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.expandable-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 15px;

  width: 30vw;
  padding: 0rem 3rem 3rem 3rem;
}
</style>
