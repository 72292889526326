<template>
  <button class="illustration-button" @click="downloadImage">{{ buttonText }}</button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class IllustrationButton extends Vue {
  @Prop({ type: String, required: true }) readonly imageSrc!: string
  @Prop({ type: String, default: 'Baixar Imagem' }) readonly buttonText!: string
  @Prop({ type: String, default: 'downloaded-image.png' }) readonly downloadName!: string

  async downloadImage(): Promise<void> {
    try {
      const response = await fetch(this.imageSrc)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = this.downloadName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Erro ao baixar a imagem:', error)
    }
  }
}
</script>

<style scoped>
.illustration-button {
  width: 516px;
  height: 52px;
  margin-left: 3rem;

  padding: 17px 25px 17px 25px;
  border-radius: 17px;
  border: none;
  cursor: pointer;

  color: #fff;
  background: linear-gradient(
    90deg,
    #0079fd -37.91%,
    #2d51fb 20.11%,
    #5a29fa 78.13%,
    #8800f8 137.91%
  );
}
</style>
