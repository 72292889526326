<template>
  <div class="typography-hierarchy-content">
    <div class="title-typography-hierarchy-wrapper">
      <h1 class="typography-hierarchy-span">{{ typographyHierarchyTitleSpan }}</h1>
      <h1 class="typography-hierarchy-title">{{ typographyHierarchyTitle }}</h1>
    </div>
    <div class="description-typography-hierarchy-wrapper">
      <div>
        <p class="typography-hierarchy-description">{{ typographyHierarchyDescription }}</p>
        <p class="typography-hierarchy-description" style="padding-top: 1rem">
          {{ typographyHierarchyDescription1 }}
        </p>
      </div>

      <div class="typography-hierarchy-icon">
        <IconCheck />
        <p class="typography-hierarchy-description2">{{ typographyHierarchyDescription2 }}</p>
      </div>
    </div>
    <div
      class="typography-hierarchy-card-item"
      :style="{ backgroundImage: `url(${typographyHierarchyImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class TypographyHierarchySection extends Mixins(TypographyPageMixin) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyHierarchyTitleSpan(): string {
    return this.getElementTitle(5, 0)
  }

  get typographyHierarchyTitle(): string {
    return this.getElementText(5, 0)
  }

  get typographyHierarchyDescription(): string {
    return this.getElementText(5, 1)
  }

  get typographyHierarchyDescription1(): string {
    return this.getElementText(5, 2)
  }

  get typographyHierarchyDescription2(): string {
    return this.getElementText(5, 3)
  }

  get typographyHierarchyImageSrc(): string {
    return this.getElementMedia(5, 4)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-hierarchy-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.title-typography-hierarchy-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.typography-hierarchy-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.typography-hierarchy-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.typography-hierarchy-icon {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.description-typography-hierarchy-wrapper {
  padding: 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.typography-hierarchy-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 430px;
}

.typography-hierarchy-description2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  width: 374px;
  padding-left: 7px;
}

.typography-hierarchy-card-item {
  position: relative;
  bottom: 3rem;

  width: 100%;
  max-width: 1045px;
  height: 1004px;
  border-radius: 8px;

  background-repeat: no-repeat;
  background-position: center;

  padding: 0rem 0rem 0rem 3rem;
}
</style>
