<template>
  <div class="content-news">
    <h2>Últimas novidades</h2>
    <div class="cards">
      <router-link class="no-decor" :to="{ name: 'identidade-visual.photography' }">
        <CardNews class="card-item-position-1">
          <img class="card-img" :src="newsImageSrcPosition1" />
          <div class="text-position-1">
            <span>{{ newsTitlePosition1 }}</span>
            <p>
              {{ newsDescriptionPosition1 }}
            </p>
          </div>
          <img class="icon" src="../../../assets/icons/news-button.svg" />
        </CardNews>
      </router-link>

      <router-link class="no-decor" :to="{ name: 'identidade-visual.grid' }">
        <CardNews class="card-item-position-2">
          <img class="card-img" :src="newsImageSrcPosition2" />
          <div class="text-position-2">
            <span>{{ newsTitlePosition2 }}</span>
            <p>
              {{ newsDescriptionPosition2 }}
            </p>
          </div>
          <img class="icon" src="../../../assets/icons/news-button.svg" />
        </CardNews>
      </router-link>

      <router-link class="no-decor" :to="{ name: 'identidade-visual.typography' }">
        <CardNews class="card-item-position-3">
          <img class="card-img" :src="newsImageSrcPosition3" />
          <div class="text-position-3">
            <span>{{ newsTitlePosition3 }}</span>
            <p>
              {{ newsDescriptionPosition3 }}
            </p>
          </div>
          <img class="icon" src="../../../assets/icons/news-button.svg" />
        </CardNews>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import CardNews from '../CardNews.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import { getModule } from 'vuex-module-decorators'
import { HomePageStore } from '@/store/home-page/home-store'
import HomePageMixin from '@/mixins/home-page-mixin'

@Component({
  components: {
    CardNews
  }
})
export default class NewsSection extends Mixins(HomePageMixin) {
  homePageStore: PageStoreContract = getModule(HomePageStore, this.$store)

  get newsTitlePosition1(): string {
    return this.getElementTitle(2, 0)
  }

  get newsDescriptionPosition1(): string {
    return this.getElementText(2, 0)
  }

  get newsImageSrcPosition1(): string {
    return this.getElementMedia(2, 0)
  }

  get newsTitlePosition2(): string {
    return this.getElementTitle(2, 1)
  }

  get newsDescriptionPosition2(): string {
    return this.getElementText(2, 1)
  }

  get newsImageSrcPosition2(): string {
    return this.getElementMedia(2, 1)
  }

  get newsTitlePosition3(): string {
    return this.getElementTitle(2, 2)
  }

  get newsDescriptionPosition3(): string {
    return this.getElementText(2, 2)
  }

  get newsImageSrcPosition3(): string {
    return this.getElementMedia(2, 2)
  }

  async getHomePage(): Promise<void> {
    await this.homePageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.homePageStore.isLoading) {
      await this.getHomePage()
    }
  }
}
</script>

<style scoped lang="scss">
.content-news {
  width: 95%;
  padding-left: 3rem;
  max-width: 1045px;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.card-img {
  width: 100%;
  max-width: 527px;
  height: 200px;

  background: rgb(79, 79, 77);
  border-radius: 9.9px 9.9px 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.card-item-position-1,
.card-item-position-2,
.card-item-position-3 {
  position: relative;
  width: 100%;
  max-width: 315px;
  height: 336px;
  padding: 0;

  background: #f5f5f5;
  border-radius: 10px;
}

.text-position-1,
.text-position-2,
.text-position-3 {
  position: absolute;
  left: 0;
  padding: 1rem;

  p {
    font-family: GlobotipoCorporativaTextosRegular;
    font-weight: 100;
    font-size: 16px;
  }
}

span {
  font-size: 12px;
  text-transform: uppercase;
}

@media (min-width: 1726px) {
  .card-item-position-1,
  .card-item-position-2,
  .card-item-position-3 {
    width: 366px;
  }
}

.no-decor {
  text-decoration: none;
  color: black;
  width: 33%;
  max-width: 527px;
}

.no-decor:hover {
  color: black;
}
</style>
