<template>
  <div class="content">
    <BrandIntroSection />
    <BrandGraphsSection/>
  </div>
</template>

<script lang="ts">
import BrandIntroSection from '@/components/BrandPlataformPage/sections/brand-plataform/BrandIntroSection.vue'
import BrandGraphsSection from '@/components/BrandPlataformPage/sections/brand-plataform/BrandIGraphsSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    BrandIntroSection,
    BrandGraphsSection 
  }
})
export default class BrandPlataform extends Vue {}
</script>

<style lang="scss"></style>
