<template>
  <div class="video-player-globo">
    <video ref="videoElement" :src="videoSrc" autoplay muted controls></video>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import GloboPageMixin from '@/mixins/globo-page-mixin'
import { GloboPageStore } from '@/store/globo-page/globo-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class BannerVideoSection extends Mixins(GloboPageMixin) {
  globoPageStore: PageStoreContract = getModule(GloboPageStore, this.$store)

  get videoTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get videoSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getGloboPage(): Promise<void> {
    await this.globoPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.globoPageStore.isLoading) {
      await this.getGloboPage()
      const videoElement = this.$refs.videoElement as HTMLVideoElement
      videoElement.addEventListener('loadedmetadata', this.onLoadedMetadata)
    }
  }

  beforeUnmount() {
    const videoElement = this.$refs.videoElement as HTMLVideoElement
    videoElement.removeEventListener('loadedmetadata', this.onLoadedMetadata)
  }

  onLoadedMetadata() {
    console.log('Video metadata loaded')
  }
}
</script>

<style lang="scss">
.video-player-globo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  width: 100%;
  video{
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

}

.overlay {
  position: absolute;
  top: 10rem;
  left: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.video-title {
  font-size: 5rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}
</style>
