<template>
  <div class="logo-signature-3-second-content">
    <div class="title-signature-wrapper">
      <h1 class="logo-signature-3-second-title-span">
        {{ logoSignature3SecondVideosTitleSpan }}
      </h1>
      <h1 class="logo-signature-3-second-title">{{ logoSignature3SecondVideosTitle }}</h1>
    </div>

    <div
      class="logo-signature-3-second-card-item"
      :style="{ backgroundImage: `url(${logoSignature3SecondVideosImageSrc})` }"
    ></div>
    <div
      class="logo-signature-3-second-card-item"
      :style="{ backgroundImage: `url(${logoSignature3SecondVideosImageSrc2})` }"
    ></div>

    <div class="button-signature-wrapper">
      <LogoButton
        :imageSrc="logoSignature3SecondVideosImageSrcZip"
        buttonText="Acesse aqui nosso banco de arquivos"
        downloadName="logos.zip"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LogoButton from './LogoButton.vue'

@Component({
  components: {
    LogoButton
  }
})
export default class LogoSignature3SecondVideosSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoSignature3SecondVideosTitleSpan(): string {
    return this.getElementTitle(18, 0)
  }

  get logoSignature3SecondVideosTitle(): string {
    return this.getElementText(18, 0)
  }

  get logoSignature3SecondVideosImageSrc(): string {
    return this.getElementMedia(18, 1)
  }

  get logoSignature3SecondVideosImageSrc2(): string {
    return this.getElementMedia(18, 2)
  }

  get logoSignature3SecondVideosImageSrcZip(): string {
    return this.getElementMedia(18, 3)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-signature-3-second-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.title-signature-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-signature-3-second-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-signature-3-second-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.logo-signature-3-second-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 484px;
}

.logo-signature-3-second-description-second {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 484px;
}

.logo-signature-3-second-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  width: 1045px;
  height: 558px;
  margin-bottom: 2rem;

  background-repeat: no-repeat;
  background-position: center;
}

.button-signature-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5rem;
  padding-bottom: 8rem;
}
</style>
