<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M30 5C16.175 5 5 16.175 5 30C5 43.825 16.175 55 30 55C43.825 55 55 43.825 55 30C55 16.175 43.825 5 30 5ZM40.75 40.75C39.775 41.725 38.2 41.725 37.225 40.75L30 33.525L22.775 40.75C21.8 41.725 20.225 41.725 19.25 40.75C18.275 39.775 18.275 38.2 19.25 37.225L26.475 30L19.25 22.775C18.275 21.8 18.275 20.225 19.25 19.25C20.225 18.275 21.8 18.275 22.775 19.25L30 26.475L37.225 19.25C38.2 18.275 39.775 18.275 40.75 19.25C41.725 20.225 41.725 21.8 40.75 22.775L33.525 30L40.75 37.225C41.7 38.175 41.7 39.775 40.75 40.75Z"
        fill="#CA2E24"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class IconCancel extends Vue {}
</script>
