<template>
  <div class="layout-mood-red-content">
    <div class="descriptiom-layout-mood-red-wrapper">
      <div class="icon-wrapper">
        <IconCheck />
        <p class="layout-mood-red-description">{{ layoutMoodRedDescription1 }}</p>
      </div>
    </div>
    <div
      class="layout-mood-red-card-item"
      :style="{ backgroundImage: `url(${layoutMoodRedImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import LayoutPageMixin from '@/mixins/layout-page-mixin'
import { LayoutPageStore } from '@/store/visual-identity-page/layout-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class LayoutsMoodRedSection extends Mixins(LayoutPageMixin) {
  layoutPageStore: PageStoreContract = getModule(LayoutPageStore, this.$store)

  get layoutMoodRedDescription1(): string {
    return this.getElementText(15, 0)
  }

  get layoutMoodRedImageSrc(): string {
    return this.getElementMedia(15, 1)
  }

  async getLayoutPage(): Promise<void> {
    await this.layoutPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.layoutPageStore.isLoading) {
      await this.getLayoutPage()
    }
  }
}
</script>

<style lang="scss">
.layout-mood-red-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.descriptiom-layout-mood-red-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 4rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.layout-mood-red-description {
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 374px;
}

.layout-mood-red-card-item {
  position: relative;
  bottom: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 484px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
