<template>
  <div class="menu-container">
    <nav class="fixed-menu">
      <div class="header">
        <router-link to="/">
          <img src="../assets/img/logo-central-da-marca.svg" alt="Logo Central da Marca" />
        </router-link>
      </div>
      <ul>
        <li>
          <router-link
            :to="{
              path: '/globo'
            }"
            :class="{
              active: isRouteActive('/globo')
            }"
            >A Globo</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              path: '/plataforma-de-marca'
            }"
            :class="{
              active: isRouteActive('/plataforma-de-marca')
            }"
          >
            Plataforma de Marca
          </router-link>
        </li>
        <li>
          <router-link
            class="disabled"
            :to="{
              path: '/section3'
            }"
            :class="{
              active: isRouteActive('/section3')
            }"
            >Principios de Expressão</router-link
          >
        </li>
        <li>
          <router-link
            class="disabled"
            :to="{
              path: '/section4'
            }"
            :class="{
              active: isRouteActive('/section4')
            }"
            >Identidade Verbal</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              path: '/identidade-visual'
            }"
            :class="{
              active: isRouteActive('/identidade-visual')
            }"
            >Identidade Visual</router-link
          >
        </li>
        <li>
          <router-link
            class="disabled"
            :to="{
              path: '/section6'
            }"
            :class="{
              active: isRouteActive('/section6')
            }"
            >Marcas da Globo</router-link
          >
        </li>
        <li>
          <a @click="logout()">Log Out</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'

export default class MenuComponent extends Vue {
  showSidebar = true
  submenuVisible = false

  isRouteActive(route: string) {
    return this.$route.path === route
  }

  toggleSubmenu(): void {
    this.submenuVisible = !this.submenuVisible
  }

  async logout(): Promise<void> {
    await this.$store.dispatch('logout')
    this.$router.push({ name: 'logout' })
  }
}
</script>

<style lang="scss">
.menu-container {
  display: flex;
  width: 191px;
  position: relative;
  justify-content: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.fixed-menu {
  max-width: 191px;
  width: 100%;
  padding-top: 27px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
}

.fixed-menu ul {
  list-style-type: none;
  padding: 0;
}

.fixed-menu li {
  margin: 32px 0;
}

.fixed-menu span {
  color: #101010;
  text-decoration: none;
  cursor: pointer;

  &.active {
    font-weight: bold;
  }

  &:hover {
    font-weight: bold;
  }
}

.fixed-menu a {
  color: #101010;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;

  &.active {
    font-weight: bold;
  }

  &:hover {
    font-weight: bold;
  }
}

.fixed-menu .submenu {
  margin-top: 16px;
  padding-left: 16px;
}

.fixed-menu .submenu li {
  margin: 16px 0;
}

.fixed-menu .submenu a {
  color: #9e9e9e;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #101010;
    text-decoration: none;
  }
}
</style>
