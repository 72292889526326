<template>
  <div class="typography-content">
    <div
      class="typography-banner-image"
      :style="{ backgroundImage: `url(${typographyBannerImageSrc})` }"
    ></div>
    <div class="typography-overlay-image">
      <h1 class="typography-banner-title">{{ typographyBannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import TypographyPageMixin from '@/mixins/typography-page-mixin'
import { TypographyPageStore } from '@/store/visual-identity-page/typography-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class TypographyBannerSection extends Mixins(TypographyPageMixin) {
  typographyPageStore: PageStoreContract = getModule(TypographyPageStore, this.$store)

  get typographyBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get typographyBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getTypographyPage(): Promise<void> {
    await this.typographyPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.typographyPageStore.isLoading) {
      await this.getTypographyPage()
    }
  }
}
</script>

<style lang="scss">
.typography-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;

  width: 100%;
  max-height: 28rem;
}

.typography-banner-image {
  position: relative;
  width: 100%;
  height: 451px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.typography-overlay-image {
  position: absolute;
  bottom: 10rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.typography-banner-title {
  font-size: 7rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

@media (min-width: 1726px) {
  .typography-content {
    width: 100%;
  }
}
</style>
