import BrandPlataformView from '@/views/BrandPlataform/index.vue'
import BrandPlataform from '@/views/BrandPlataform/submenu/BrandPlataform.vue'
import DifferentiatingBenefitsView from '@/views/BrandPlataform/submenu/DifferentiatingBenefits.vue'
import OurBeliefsView from '@/views/BrandPlataform/submenu/OurBeliefs.vue'
import PersonalityView from '@/views/BrandPlataform/submenu/Personality.vue'
import PuclicView from '@/views/BrandPlataform/submenu/Public.vue'
import VocationView from '@/views/BrandPlataform/submenu/Vocation.vue'

export default {
  path: '/plataforma-de-marca',
  component: BrandPlataformView,
  children: [
    {
      path: '/plataforma-de-marca',
      name: 'plataforma-de-marca',
      component: BrandPlataform
    },
    {
      path: '/beneficios-diferenciadores',
      name: 'plataforma-de-marca.beneficios-diferenciadores',
      component: DifferentiatingBenefitsView
    },
    {
      path: '/nossas-crencas',
      name: 'plataforma-de-marca.nossas-crencas',
      component: OurBeliefsView
    },
    {
      path: '/personalidade',
      name: 'plataforma-de-marca.personalidade',
      component: PersonalityView
    },
    {
      path: '/publico',
      name: 'plataforma-de-marca.publico',
      component: PuclicView 
    },
    {
      path: '/vocacao',
      name: 'plataforma-de-marca.vocacao',
      component: VocationView
    },
  ]
}
