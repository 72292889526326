import VisualIdentityView from '@/views/VisualIdentity/index.vue'
import ColorView from '@/views/VisualIdentity/submenu/Color.vue'
import GraphicView from '@/views/VisualIdentity/submenu/Graphic.vue'
import IllustrationView from '@/views/VisualIdentity/submenu/Illustration.vue'
import IconographyView from '@/views/VisualIdentity/submenu/Iconography.vue'
import GridView from '@/views/VisualIdentity/submenu/Grid.vue'
import LayoutView from '@/views/VisualIdentity/submenu/Layout.vue'
import LogoView from '@/views/VisualIdentity/submenu/Logo.vue'
import PhotographyView from '@/views/VisualIdentity/submenu/Photography.vue'
import TypographyView from '@/views/VisualIdentity/submenu/Typography.vue'

export default {
  path: '/identidade-visual',
  component: VisualIdentityView,
  children: [
    {
      path: '',
      name: 'identidade-visual',
      redirect: { name: 'identidade-visual.logo' }
    },
    {
      path: '/logo',
      name: 'identidade-visual.logo',
      component: LogoView
    },
    {
      path: '/cores',
      name: 'identidade-visual.color',
      component: ColorView
    },
    {
      path: '/tipografia',
      name: 'identidade-visual.typography',
      component: TypographyView
    },
    {
      path: '/grafismos',
      name: 'identidade-visual.grafismos',
      component: GraphicView
    },
    {
      path: '/iconografia',
      name: 'identidade-visual.iconography',
      component: IconographyView
    },
    {
      path: '/fotografia',
      name: 'identidade-visual.photography',
      component: PhotographyView
    },
    {
      path: '/grid',
      name: 'identidade-visual.grid',
      component: GridView
    },
    {
      path: '/ilustracao',
      name: 'identidade-visual.ilustracao',
      component: IllustrationView
    },
    {
      path: '/layouts',
      name: 'identidade-visual.layouts',
      component: LayoutView
    }
  ]
}
