<template>
  <div class="color-what-not-todo-content">
    <div class="color-what-not-todo-title-wrapper">
      <h1 class="color-what-not-todo-title-span">{{ colorWhatNotToDoTitleSpan }}</h1>
      <h1 class="color-what-not-todo-title">{{ colorWhatNotToDoTitle }}</h1>
    </div>

    <div class="color-what-not-todo-description-wrapper">
      <p class="color-what-not-todo-description">{{ colorWhatNotToDoDescription }}</p>
    </div>

    <div
      class="color-what-not-todo-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class ColorsGridWhatNotToDoSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get colorWhatNotToDoTitleSpan(): string {
    return this.getElementTitle(8, 0)
  }

  get colorWhatNotToDoTitle(): string {
    return this.getElementText(8, 0)
  }

  get colorWhatNotToDoDescription(): string {
    return this.getElementText(8, 1)
  }

  get ImageSrc(): string {
    return this.getElementMedia(8, 2)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.color-what-not-todo-content {
  display: grid;
  max-width: 1045px;
  width: 100%;
}

.color-what-not-todo-title-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.color-what-not-todo-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-right: 5px;
  color: #b3b3b3;
}

.color-what-not-todo-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.color-what-not-todo-description-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  width: 100%;
  max-width: 1045px;
}

.color-what-not-todo-description {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding-left: 7px;
  width: 526px;
}

.color-what-not-todo-card-item {
  position: relative;
  bottom: 3rem;
  left: 0;

  max-width: 1045px;
  width: 100%;
  height: 766px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
