<template>
  <div class="logo-full-left-content">
    <div class="description-full-left-wrapper">
      <div class="logo-full-left-icon-wrapper">
        <p class="logo-full-left-description" style="font-size: 30px; font-weight: 300">
          {{ logoFullSignatureDescription14 }}
        </p>
      </div>
    </div>
    <div
      class="logo-full-left-card-item-4"
      :style="{ backgroundImage: `url(${logoFullSignatureImageSrc7})` }"
    ></div>
    <div
      class="logo-full-left-card-item-5"
      :style="{ backgroundImage: `url(${logoFullSignatureImageSrc8})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LogoButton from './LogoButton.vue'

@Component({
  components: {
    LogoButton
  }
})
export default class LogoSignatureAlignedLeftSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoFullSignatureDescription14(): string {
    return this.getElementText(16, 21)
  }

  get logoFullSignatureImageSrc7(): string {
    return this.getElementMedia(16, 22)
  }

  get logoFullSignatureImageSrc8(): string {
    return this.getElementMedia(16, 23)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-full-left-content {
  display: grid;
  width: 100%;
  max-width: 1045px;
}

.description-full-left-wrapper {
  padding: 3rem 3rem 3rem 2rem;
  position: relative;
  display: grid;
  grid-template-columns: 47% 47%;
  justify-items: start;
  justify-content: space-between;
  gap: 1rem;
}

.logo-full-left-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  padding-left: 7px;
  width: 100%;
}

.logo-full-left-icon-wrapper {
  display: flex;
  align-items: center;
}

.logo-full-left-card-item {
  position: relative;
  bottom: 0;
  width: 95%;
  max-width: 1045px;
  height: 558px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-full-left-card-item-4 {
  position: relative;
  bottom: 3rem;
  left: 3rem;

  max-width: 1045px;
  width: 95%;
  height: 336px;

  border-radius: 20px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-full-left-card-item-5 {
  position: relative;
  bottom: 3rem;
  left: 3rem;

  max-width: 1405px;
  width: 90%;
  height: 531px;

  border-radius: 20px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
