<template>
  <div class="color-content">
    <div
      class="color-banner-image"
      :style="{ backgroundImage: `url(${colorBannerImageSrc})` }"
    ></div>
    <div class="color-overlay-image">
      <h1 class="color-banner-title">{{ colorBannerImageTitle }}</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import ColorPageMixin from '@/mixins/color-page-mixin'
import { ColorPageStore } from '@/store/visual-identity-page/color-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class ColorSection extends Mixins(ColorPageMixin) {
  colorPageStore: PageStoreContract = getModule(ColorPageStore, this.$store)

  get colorBannerImageTitle(): string {
    return this.getElementTitle(0, 0)
  }

  get colorBannerImageSrc(): string {
    return this.getElementMedia(0, 0)
  }

  async getColorPage(): Promise<void> {
    await this.colorPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.colorPageStore.isLoading) {
      await this.getColorPage()
    }
  }
}
</script>

<style lang="scss">
.color-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  width: 100%;
  max-height: 28rem;
}

.color-banner-image {
  position: relative;
  width: 100%;
  height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.color-overlay-image {
  position: absolute;
  bottom: 10rem;
  left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
  pointer-events: none;
  text-align: left;
}

.color-banner-title {
  font-size: 8rem;
  width: 1rem;
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: left;
}

@media (min-width: 1726px) {
  .color-content {
    width: 100%;
  }
}
</style>
