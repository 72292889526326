<template>
  <div class="vocation-graphic-content">
    <div class="vocation-graphic-content">
      <div class="vocation-graphic-card-item" :style="{ backgroundImage: `url(${graphicImageSrc})` }"></div>
    </div>
  </div>
</template>

<script lang="ts">
  import { PageStoreContract } from '@/data/store/page.store.contract'
  import VocationPageMixin from '@/mixins/vocation-page-mixin'
  import { VocationPageStore } from '@/store/brand-plataform-page/vocation-store'
  import { Component, Mixins } from 'vue-property-decorator'
  import { getModule } from 'vuex-module-decorators'

@Component({})
export default class VocationGraphicSection extends Mixins(VocationPageMixin) {
  vocationPageStore: PageStoreContract = getModule(VocationPageStore, this.$store)

  get graphicImageSrc(): string {
    return this.getElementMedia(3, 0)
  }

  async getVocationPageStore(): Promise<void> {
    await this.vocationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.vocationPageStore.isLoading) {
      await this.getVocationPageStore()
    }
  }
}
</script>

<style lang="scss">
.vocation-graphic-content {
  margin-top: 5px;
  display: grid;
  position: relative;
  justify-content: center;
  align-items: center;
}

.vocation-graphic-card-item {
  position: relative;
  bottom: 0;
  width: 1045px;
  height: 646px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>