<template>
  <div class="content-globo">
    <BannerVideoGloboSection />
    <WelcomeGloboSection />
    <AboutUsGloboSection />
  </div>
</template>

<script lang="ts">
import AboutUsGloboSection from '@/components/GloboPage/sections/AboutUsGloboSection.vue'
import BannerVideoGloboSection from '@/components/GloboPage/sections/BannerVideoGloboSection.vue'
import WelcomeGloboSection from '@/components/GloboPage/sections/WelcomeGloboSection.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    BannerVideoGloboSection,
    WelcomeGloboSection,
    AboutUsGloboSection
  }
})
export default class GloboView extends Vue {}
</script>

<style lang="scss">
.content-globo{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
</style>
