<template>
  <div class="illustration-frameworks-content">
    <div class="illustration-frameworks-wrapper">
      <h1 class="illustration-frameworks-title-span">{{ frameworksTitleSpan }}</h1>
      <h1 class="illustration-frameworks-title">{{ frameworksTitle }}</h1>
    </div>

    <div class="description-illustration-frameworks-wrapper">
      <div class="illustration-frameworks-icon-wrapper">
        <IconCheck />
        <p class="illustration-frameworks-description">{{ frameworksDescription }}</p>
      </div>
    </div>

    <div
      class="illustration-frameworks-card-item"
      :style="{ backgroundImage: `url(${ImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/assets/icons/IconCheck.vue'
import { PageStoreContract } from '@/data/store/page.store.contract'
import IllustrationPageMixin from '@/mixins/illustration-page-mixin'
import { IllustrationPageStore } from '@/store/visual-identity-page/illustration-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({ components: { IconCheck } })
export default class IllustrationFrameworksSection extends Mixins(IllustrationPageMixin) {
  illustrationPageStore: PageStoreContract = getModule(IllustrationPageStore, this.$store)

  get frameworksTitleSpan(): string {
    return this.getElementTitle(22, 0)
  }

  get frameworksTitle(): string {
    return this.getElementText(22, 0)
  }

  get frameworksDescription(): string {
    return this.getElementText(22, 1)
  }

  get ImageSrc(): string {
    return this.getElementMedia(22, 2)
  }

  async getIllustrationPage(): Promise<void> {
    await this.illustrationPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.illustrationPageStore.isLoading) {
      await this.getIllustrationPage()
    }
  }
}
</script>

<style lang="scss">
.illustration-frameworks-content {
  display: grid;

  width: 100%;
  max-width: 1045px;
}

.illustration-frameworks-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.illustration-frameworks-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.illustration-frameworks-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-illustration-frameworks-wrapper {
  padding: 3rem 3rem 0rem 3rem;
  position: relative;
  bottom: 5rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.illustration-frameworks-icon-wrapper {
  display: flex;
  align-items: center;
}

.illustration-frameworks-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 374px;
}

.illustration-frameworks-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;

  max-width: 1045px;
  width: 100%;
  height: 650px;

  background-repeat: no-repeat;
  background-position: center;
}
</style>
