<script lang="ts">
export default {
  name: 'CardIdentities'
}
</script>

<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.card {
  border-radius: 15px;
  border: 1px solid #ddd;
  padding: 16px;

  width: 527px;
  height: 144px;
}
</style>
