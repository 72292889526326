<template>
  <div class="logo-what-not-content">
    <div class="title-what-not-wrapper">
      <h1 class="logo-what-not-title-span">{{ logoWhatNotToDoTitleSpan }}</h1>
      <h1 class="logo-what-not-title">{{ logoWhatNotToDoTitle }}</h1>
    </div>

    <div class="description-what-not-wrapper">
      <div>
        <p class="logo-what-not-description">{{ logoWhatNotToDoDescription }}</p>
      </div>

      <div class="logo-what-not-icon-wrapper">
        <IconCancel />
        <div>
          <p class="logo-what-not-description">{{ logoWhatNotToDoDescription1 }}</p>
          <p class="logo-what-not-description-second-title">
            {{ logoWhatNotToDoDescription2 }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="logo-what-not-card-item"
      :style="{ backgroundImage: `url(${logoWhatNotToDoImageSrc})` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PageStoreContract } from '@/data/store/page.store.contract'
import VisualIdentityPageMixin from '@/mixins/visual-identity-page-mixin'
import { VisualIdentityPageStore } from '@/store/visual-identity-page/visual-identity-store'
import { Component, Mixins } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import LogoButton from './LogoButton.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'

@Component({
  components: {
    IconCancel,
    LogoButton
  }
})
export default class LogoWhatNotToDoSection extends Mixins(VisualIdentityPageMixin) {
  visualIdentityPageStore: PageStoreContract = getModule(VisualIdentityPageStore, this.$store)

  get logoWhatNotToDoTitleSpan(): string {
    return this.getElementTitle(21, 0)
  }

  get logoWhatNotToDoTitle(): string {
    return this.getElementText(21, 0)
  }

  get logoWhatNotToDoDescription(): string {
    return this.getElementText(21, 1)
  }

  get logoWhatNotToDoDescription1(): string {
    return this.getElementText(21, 2)
  }

  get logoWhatNotToDoDescription2(): string {
    return this.getElementText(21, 3)
  }

  get logoWhatNotToDoImageSrc(): string {
    return this.getElementMedia(21, 4)
  }

  async getVisualIdentityPage(): Promise<void> {
    await this.visualIdentityPageStore.getDataPage()
  }

  async mounted(): Promise<void> {
    if (!this.visualIdentityPageStore.isLoading) {
      await this.getVisualIdentityPage()
    }
  }
}
</script>

<style lang="scss">
.logo-what-not-content {
  width: 100%;
  display: grid;
  max-width: 1045px;
}

.title-what-not-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.logo-what-not-title-span {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 5px;
  color: #b3b3b3;
}

.logo-what-not-title {
  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 30px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #000000;
}

.description-what-not-wrapper {
  padding: 3rem 0rem 0rem 3rem;
  position: relative;
  bottom: 5rem;
  max-width: 1045px;
  width: 95%;
  display: grid;
  grid-template-columns: 44% 56%;
  justify-content: space-around;
  justify-items: center;
}

.logo-what-not-description {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 100%;
}

.logo-what-not-description-second-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 100%;
}

.logo-what-not-description-second {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  margin: 0;
  padding-left: 7px;
  width: 484px;
}

.logo-what-not-icon-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1045px;
  justify-content: flex-end;
}

.logo-what-not-card-item {
  position: relative;
  bottom: 0;
  left: 3rem;
  width: 95%;
  max-width: 1045px;
  height: 700px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
