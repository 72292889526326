<template>
  <div class="content-navbar">
    <b-navbar class="visual-id-nav" variant="light">
      <router-link
        :to="{ name: 'identidade-visual.logo' }"
        class="nav-link"
        active-class="active-link"
        >Logo</router-link
      >

      <router-link
        :to="{ name: 'identidade-visual.color' }"
        class="nav-link"
        active-class="active-link"
        >Cores</router-link
      >

      <router-link
        :to="{ name: 'identidade-visual.typography' }"
        class="nav-link"
        active-class="active-link"
        >Tipografia</router-link
      >

      <router-link
        :to="{ name: 'identidade-visual.iconography' }"
        class="nav-link"
        active-class="active-link"
        >Iconografia</router-link
      >
      <router-link
        :to="{ name: 'identidade-visual.grafismos' }"
        class="nav-link"
        active-class="active-link"
        >Grafismos</router-link
      >
      <router-link
        :to="{ name: 'identidade-visual.ilustracao' }"
        class="nav-link"
        active-class="active-link"
        >Ilustração</router-link
      >
      <router-link
        :to="{ name: 'identidade-visual.photography' }"
        class="nav-link"
        active-class="active-link"
        >Fotografia</router-link
      >
      <router-link
        :to="{ name: 'identidade-visual.grid' }"
        class="nav-link"
        active-class="active-link"
        >Grid</router-link
      >
      <router-link
        :to="{ name: 'identidade-visual.layouts' }"
        class="nav-link"
        active-class="active-link"
        >Layouts</router-link
      >
    </b-navbar>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class NavInternal extends Vue {}
</script>

<style lang="scss">
.content-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1045px;
  width: 100%;
}

.visual-id-nav {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e0e0e0;
  max-width: 949px;
  width: 100%;
  justify-content: space-between;
  margin: 3rem 0px;
}

.visual-id-nav a {
  text-decoration: none;
  color: #999;
  font-weight: bold;
  padding: 10px;

  font-family: GlobotipoCorporativaTextosRegular;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: left;
}

.visual-id-nav a.active-link {
  color: #000;
  border-bottom: 2px solid #000;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
