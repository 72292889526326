import { render, staticRenderFns } from "./LayoutsCombinigAssetsPhotosSection.vue?vue&type=template&id=e70f56b0"
import script from "./LayoutsCombinigAssetsPhotosSection.vue?vue&type=script&lang=ts"
export * from "./LayoutsCombinigAssetsPhotosSection.vue?vue&type=script&lang=ts"
import style0 from "./LayoutsCombinigAssetsPhotosSection.vue?vue&type=style&index=0&id=e70f56b0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports